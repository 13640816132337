import axios from "axios";
import { requestHandler, successHandler } from "./base.api.interceptor";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// api.defaults.headers.Pragma = "no-cache";

api.interceptors.request.use((request) => requestHandler(request));

api.interceptors.response.use((response) => successHandler(response));

export { api as Api };
