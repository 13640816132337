import {
  ConfigProvider,
  Select,
  Space,
  Table as AntdTable,
  Typography
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { RowSelectionType } from "antd/es/table/interface";
import { ColumnType } from "antd/lib/table";
import { LockIcon, UpDownArrowIcon } from "../../../assets/images/svg";
import { PageSize } from "../../../constant";
import "./yuvo-table.scss";

const { Link } = Typography;

type Props<T> = {
  columns: ColumnsType<T>;
  data: T[];
  loading?: boolean;
  pagination?: {
    total: number;
    pageSize?: number;
    pageIndex?: number;
  };
  selectionType: RowSelectionType;
  onSelectRowSelection: (record: any) => void;
  onChangeRowSelection: (keys: any) => void;
  className?: string;
  onChange?: (pageSize: number, pageIndex: number) => void;
};

function Table<T extends object = any>({
  columns,
  data,
  loading,
  pagination,
  selectionType,
  onSelectRowSelection,
  onChangeRowSelection,
  className,
  onChange
}: Props<T>) {
  const handleChange = (e: any) =>
    onChange && onChange(e, pagination?.pageIndex || 0);
  const onTableChange: TableProps<T>["onChange"] = (tablePagination) =>
    onChange &&
    onChange(
      tablePagination.pageSize ? tablePagination.pageSize : 10,
      tablePagination.current ? tablePagination.current - 1 : 0
    );
  const tableColumn: ColumnsType<T> = columns.map((col: ColumnType<T>) => {
    return {
      ...col,
      title: col.sorter
        ? ({ sortColumns }) => {
            sortColumns?.find(({ column }) => column.key === col.key);
            return (
              <div className="custom-header-title">
                <span>{col.title?.toString()}</span>
                <span style={{ marginLeft: "10px" }}>
                  <UpDownArrowIcon />
                </span>

                {col?.fixed !== undefined && (
                  <span style={{ marginLeft: "10px" }}>
                    <LockIcon />
                  </span>
                )}
              </div>
            );
          }
        : col.title
    };
  });

  const showTotal = (total: number) => {
    return (
      <Space style={{ position: "absolute", left: "0px" }}>
        <span>Show</span>
        <Select
          data-testid="yuvo-select"
          value={pagination?.pageSize ? pagination.pageSize : 0}
          size="small"
          style={{ width: "70px" }}
          onChange={handleChange}
          options={PageSize.map((item) => {
            return {
              value: item,
              lable: item
            };
          })}
        />
        <span>of {total} entries</span>
      </Space>
    );
  };

  const renderPagination = (current: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return <Link>Prev</Link>;
    }
    if (type === "next") {
      return <Link>Next</Link>;
    }
    return originalElement;
  };

  const locale = {
    emptyText: "No Result found"
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2
        }
      }}
    >
      <AntdTable
        data-testid="yuvo-table"
        columns={tableColumn}
        dataSource={data}
        onChange={onTableChange}
        loading={loading}
        pagination={
          pagination
            ? {
                className: "yuvo-table-pagination",
                total: pagination.total,
                pageSize: pagination?.pageSize || 10,
                current: pagination.pageIndex ? pagination.pageIndex + 1 : 1,
                showSizeChanger: false,
                showTotal,
                itemRender: (current, type, originalElement) =>
                  renderPagination(current, type, originalElement)
              }
            : false
        }
        className={`yuvo-table ${className}`}
        size="small"
        scroll={{ x: 1300, y: 500 }}
        rowSelection={{
          type: selectionType,
          onSelect: (record) => {
            onSelectRowSelection(record);
          },
          onChange: (keys) => {
            onChangeRowSelection(keys);
          }
        }}
        locale={locale}
      />
    </ConfigProvider>
  );
}

export { Table };
