import { makeAutoObservable, observable } from "mobx";
import { useNavigate } from "react-router-dom";
import { UserService } from "../services";
import { UserType } from "../models";

export class AuthStore {
  @observable
  private authenticated: boolean = false;

  private currentUser: UserType | undefined;

  userService = new UserService();

  constructor() {
    makeAutoObservable(this);
  }

  isAuthenticated = async () => {
    const navigate = useNavigate();
    if (
      this.authenticated === false &&
      localStorage.getItem("accessToken") !== ""
    ) {
      const response = await this.getCurrentUserInfo();
      if (response) {
        return response;
      }
      localStorage.clear();
      navigate("/");
    }

    return this.authenticated;
  };

  setAuthenticated = async (authenticated: boolean) => {
    this.authenticated = authenticated;
    return this.authenticated;
  };

  get getCurrentUser() {
    return this.currentUser;
  }

  getCurrentUserInfo = async () => {
    const response = await this.userService.getUserInfo();
    if (response.remote === "success") {
      this.authenticated = true;
      this.currentUser = response.data.data;
      return true;
    }

    this.authenticated = false;
    return false;
  };
}
