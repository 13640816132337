import { makeAutoObservable } from "mobx";

export class TestStore {
  loading = false;

  constructor() {
    // console.log("--constructor--");
    makeAutoObservable(this);
    this.loading = false;
  }
}
