import React from "react";

function GoogleIcon() {
  return (
    <svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo / google">
        <path
          id="Icon"
          d="M19.0319 10.8457C19.0319 10.1443 18.9739 9.63239 18.8482 9.10156H10.6748V12.2676H15.4724C15.3757 13.0544 14.8534 14.2393 13.6926 15.0355L13.6764 15.1415L16.2606 17.1034L16.4397 17.1209C18.084 15.6327 19.0319 13.443 19.0319 10.8457Z"
          fill="#4285F4"
        />
        <path
          id="Icon_2"
          d="M10.6747 19.1873C13.0251 19.1873 14.9983 18.4289 16.4396 17.1208L13.6925 15.0353C12.9574 15.5377 11.9708 15.8885 10.6747 15.8885C8.37267 15.8885 6.41882 14.4003 5.72233 12.3433L5.62024 12.3518L2.93309 14.3898L2.89795 14.4855C4.32948 17.2724 7.26995 19.1873 10.6747 19.1873Z"
          fill="#34A853"
        />
        <path
          id="Icon_3"
          d="M5.72217 12.3436C5.5384 11.8127 5.43204 11.244 5.43204 10.6563C5.43204 10.0685 5.5384 9.4998 5.7125 8.96897L5.70764 8.85592L2.98681 6.78516L2.89779 6.82665C2.30778 7.98313 1.96924 9.2818 1.96924 10.6563C1.96924 12.0307 2.30778 13.3294 2.89779 14.4858L5.72217 12.3436Z"
          fill="#FBBC05"
        />
        <path
          id="Icon_4"
          d="M10.6748 5.42373C12.3094 5.42373 13.4121 6.1157 14.0408 6.69397L16.4977 4.34312C14.9888 2.96865 13.0252 2.125 10.6748 2.125C7.26997 2.125 4.32948 4.03978 2.89795 6.82663L5.71267 8.96895C6.41884 6.91197 8.3727 5.42373 10.6748 5.42373Z"
          fill="#EB4335"
        />
      </g>
    </svg>
  );
}

export default GoogleIcon;
