import React from "react";

function GridIcon() {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 10.71C10.6642 10.71 11 10.3742 11 9.95996C11 9.54575 10.6642 9.20996 10.25 9.20996C9.83579 9.20996 9.5 9.54575 9.5 9.95996C9.5 10.3742 9.83579 10.71 10.25 10.71Z"
        fill="#5E718D"
      />
      <path
        d="M6 10.71C6.41421 10.71 6.75 10.3742 6.75 9.95996C6.75 9.54575 6.41421 9.20996 6 9.20996C5.58579 9.20996 5.25 9.54575 5.25 9.95996C5.25 10.3742 5.58579 10.71 6 10.71Z"
        fill="#5E718D"
      />
      <path
        d="M1.75 10.71C2.16421 10.71 2.5 10.3742 2.5 9.95996C2.5 9.54575 2.16421 9.20996 1.75 9.20996C1.33579 9.20996 1 9.54575 1 9.95996C1 10.3742 1.33579 10.71 1.75 10.71Z"
        fill="#5E718D"
      />
      <path
        d="M10.25 6.45996C10.6642 6.45996 11 6.12417 11 5.70996C11 5.29575 10.6642 4.95996 10.25 4.95996C9.83579 4.95996 9.5 5.29575 9.5 5.70996C9.5 6.12417 9.83579 6.45996 10.25 6.45996Z"
        fill="#5E718D"
      />
      <path
        d="M6 6.45996C6.41421 6.45996 6.75 6.12417 6.75 5.70996C6.75 5.29575 6.41421 4.95996 6 4.95996C5.58579 4.95996 5.25 5.29575 5.25 5.70996C5.25 6.12417 5.58579 6.45996 6 6.45996Z"
        fill="#5E718D"
      />
      <path
        d="M1.75 6.45996C2.16421 6.45996 2.5 6.12417 2.5 5.70996C2.5 5.29575 2.16421 4.95996 1.75 4.95996C1.33579 4.95996 1 5.29575 1 5.70996C1 6.12417 1.33579 6.45996 1.75 6.45996Z"
        fill="#5E718D"
      />
      <path
        d="M10.25 2.20996C10.6642 2.20996 11 1.87417 11 1.45996C11 1.04575 10.6642 0.709961 10.25 0.709961C9.83579 0.709961 9.5 1.04575 9.5 1.45996C9.5 1.87417 9.83579 2.20996 10.25 2.20996Z"
        fill="#5E718D"
      />
      <path
        d="M6 2.20996C6.41421 2.20996 6.75 1.87417 6.75 1.45996C6.75 1.04575 6.41421 0.709961 6 0.709961C5.58579 0.709961 5.25 1.04575 5.25 1.45996C5.25 1.87417 5.58579 2.20996 6 2.20996Z"
        fill="#5E718D"
      />
      <path
        d="M1.75 2.20996C2.16421 2.20996 2.5 1.87417 2.5 1.45996C2.5 1.04575 2.16421 0.709961 1.75 0.709961C1.33579 0.709961 1 1.04575 1 1.45996C1 1.87417 1.33579 2.20996 1.75 2.20996Z"
        fill="#5E718D"
      />
      <path
        d="M10.25 10.71C10.6642 10.71 11 10.3742 11 9.95996C11 9.54575 10.6642 9.20996 10.25 9.20996C9.83579 9.20996 9.5 9.54575 9.5 9.95996C9.5 10.3742 9.83579 10.71 10.25 10.71Z"
        stroke="#5E718D"
      />
      <path
        d="M6 10.71C6.41421 10.71 6.75 10.3742 6.75 9.95996C6.75 9.54575 6.41421 9.20996 6 9.20996C5.58579 9.20996 5.25 9.54575 5.25 9.95996C5.25 10.3742 5.58579 10.71 6 10.71Z"
        stroke="#5E718D"
      />
      <path
        d="M1.75 10.71C2.16421 10.71 2.5 10.3742 2.5 9.95996C2.5 9.54575 2.16421 9.20996 1.75 9.20996C1.33579 9.20996 1 9.54575 1 9.95996C1 10.3742 1.33579 10.71 1.75 10.71Z"
        stroke="#5E718D"
      />
      <path
        d="M10.25 6.45996C10.6642 6.45996 11 6.12417 11 5.70996C11 5.29575 10.6642 4.95996 10.25 4.95996C9.83579 4.95996 9.5 5.29575 9.5 5.70996C9.5 6.12417 9.83579 6.45996 10.25 6.45996Z"
        stroke="#5E718D"
      />
      <path
        d="M6 6.45996C6.41421 6.45996 6.75 6.12417 6.75 5.70996C6.75 5.29575 6.41421 4.95996 6 4.95996C5.58579 4.95996 5.25 5.29575 5.25 5.70996C5.25 6.12417 5.58579 6.45996 6 6.45996Z"
        stroke="#5E718D"
      />
      <path
        d="M1.75 6.45996C2.16421 6.45996 2.5 6.12417 2.5 5.70996C2.5 5.29575 2.16421 4.95996 1.75 4.95996C1.33579 4.95996 1 5.29575 1 5.70996C1 6.12417 1.33579 6.45996 1.75 6.45996Z"
        stroke="#5E718D"
      />
      <path
        d="M10.25 2.20996C10.6642 2.20996 11 1.87417 11 1.45996C11 1.04575 10.6642 0.709961 10.25 0.709961C9.83579 0.709961 9.5 1.04575 9.5 1.45996C9.5 1.87417 9.83579 2.20996 10.25 2.20996Z"
        stroke="#5E718D"
      />
      <path
        d="M6 2.20996C6.41421 2.20996 6.75 1.87417 6.75 1.45996C6.75 1.04575 6.41421 0.709961 6 0.709961C5.58579 0.709961 5.25 1.04575 5.25 1.45996C5.25 1.87417 5.58579 2.20996 6 2.20996Z"
        stroke="#5E718D"
      />
      <path
        d="M1.75 2.20996C2.16421 2.20996 2.5 1.87417 2.5 1.45996C2.5 1.04575 2.16421 0.709961 1.75 0.709961C1.33579 0.709961 1 1.04575 1 1.45996C1 1.87417 1.33579 2.20996 1.75 2.20996Z"
        stroke="#5E718D"
      />
    </svg>
  );
}

export default GridIcon;
