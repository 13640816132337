function UpDownArrowIcon() {
  return (
    <svg
      width={11}
      height={13}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5625 10.25L2.6875 12.125M2.6875 12.125L0.8125 10.25M2.6875 12.125V0.875M6.4375 2.75L8.3125 0.875M8.3125 0.875L10.1875 2.75M8.3125 0.875V12.125"
        stroke="black"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UpDownArrowIcon;
