import { useEffect } from "react";
import { Checkbox, Col, Popover, Row, Tooltip, Typography } from "antd";
import GridIcon from "../../../assets/images/svg/GridIcon";
import LockIcon from "../../../assets/images/svg/LockIcon";
import ColorSelector from "../../../components/shared/ColorSelector";
import ManageColumnIcon from "../../../assets/images/svg/ManageColumnIcon";
import { ColType } from "../../../models/memberPatientList.model";

const { Link } = Typography;

type ColumnFilterProps = {
  columnsSource: any;
  setColumns: any;
  setBackColor: any;
  backColor: any;
  setIsLock: any;
  isLock: any;
  selectedColumns: ColType[];
  setSelectedColumns: Function;
};

function FilterContent({
  selectedColumns,
  onChange,
  setBackColor,
  backColor,
  setIsLock,
  isLock
}: any) {
  return (
    <div style={{ height: "450px", overflow: "auto" }} key="filter-content">
      {selectedColumns?.length > 0 &&
        selectedColumns.map((item: any) => (
          <Row
            key={item.key}
            style={{
              padding: "7px",
              border: "1px solid #f5f5f5",
              borderRadius: "4px",
              marginBottom: "10px",
              marginRight: "10px"
            }}
            justify="space-between"
            data-testid="column-filter-row"
          >
            <Col span={20}>
              <Checkbox
                onChange={(e) => onChange(item.key, e.target.checked)}
                checked={item.value}
                className="check"
                data-testid={`chk-item-${item.key}`}
              >
                <span style={{ fontSize: "15px" }}>{item.title}</span>
              </Checkbox>
            </Col>
            <Col span={4}>
              <div
                style={{ position: "relative" }}
                className="lock-outer"
                data-testid={`column-filters-row-${item.key}`}
              >
                <div style={{ marginRight: "10px" }}>
                  {item.title === "Patient Name" ? (
                    <LockIcon setIsLock={setIsLock} isLock={isLock} />
                  ) : (
                    <ColorSelector
                      objKey={item.key}
                      setBackColor={setBackColor}
                      backColor={backColor}
                    />
                  )}
                </div>
                <div>
                  <GridIcon />
                </div>
              </div>
            </Col>
          </Row>
        ))}
    </div>
  );
}

function ColumnFilters(props: ColumnFilterProps) {
  const {
    columnsSource,
    setColumns,
    setBackColor,
    backColor,
    setIsLock,
    isLock,
    selectedColumns,
    setSelectedColumns
  } = props;

  // Some modification on columnSource data
  useEffect(() => {
    const temp = columnsSource
      .map((col: ColType, index: number) => {
        return { index, key: col.key, value: true, title: col.title };
      })
      .filter(Boolean);

    setSelectedColumns(temp);
  }, []);

  /**
   * onChange will get called on click of checkbox
   * @param key
   * @param value
   * @returns boolean
   */
  const onChange = (key: string, value: boolean) => {
    const column: any = selectedColumns.find((col: ColType) => col.key === key);
    if (column?.index <= -1) {
      // index not found something went wrong
      return false;
    }
    const temp: any = [...selectedColumns];
    temp[column.index].value = value;
    setSelectedColumns([...temp]);
    return true;
  };

  // By default all checkboxes selected
  useEffect(() => {
    if (selectedColumns?.length > 0) {
      const temp = columnsSource.filter((col: any) => {
        return selectedColumns.find(
          (sCol: ColType) => sCol.key === col.key && sCol.value === true
        );
      });

      temp.forEach((col: any, index: number) => {
        if (isLock && col.key === "patientName") {
          temp[index].fixed = "left";
        }
      });
      setColumns([...temp].filter(Boolean));
    }
  }, [selectedColumns]);

  return (
    <Popover
      placement="bottomRight"
      overlayClassName="column-filter-overlay"
      title={<h3>Manage Column</h3>}
      style={{ maxWidth: "322px", width: "100%" }}
      content={
        <FilterContent
          onChange={onChange}
          selectedColumns={selectedColumns}
          setBackColor={setBackColor}
          backColor={backColor}
          setIsLock={setIsLock}
          isLock={isLock}
        />
      }
      overlayStyle={{ maxWidth: "715px" }}
      trigger="click"
      data-testid="manage-column-id"
    >
      <Tooltip
        overlayClassName="filters-tooltip"
        placement="top"
        title="Manage Column"
      >
        <Link>
          <ManageColumnIcon />
        </Link>
      </Tooltip>
    </Popover>
  );
}

export default ColumnFilters;
