import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Popover,
  Row,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { OptionType, ValueType } from "./MenuItem";
import "./MultiLevelSelect.scss";

const { Paragraph } = Typography;

type PropTypes = {
  className?: string;
  options?: OptionType;
  onSelect?: (value: ValueType) => void;
  value?: ValueType;
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
};

function MultiLevelSelect({
  className,
  options,
  onSelect,
  value,
  placeholder,
  readonly,
  disabled
}: PropTypes) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<ValueType | undefined>();
  const [label, setLabel] = useState<string | undefined>();
  const onSelectOption = (selectValue: ValueType, selectLabel: string) => {
    setSelectedValue(selectValue);
    setIsDropdownOpen(false);
    setLabel(selectLabel);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    if (selectedValue && onSelect) {
      const labelValue = Object.values(selectedValue).reduce(
        (prev, current) => {
          return `${prev}/${current}`;
        }
      );
      setLabel(labelValue);
      onSelect(selectedValue);
    } else {
      setLabel(undefined);
    }
    // eslint-disable-next-line
  }, [selectedValue]);

  const content = () => {
    return (
      <div>
        {options ? (
          <Dropdown
            onSelectOption={onSelectOption}
            isDropdownOpen={isDropdownOpen}
            options={options}
            selectedValue={selectedValue || {}}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    );
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimaryHover: "#8F8F8F",
            colorPrimaryActive: "#8F8F8F"
          }
        }
      }}
    >
      <Popover
        overlayClassName={options ? "sub-dropdown" : "empty-dropdown"}
        placement="bottom"
        trigger="click"
        open={isDropdownOpen}
        content={content}
        arrow={false}
        onOpenChange={(e) => {
          if (isDropdownOpen) {
            setIsDropdownOpen(e);
          }
        }}
      >
        <Button
          onClick={(event) => {
            if (!readonly) {
              event.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }
          }}
          disabled={disabled}
          className={`dropdown-filters ${className} ${
            readonly ? "readonly" : ""
          }`}
        >
          <Row justify="start" align="middle">
            <Col span={22}>
              <Paragraph
                className="selected-value"
                ellipsis
                title={label || undefined}
              >
                {label || placeholder}
              </Paragraph>
            </Col>
            {!readonly && (
              <Col span={2}>
                {isDropdownOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </Col>
            )}
          </Row>
        </Button>
      </Popover>
    </ConfigProvider>
  );
}

export default MultiLevelSelect;
