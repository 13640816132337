import { Api } from "../components/base/api/base.api";
import { GET_CURRENT_USER } from "../components/base/api/endpoints";
import { UserInfoResponse } from "../models";
import { ErrorResult, SuccessResult } from "../models/base";

export class UserService {
  /**
   ** getUserInfo call the endpoint and fetch response from backend
   * @returns response
   */
  getUserInfo = async (): Promise<
    SuccessResult<UserInfoResponse> | ErrorResult
  > => {
    try {
      const response = await Api<UserInfoResponse>({
        method: "GET",
        url: GET_CURRENT_USER
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };
}
