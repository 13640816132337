export const PageSize = [10, 20, 50, 100];
export const RiskAdjustmentPageIndex = [0, 1, 2, 3, 4, 5];

export const DashboardBreadCrumbMapping: Record<string, string> = {
  "/dashboard": "Dashboard",
  "/yuvo-data-hub": "Yuvo Data Hub",
  "/contracts": "Contracts",
  "/care-management": "Care Management",
  "/risk-adjustment": "Risk Management",
  "/performance-management": "Performance Management",
  "/quality-management": "Quality Management",
  "/patient-management": "Patient Management",
  "/provider": "Provider",
  "/campaign": "Campaign",
  "/visit-management": "Visit Management",
  "/admin": "Admin",
  "/admin/create-user": "Create User",
  "/admin/user-list": "User List",
  "/admin-configuration": "Admin Configuration"
};

export const RegExp = {
  PasswordExp:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
};

export const globalDateFormat = "MM/DD/YYYY";
