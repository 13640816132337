import { notification } from "antd";
import "./alertBox.scss";
import { ErrorCloseIcon } from "../../../assets/images/svg";

interface TextType {
  message: string;
  description: string;
}

const formatDescription = (textArr: TextType[]) => {
  return (
    <>
      {textArr.map((text) => {
        return (
          <>
            <p className="toast-des">{text.message}</p>
            <b>{text.description}</b>
          </>
        );
      })}
    </>
  );
};

const formatMessage = (header: string, type: string) => {
  switch (type) {
    case "success": {
      return <p className="success-msg">{header}</p>;
    }
    case "error": {
      return <p className="error-msg">{header}</p>;
    }
    default: {
      return false;
    }
  }
};

export const alertNotification = (
  type: string,
  header: string,
  text?: TextType[]
) => {
  notification.open({
    message: formatMessage(header, type),
    description: text ? formatDescription(text) : [],
    placement: "top",
    className: `notification-message ${
      type === "error" ? "error-msg-border" : "success-msg-border"
    }`,
    closeIcon: <ErrorCloseIcon />,
    icon: null,
    duration: 5
  });
};
