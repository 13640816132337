import React from "react";
import { Breadcrumb, Col, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { DashboardBreadCrumbMapping } from "../../../constant/index";
import "./breadcrumb.scss";
import { useStore } from "../../../stores/helpers/use-store";

function BreadcrumbComp() {
  const location = useLocation();
  const { memberPatientListStore } = useStore();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    if (index + 1 === pathSnippets.length) {
      return (
        <Breadcrumb.Item key={url}>
          <Link style={{ color: "#0D0D0D" }} to={url}>
            {!memberPatientListStore.isFilterApplied &&
            url === "/patient-management"
              ? `${DashboardBreadCrumbMapping[url]}-Ad_Hoc`
              : DashboardBreadCrumbMapping[url]}
          </Link>
        </Breadcrumb.Item>
      );
    }

    return (
      <Breadcrumb.Item key={url}>
        <Link style={{ color: "#0D0D0D" }} to={url}>
          {DashboardBreadCrumbMapping[url]}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link style={{ color: "#513899" }} to="/dashboard">
        Home
      </Link>
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems);

  return (
    <Row
      className="breadcrumb-outer"
      justify="end"
      style={{ backgroundColor: "#FDFDFD" }}
    >
      <Col style={{ padding: "10px" }}>
        <Breadcrumb style={{ color: "#000" }}>{breadcrumbItems}</Breadcrumb>
      </Col>
    </Row>
  );
}

export default observer(BreadcrumbComp);
