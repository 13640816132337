import { AutoComplete, Input, Typography } from "antd";
import { ChangeEventHandler, MouseEventHandler } from "react";
import { SearchIcon } from "../../../assets/images/svg";
import "./SearchBarWithIcon.scss";

const { Link } = Typography;

export function SearchBarWithIcon({
  searchDropdownClassName,
  iconClassName,
  inputClassName,
  options,
  placeholder,
  onChangeEvent,
  onClickEvent
}: {
  searchDropdownClassName?: string;
  iconClassName?: string;
  inputClassName?: string;
  placeholder: string;
  options?: { value: string }[];
  onChangeEvent: ChangeEventHandler<HTMLInputElement>;
  onClickEvent: MouseEventHandler<HTMLElement>;
}) {
  return (
    <AutoComplete
      data-testid="autocomplete"
      options={options}
      className={searchDropdownClassName}
      onChange={onChangeEvent}
      filterOption
      style={{ marginTop: "-12px", marginRight: "10px" }}
    >
      <Input
        size="middle"
        data-testid="searchBar"
        placeholder={placeholder}
        className={inputClassName}
        suffix={
          <Link className={iconClassName}>
            <SearchIcon onClick={onClickEvent} />
          </Link>
        }
      />
    </AutoComplete>
  );
}
