import React from "react";

function EditIconTwo() {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.76866 11.8894L11.3962 3.26185L9.23783 1.10352L0.610322 9.73101L0.604492 11.8952L2.76866 11.8894Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9541 3.0752L9.4241 4.54519"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIconTwo;
