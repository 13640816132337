import { useGoogleLogin } from "@react-oauth/google";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../../assets/images/svg/social-media";

function GoogleLogin({ loginStore }: any) {
  const navigate = useNavigate();

  /**
   * googleSsoLogin will get called on click of google sso icon button.
   * it will call googleSignIn service to get response.
   */
  const googleSsoLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      const response = await loginStore.googleSignIn({
        token: tokenResponse.code
      });

      if (response.remote === "success") {
        message.success("Congratulations! You Log in Successfully!!");
        navigate("/patient-management");
      }
    }
  });

  return (
    <div className="social-login-buttons" onClick={() => googleSsoLogin()}>
      <GoogleIcon />
    </div>
  );
}

export default GoogleLogin;
