function WarningIcon() {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fontSize: "25px" }}
    >
      <g id="Phosphor Icons / Warning">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 9C12.4142 9 12.75 9.33579 12.75 9.75V13.5C12.75 13.9142 12.4142 14.25 12 14.25C11.5858 14.25 11.25 13.9142 11.25 13.5V9.75C11.25 9.33579 11.5858 9 12 9Z"
          fill="#FF3B3B"
        />
        <path
          id="Vector (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8761 2.55233C11.2176 2.35429 11.6053 2.25 12.0001 2.25C12.3948 2.25 12.7826 2.35429 13.1241 2.55233C13.465 2.75002 13.7477 3.03413 13.9438 3.37598C13.9441 3.37656 13.9444 3.37714 13.9448 3.37772L22.1934 17.6252C22.3905 17.9667 22.4945 18.354 22.4948 18.7483C22.4952 19.1425 22.3919 19.53 22.1953 19.8718C21.9988 20.2136 21.7159 20.4978 21.375 20.6958C21.034 20.8939 20.647 20.9988 20.2527 21.0002L20.2501 21.0002H3.75009L3.74745 21.0002C3.35316 20.9988 2.96617 20.8939 2.62522 20.6958C2.28428 20.4978 2.00137 20.2136 1.80483 19.8718C1.60829 19.53 1.50502 19.1425 1.50537 18.7483C1.50572 18.354 1.60968 17.9667 1.80682 17.6252L10.0554 3.37772C10.0557 3.37714 10.0561 3.37656 10.0564 3.37598C10.2524 3.03413 10.5352 2.75002 10.8761 2.55233ZM10.7063 3.75024L11.3554 4.12601L3.10585 18.3752C3.1058 18.3753 3.10591 18.3751 3.10585 18.3752C3.04025 18.489 3.00549 18.6183 3.00537 18.7496C3.00525 18.881 3.03968 19.0102 3.10519 19.1241C3.17071 19.238 3.26501 19.3328 3.37866 19.3988C3.49201 19.4646 3.62063 19.4996 3.75171 19.5002H20.2485C20.3795 19.4996 20.5082 19.4646 20.6215 19.3988C20.7352 19.3328 20.8295 19.238 20.895 19.1241C20.9605 19.0102 20.9949 18.881 20.9948 18.7496C20.9947 18.6183 20.9601 18.4894 20.8946 18.3757C20.8945 18.3755 20.8947 18.3758 20.8946 18.3757L12.6429 4.12276C12.5781 4.00951 12.4845 3.91539 12.3716 3.84993C12.2587 3.78447 12.1306 3.75 12.0001 3.75C11.8696 3.75 11.7414 3.78447 11.6286 3.84993C11.5157 3.91539 11.4221 4.00951 11.3573 4.12276L10.7063 3.75024Z"
          fill="#FF3B3B"
        />
        <path
          id="Vector"
          d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
          fill="#FF3B3B"
        />
      </g>
    </svg>
  );
}

export default WarningIcon;
