import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Popover, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../stores/helpers/use-store";
import { MyFavoriteType } from "../../../../models/memberPatientList.model";
import {
  DateIcon,
  DeleteIcon,
  EditIcon,
  RenameIcon,
  ShareIcon,
  ThreeDotVerticalIcon
} from "../../../../assets/images/svg";
import { getDateTimeFormat } from "../../../../library/util/js-helper";

const { Link } = Typography;

function MenuItem({
  item,
  handleEditIconClick,
  handleDeleteIconClick,
  handleRenameIconClick,
  shareSelectedFilter
}: any) {
  const MenuItemComponent = useCallback(
    () => (
      <>
        <Link
          onClick={() => handleEditIconClick()}
          className="favorite-option-menu"
        >
          <EditIcon />
          <span className="icon-position">View/Edit</span>
        </Link>
        <Divider style={{ margin: "5px 0px" }} />
        <Link
          onClick={() => handleDeleteIconClick()}
          className="favorite-option-menu"
        >
          <DeleteIcon />
          <span className="icon-position">Delete</span>
        </Link>
        <Divider style={{ margin: "5px 0px" }} />
        <Link
          onClick={() => handleRenameIconClick()}
          className="favorite-option-menu"
        >
          <RenameIcon />
          <span className="icon-position">Rename</span>
        </Link>
        <Divider style={{ margin: "5px 0px" }} />
        <Link
          className="favorite-option-menu"
          onClick={() => shareSelectedFilter(item)}
        >
          <ShareIcon />
          <span className="icon-position">Share</span>
        </Link>
        <Divider style={{ margin: "5px 0px" }} />
      </>
    ),
    []
  );

  return <MenuItemComponent />;
}

function TabItemMyFavorite({
  applyFavoriteFilter,
  onClose,
  setSelectedItem,
  setViewEditModalVisible,
  setDeleteModalVisible,
  setRenameModalVisible,
  shareSelectedFilter
}: any) {
  const { memberPatientListStore } = useStore();
  const menuItemRef = useRef<HTMLInputElement>(null);

  const [list, setList] = useState<any>([]);
  const [isActiveId, setIsActiveId] = useState<number>();

  // applyOnFavoriteFilter will get called on click of favorite list to apply filter.
  const applyOnFavoriteFilter = (item: any) => {
    applyFavoriteFilter(item.filter);
    setIsActiveId(item.filterPreferenceId);
    onClose();
  };

  const getListData = async () => {
    // setLoader(true);
    const response = await memberPatientListStore.getMyFavoriteList({
      pageSize: 0,
      pageIndex: 0
    });
    setList(response);
    // setLoader(false);
  };

  useEffect(() => {
    getListData();
  }, []);

  return (
    <div className="scrollbar">
      {memberPatientListStore.loading ? (
        <Spin
          spinning={memberPatientListStore.loading}
          className="favorite-loader"
        />
      ) : (
        list?.map((item: MyFavoriteType) => {
          return (
            <div
              className="my-favorite-list-main"
              style={{
                backgroundColor:
                  isActiveId === item.filterPreferenceId ? "#D9E8FE" : "#FFFFFF"
              }}
              key={item.filterPreferenceId}
            >
              <div className="favorite-list-name">
                <span onClick={() => applyOnFavoriteFilter(item)}>
                  {item.name}
                </span>
              </div>
              <DateIcon />
              <span className="favorite-date">
                {getDateTimeFormat(item.updatedAt)}
              </span>
              <Popover
                overlayStyle={{
                  maxWidth: "120px"
                }}
                arrow={false}
                overlayClassName="my-favorite-tab-menu"
                placement="leftTop"
                content={
                  <MenuItem
                    item={item}
                    handleEditIconClick={() => {
                      setViewEditModalVisible(true);
                      setSelectedItem(item);
                      // menuItemRef.current?.click();
                      // onClose();
                    }}
                    handleDeleteIconClick={() => {
                      setDeleteModalVisible(true);
                      setSelectedItem(item);
                      // menuItemRef.current?.click();
                      // onClose();
                    }}
                    handleRenameIconClick={() => {
                      setRenameModalVisible(true);
                      setSelectedItem(item);
                      // menuItemRef.current?.click();
                      // onClose();
                    }}
                    shareSelectedFilter={shareSelectedFilter}
                  />
                }
                trigger="click"
              >
                <span
                  ref={menuItemRef}
                  style={{ float: "right", marginTop: "-1.6rem" }}
                >
                  <ThreeDotVerticalIcon />
                </span>
              </Popover>

              <Divider
                style={{
                  margin: "5px 0px",
                  border: `1px dashed  ${
                    isActiveId === item.filterPreferenceId
                      ? "#D9E8FE"
                      : "#d9d9d9"
                  } `
                }}
              />
            </div>
          );
        })
      )}
    </div>
  );
}

export default observer(TabItemMyFavorite);
