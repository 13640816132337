export const TreeData: { id: string; value: string; title: string }[] = [
  { id: "All", value: "All", title: "All" },
  { id: "Low", value: "Low", title: "Low" },
  { id: "Medium", value: "Medium", title: "Medium" },
  { id: "High", value: "High", title: "High" }
];

export const DropdownYear: { value: string; label: string }[] = [
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" }
];

export const DropdownHelathPlan: { value: string; label: string }[] = [
  { value: "1", label: "All Health Plan" },
  { value: "2", label: "Fidelis" },
  { value: "3", label: "Emblam Health" },
  { value: "4", label: "United Health" },
  { value: "5", label: "BCBS" }
];

export const performanceYear = [
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" }
];

export const measurementYear = [
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" }
];

export const healthPlan = [
  { value: "1", label: "All Health Plan" },
  { value: "2", label: "Fidelis" },
  { value: "3", label: "Emblam Health" },
  { value: "4", label: "United Health" },
  { value: "5", label: "BCBS" }
];

export const opportunityScore = [
  { value: "1", label: "Low" },
  { value: "2", label: "Medium" },
  { value: "3", label: "High" }
];

export const riskScore = [
  { value: "1", label: "Low" },
  { value: "2", label: "Medium" },
  { value: "3", label: "High" }
];

export const filterPlaceHolders: any = {
  healthPlanNames: "Health Plan Name",
  groupName: "Group Name",
  groupNpi: "Group NPI",
  fqhc: "Select FQHC",
  site: "Select Site",
  provider: "Select Provider",
  providerType: "Provider Type",
  healthSegment: "Health Segment",
  careManagement: "Care Management",
  ageBand: "Age Band",
  lastDateOfVisit: "Date Of Last Visit",
  lastDateOfService: "Last Service Date",
  careTeamSize: "Care Team Size",
  careGap: "Care Gap"
};
export const AdvanceFilterList = {
  ageBand: [
    "00-04",
    "05-11",
    "12-17",
    "18-34",
    "35-44",
    "45-54",
    "55-64",
    "65 -69",
    "70-74",
    "75-79",
    "80-84",
    "85+"
  ],
  lastDateOfVisit: "2023-03-30",
  lastDateOfService: "2023-03-31",
  careTeamSize: ["2-5", "6-10"]
};
