// eslint-disable import/no-extraneous-dependencies
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { observer } from "mobx-react-lite";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Space,
  Checkbox,
  Divider,
  Typography,
  message
} from "antd";
import "./Login.scss";
import {
  Button,
  ButtonHTMLType,
  ButtonSize
} from "../../components/shared/button";
import { useStore } from "../../stores/helpers/use-store";

import { alertNotification } from "../../components/shared/alertBox";
import ErrorModal from "../../components/shared/modal/error-modal";
import { SuccessErrorMessages } from "../../constant/messages/login";
import logo from "../../assets/images/yuvo-logo.svg";
import { REACT_APP_GOOGLE_CLIENT_ID } from "../../config/google.config";
import GoogleLogin from "./GoogleLogin";

const { Text, Link } = Typography;

function Login() {
  const { loginStore } = useStore();

  const initialValues = {
    username: "",
    password: "",
    isChecked: false
  };

  const [formFields, setFormFields] = useState(initialValues);
  const [isChecked, setIsChecked] = useState(false);
  const [count, setCount] = useState(2);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /**
   * Username and Password onchange event set value
   * @param event
   */
  const handleChange = (event: any) => {
    setFormFields({ ...formFields, [event.target.name]: event.target.value });
  };

  /**
   * remember on change event set value
   * @param event
   */
  const handleRememberCheck = (event: any) => {
    setIsChecked(event.target.checked);
  };

  /**
   * submitForm will get called on click of Sign In button.
   * This function will validate username ,password and it will call userLogin service to get response.
   * Once get the valid response it will call sendOTP service to get response.
   * Invalid credentials user can see remaining attempts, After 3 invalid attempt user account blocked.
   * @returns boolean
   */
  async function submitForm() {
    if (formFields.username && formFields.password) {
      const payload = {
        username: formFields.username,
        password: formFields.password
      };

      const sendOtpPayload = {
        receiver: formFields.username,
        channel: "email",
        resend: false
      };
      const response = await loginStore.userLogin(payload);
      form.resetFields();

      if (response.remote === "success") {
        const sentOTPResponse = await loginStore.sendOTP(sendOtpPayload);
        if (sentOTPResponse.remote === "success") {
          message.success(SuccessErrorMessages.OtpSendMessage);
          navigate("/verify-login", { state: { email: formFields.username } });
        } else {
          message.error("OTP not sent Please try to resend");
        }
      } else {
        let loginFailedCount = count - 1;
        if (response.error?.error?.message === "UserAccountBlocked") {
          loginFailedCount = 0;
        }

        setCount(loginFailedCount);
        switch (loginFailedCount) {
          case 1:
          case 2:
            alertNotification("error", "Error!", [
              {
                message: "Incorrect Email Id or Password",
                description: `${count} attempts are remaining`
              }
            ]);
            break;
          case 0:
            setCount(2);
            ErrorModal({
              title: "Login Failed!!",
              modalContent: [
                SuccessErrorMessages.LoginFailedMessage,
                SuccessErrorMessages.LoginFailedDescription
              ]
            });
            break;

          default:
            return false;
        }
      }
    }

    return false;
  }

  return (
    <Card className="card-form">
      <div>
        <img
          src={logo}
          className="logo-slider hide-for-large-screen"
          alt="Yuvo Health"
          width="128px"
          height="48px"
        />
      </div>
      <h4 className="page-title">Welcome to Yuvo Health</h4>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Email Id"
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter your email id"
            }
          ]}
          style={{ marginBottom: 15 }}
          className="username"
        >
          <Input
            data-testid="username"
            id="username"
            name="username"
            type="email"
            value={formFields.username}
            placeholder="Enter Your Email Id"
            onChange={handleChange}
            className="username"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password"
            }
          ]}
          style={{ marginBottom: 15 }}
          className="password"
        >
          <Input.Password
            data-testid="password"
            id="password"
            name="password"
            placeholder="Enter Your Password"
            onChange={handleChange}
            value={formFields.password}
            className="password"
          />
        </Form.Item>
        <Row justify="space-between" align="middle">
          <Col>
            <Form.Item name="remember" noStyle>
              <Checkbox checked={isChecked} onChange={handleRememberCheck}>
                <span style={{ fontSize: "14px" }}>Remember me</span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Link
              className="forgotPasswordLink"
              href="/forgot-password"
              target="_self"
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Row gutter={[24, 24]} justify="center">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="typo-right">
            <Form.Item shouldUpdate style={{ marginBottom: "15px" }}>
              <Button
                className="btn-primary"
                htmlType={ButtonHTMLType.Submit}
                size={ButtonSize.Large}
                onClick={() => submitForm()}
                disabled={loginStore.loading}
                style={{ marginTop: "13px" }}
                data-testid="btn-submit"
              >
                <span data-testid="btn-signin">Sign In</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider className="divider">or</Divider>
      <Row gutter={[24, 24]} style={{ marginTop: 15 }} justify="center">
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className="typo-right google-sign-btn"
          style={{ textAlign: "center" }}
        >
          <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
            <GoogleLogin loginStore={loginStore} />
          </GoogleOAuthProvider>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: 15 }}>
        <Space>
          <Text
            style={{
              color: "#4A5C75",
              fontSize: "14px",
              textAlign: "center"
            }}
          >
            <p>
              By signing in, you agree to our{" "}
              <Link className="signUpLink" href="#" target="_self">
                Terms of Service{" "}
              </Link>
              and <br />
              <Link className="signUpLink" href="#" target="_self">
                Privacy Policy{" "}
              </Link>
            </p>
          </Text>
        </Space>
      </Row>
    </Card>
  );
}

export default observer(Login);
