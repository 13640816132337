import React from "react";

function DropdownSearchIcon() {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.12458 7.9664C9.06199 8.02285 8.97772 8.05455 8.88988 8.05469C8.80085 8.05434 8.71536 8.02274 8.651 7.9664L6.84051 6.30421C6.078 6.89085 5.09767 7.18521 4.10398 7.12589C3.11029 7.06657 2.17997 6.65816 1.50703 5.98583C0.834102 5.3135 0.470521 4.42917 0.492121 3.51726C0.513721 2.60536 0.918834 1.7363 1.62297 1.09134C2.3271 0.446376 3.27589 0.0753061 4.27146 0.0555212C5.26702 0.0357363 6.23249 0.368764 6.9665 0.985147C7.70051 1.60153 8.14639 2.45368 8.21115 3.36386C8.27591 4.27405 7.95455 5.172 7.31408 5.87043L9.12458 7.52878C9.15625 7.55735 9.18141 7.59141 9.19858 7.62898C9.21576 7.66654 9.2246 7.70686 9.2246 7.74759C9.2246 7.78831 9.21576 7.82863 9.19858 7.86619C9.18141 7.90376 9.15625 7.93782 9.12458 7.9664ZM4.36365 6.51918C4.99361 6.51918 5.60942 6.34807 6.13321 6.0275C6.657 5.70692 7.06525 5.25128 7.30632 4.71818C7.5474 4.18508 7.61047 3.59848 7.48758 3.03254C7.36468 2.46661 7.06132 1.94677 6.61588 1.53875C6.17043 1.13074 5.60289 0.852875 4.98504 0.740304C4.36719 0.627733 3.72676 0.685508 3.14476 0.906325C2.56275 1.12714 2.0653 1.50108 1.71532 1.98085C1.36533 2.46063 1.17853 3.02469 1.17853 3.60171C1.17964 4.37516 1.51557 5.11664 2.11265 5.66355C2.70974 6.21046 3.51924 6.51816 4.36365 6.51918Z"
        fill="#4A5C75"
      />
    </svg>
  );
}

export default DropdownSearchIcon;
