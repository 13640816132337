import { Empty, Input, Tag } from "antd";
import { useEffect, useState } from "react";
import MenuItem, { MenuType, OptionType, ValueType } from "./MenuItem";
import { DropdownSearchIcon } from "../../../assets/images/svg";

type PropsType = {
  options: OptionType;
  onSelectOption: (value: ValueType, label: string) => void;
  isDropdownOpen: boolean;
  selectedValue: ValueType;
};

function Dropdown({
  options,
  onSelectOption,
  isDropdownOpen,
  selectedValue
}: PropsType) {
  const [menuList, setMenuList] = useState<MenuType[]>([]);
  const [search, setSearch] = useState<string | undefined>("");

  useEffect(() => {
    if (search) {
      const filterData = options.value.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
      );
      setMenuList(filterData);
    } else {
      setMenuList(options.value);
    }
  }, [search, options]);

  useEffect(() => {
    if (!isDropdownOpen) setSearch(undefined);
  }, [isDropdownOpen]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      {options.showSearch && (
        <Input
          className="searchOption"
          onChange={onSearch}
          suffix={<DropdownSearchIcon />}
          placeholder="Search"
          size="small"
          value={search}
        />
      )}
      {options.dropdownTitle && (
        <Tag className="dropdown-title" color="#5B4E98">
          {options.dropdownTitle}
        </Tag>
      )}
      {menuList.length > 0 ? (
        <MenuItem
          objectKey={options.objectKey}
          onSelectOption={onSelectOption}
          isDropdownOpen={isDropdownOpen}
          menuOptions={menuList}
          value={selectedValue}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}

export default Dropdown;
