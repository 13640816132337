function MicIcon() {
  return (
    <svg
      width={26}
      height={27}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width={26} height={26} rx={13} fill="#EEECFF" />
      <path
        d="M12.9999 18V20M12.9999 18C14.1068 18 15.1749 17.592 16 16.8541C16.8251 16.1162 17.3493 15.1001 17.4724 14M12.9999 18C11.8929 18 10.8248 17.592 9.99973 16.8541C9.17463 16.1162 8.65044 15.1001 8.52734 14M12.9999 7C13.6629 7 14.2988 7.26339 14.7676 7.73223C15.2365 8.20107 15.4999 8.83696 15.4999 9.5V13.5C15.4999 14.163 15.2365 14.7989 14.7676 15.2678C14.2988 15.7366 13.6629 16 12.9999 16C12.6716 16 12.3465 15.9353 12.0432 15.8097C11.7399 15.6841 11.4643 15.4999 11.2321 15.2678C11 15.0356 10.8158 14.76 10.6902 14.4567C10.5645 14.1534 10.4999 13.8283 10.4999 13.5V9.49999C10.4999 8.83695 10.7633 8.20107 11.2321 7.73223C11.701 7.26339 12.3368 7 12.9999 7Z"
        stroke="#5F6875"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MicIcon;
