import { Checkbox, Col, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { ColumnType } from "antd/lib/table";
import { TableRecord } from "../../../../../models/memberPatientList.model";

type PropsType = {
  selectedColumns: string[];
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
  className: string;
  columnsSource: ColumnsType<TableRecord>;
  isEditable: boolean;
};

function ColumnFilterFields({
  selectedColumns,
  setSelectedColumns,
  className = "",
  columnsSource,
  isEditable = false
}: PropsType) {
  const [columns, setColumns] = useState<any>([]);

  /**
   * onChange will get called on click of checkbox
   * @param key
   * @param value
   * @returns boolean
   */
  const onChange = (key: string, value: boolean) => {
    if (value) {
      setSelectedColumns([...selectedColumns, key]);
    } else {
      setSelectedColumns((prevState) =>
        prevState.filter((preValue) => preValue !== key)
      );
    }
  };

  // Some modification on columnSource data
  useEffect(() => {
    const col = columnsSource.map((item: ColumnType<TableRecord>) => {
      return {
        title: item.title,
        key: item.key,
        checked: item.key
          ? selectedColumns.includes(item.key as string)
          : false,
        disabled: !isEditable
      };
    });
    setColumns(col);
  }, [columnsSource, isEditable, selectedColumns]);

  return (
    <div>
      {columns.length > 0 && (
        <Row gutter={[4, 8]} className={className}>
          {columns.map((item: any) => (
            <Col span={6} className="manage-columns" key={item.key}>
              <Checkbox
                disabled={item.disabled}
                onChange={(e) => onChange(item.key, e.target.checked)}
                checked={item.checked}
                className="manage-column"
              >
                {item.title}
              </Checkbox>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default ColumnFilterFields;
