/* eslint-disable react/no-unstable-nested-components */

import {
  Col,
  Divider,
  Popover,
  Row,
  Tabs,
  TabsProps,
  Typography,
  message,
  Tooltip
} from "antd";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { observer } from "mobx-react-lite";
import { Button, ButtonType } from "../../../components/shared/button";
import {
  CloseButtonIcon,
  MyFavoriteIcon,
  MyFavoriteListIcon,
  MyFavoritePlusIcon
} from "../../../assets/images/svg";
import { useStore } from "../../../stores/helpers/use-store";
import {
  MyFavoriteType,
  TableRecord
} from "../../../models/memberPatientList.model";
import "./RiskAdjustment.scss";
import {
  TabItemMyFavorite,
  TabItemSharedByOthers,
  DeleteMyFavorite,
  RenameMyFavorite,
  ViewEditMyFavorite
} from "./my-favorite";

const { Link } = Typography;

type FavoriteProps = {
  onSaveFavorite: Function;
  columnsSource: ColumnsType<TableRecord>;
  applyFavoriteFilter: Function;
};

function MyFavorite(props: FavoriteProps) {
  const { onSaveFavorite, columnsSource, applyFavoriteFilter } = props;
  const { memberPatientListStore } = useStore();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MyFavoriteType>();
  const menuRef = useRef<HTMLInputElement>(null);

  const [viewEditModalVisible, setViewEditModalVisible] =
    useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [RenameModalVisible, setRenameModalVisible] = useState<boolean>(false);

  // handleMenuOpen will get called on click of  My Favorite button
  const handleMenuOpen = (newOpen: boolean) => {
    setIsOpen(!newOpen);
    setOpen(newOpen);
  };

  // onSubmit will get called on on click of Add to My Favorite button
  const onSubmit = async () => {
    onSaveFavorite();
    setOpen(!open);
    menuRef.current?.click();
  };

  useEffect(() => {
    if (!viewEditModalVisible) {
      setSelectedItem(undefined);
    } else {
      // closing menu
      menuRef.current?.click();
      setOpen(false);
    }
  }, [viewEditModalVisible]);

  useEffect(() => {
    if (deleteModalVisible) {
      menuRef.current?.click();
      setOpen(false);
    }
  }, [deleteModalVisible]);

  useEffect(() => {
    if (RenameModalVisible) {
      menuRef.current?.click();
      setOpen(false);
    }
  }, [RenameModalVisible]);

  /**
   * shareSelectedFilter will get called on click of share button
   * @param item
   * @returns show message
   */
  const shareSelectedFilter = async (item: MyFavoriteType) => {
    const response = await memberPatientListStore.shareSavedFilter(
      item.filterPreferenceId
    );
    if (response?.remote === "success") {
      message.success(`${item.name} shared successfully`);
      setIsOpen(!isOpen);
    }
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "My Favorite",
      children: (
        <TabItemMyFavorite
          applyFavoriteFilter={applyFavoriteFilter}
          onClose={() => {
            setIsOpen(false);
            // setOpen(false);
          }}
          menuRef={menuRef}
          setSelectedItem={setSelectedItem}
          setViewEditModalVisible={setViewEditModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          setRenameModalVisible={setRenameModalVisible}
          shareSelectedFilter={shareSelectedFilter}
        />
      )
    },
    {
      key: "2",
      label: "Shared by Others",
      children: (
        <TabItemSharedByOthers
          menuRef={menuRef}
          setViewEditModalVisible={setViewEditModalVisible}
          setSelectedItem={setSelectedItem}
          onClose={() => {
            setIsOpen(false);
            // setOpen(false);
          }}
        />
      )
    }
  ];

  console.log("---open---", open);
  console.log("---isOpen---", isOpen);

  return (
    <>
      <Popover
        overlayStyle={{ maxWidth: "170px" }}
        overlayClassName="my-favorite"
        placement="bottomLeft"
        trigger="click"
        open={open}
        arrow={false}
        onOpenChange={handleMenuOpen}
        content={
          <>
            <div className="favorite-option">
              <MyFavoritePlusIcon />
              <Link className="icon-position" onClick={onSubmit}>
                Add to My Favorite
              </Link>
            </div>
            <Divider style={{ margin: "5px 0px" }} />
            <div className="favorite-option">
              <Popover
                title={
                  <div className="favorite-title">
                    My Favorite
                    <Link
                      className="close-icon"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <CloseButtonIcon color="#525252" />
                    </Link>
                  </div>
                }
                overlayStyle={{
                  width: "375px"
                }}
                overlayInnerStyle={{
                  marginTop: "-3.8rem",
                  marginRight: "1rem"
                }}
                arrow={false}
                overlayClassName="my-favorite-tab"
                placement="bottom"
                trigger="click"
                open={isOpen}
                content={
                  <>
                    <Divider style={{ margin: "5px 0px" }} />
                    <Tabs defaultActiveKey="1" items={tabItems} centered />
                    <Row justify="end" className="favorite-footer">
                      <Col>
                        <Button
                          className="reset-filter-btn"
                          type={ButtonType.Primary}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col>
                        <Button className="apply-filter-btn">Apply</Button>
                      </Col>
                    </Row>
                  </>
                }
                onOpenChange={(val) => {
                  setIsOpen(val);
                }}
              >
                <MyFavoriteListIcon />
                <Link className="icon-position">Favorite List</Link>
              </Popover>
            </div>
          </>
        }
      >
        <Tooltip
          placement="topLeft"
          title="My Favorite"
          overlayClassName="filters-tooltip"
        >
          <Link ref={menuRef}>
            <MyFavoriteIcon />
          </Link>
        </Tooltip>
      </Popover>
      {selectedItem && (
        <>
          <ViewEditMyFavorite
            viewEditModalVisible={viewEditModalVisible}
            setViewEditModalVisible={setViewEditModalVisible}
            columnsSource={columnsSource}
            item={selectedItem}
          />

          <DeleteMyFavorite
            deleteModalVisible={deleteModalVisible}
            setDeleteModalVisible={setDeleteModalVisible}
            item={selectedItem}
          />

          <RenameMyFavorite
            RenameModalVisible={RenameModalVisible}
            setRenameModalVisible={setRenameModalVisible}
            item={selectedItem}
          />
        </>
      )}
    </>
  );
}

export default observer(MyFavorite);
