import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Card,
  Col,
  Form,
  Row,
  Statistic,
  Typography,
  CountdownProps,
  message
} from "antd";
import {
  Button,
  ButtonHTMLType,
  ButtonSize
} from "../../../components/shared/button";
import "./VerifyLogin.scss";
import { useStore } from "../../../stores/helpers/use-store";

const { Link } = Typography;
const { Countdown } = Statistic;

function VerifyLogin() {
  const { loginStore } = useStore();
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [deadline, setDeadline] = useState(Date.now() + 60 * 1000);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [email, setFormData] = useState<any>();

  useEffect(() => {
    if (!state || !state?.email) {
      window.location.href = "/";
    }
    setFormData(state.email);
  }, [state]);

  /**
   * submitForm will get called on click of Verify button.
   * This function will validate otp and it will call verifyOTP service to get response.
   */

  const submitForm = async () => {
    if (!otp) {
      message.error("Please enter OTP");
    } else {
      const verifyOtpPayload = {
        verificationCode: otp,
        receiver: email
      };
      const response = await loginStore.verifyOTP(verifyOtpPayload);

      if (response.remote === "success") {
        message.success("You logged in successfully!!");
        navigate("/patient-management");
      } else {
        message.error("Incorrect verification code");
        setOtp("");
      }
    }
  };

  const resendOtpPayload = {
    receiver: email,
    channel: "email",
    resend: true
  };
  // Count down for the OTP
  const onTimeOver: CountdownProps["onFinish"] = () => {
    setDisabled(false);
  };

  /**
   * setTimer function for the OTP validation
   * After 60 second Resend button enable
   * After three invalid attempts user navigate to login page
   */
  async function setTimer() {
    setDeadline(Date.now() + 60 * 1000);
    setDisabled(true);
    const response = await loginStore.sendOTP(resendOtpPayload);

    if (response.remote === "success") {
      message.success(
        `New OTP has been sent to your email ${email}, Please check your inbox.`
      );
    } else {
      message.error(response.error?.error?.message, 5);
      navigate("/login");
    }
    form.resetFields();
  }
  return (
    <Card className="card-form">
      <h1
        className="otpHeading"
        style={{
          letterSpacing: 1
        }}
      >
        OTP Verification
      </h1>
      <h2
        className="loginToAcc"
        style={{
          letterSpacing: 1,
          paddingBottom: 13
        }}
      >
        Enter Verification Code
      </h2>
      <OtpInput
        inputStyle={{
          width: 39,
          height: 40,
          border: "1px solid #BCBCBC",
          borderRadius: 5,
          filter: "drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.35))",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: 20,
          color: "#000000"
        }}
        value={otp}
        onChange={setOtp}
        numInputs={6}
        inputType="tel"
        renderSeparator={<span style={{ paddingLeft: 12 }} />}
        renderInput={(props) => <input {...props} />}
        placeholder="------"
      />

      <Countdown
        style={{ marginTop: 20, marginBottom: 15 }}
        format="mm:ss"
        value={deadline}
        onFinish={onTimeOver}
        valueStyle={{ color: "#7E81F7" }}
        className="countdown"
      />

      <Typography className="verification-message">
        We have sent a verification code to your email <Link>{email}</Link> You
        can check your inbox.
      </Typography>

      <br />
      <Typography className="resend-message">
        Didn't receive code?
        <Link
          style={{ paddingLeft: 5 }}
          disabled={disabled}
          onClick={() => setTimer()}
        >
          Resend
        </Link>
      </Typography>

      <Form.Item shouldUpdate>
        <Button
          className="submit-button"
          htmlType={ButtonHTMLType.Submit}
          size={ButtonSize.Large}
          onClick={submitForm}
        >
          Verify
        </Button>
      </Form.Item>

      <Row justify="end">
        <Col>
          <Link
            style={{ color: "#3A306C" }}
            href="/login"
            disabled={loginStore.loading}
          >
            Back to sign in
          </Link>
        </Col>
      </Row>
    </Card>
  );
}

export default observer(VerifyLogin);
