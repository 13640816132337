import { Carousel } from "antd";
import "./carousel.scss";

type PropType = {
  info: {
    name: string;
    title: string;
    description: string;
    background: string;
  }[];
};

function CarouselComp(props: PropType) {
  const { info } = props;

  return (
    <Carousel autoplay style={{ height: "100%" }}>
      {info.map((obj) => {
        return (
          <div className={`slide ${obj.background}`} style={{ height: "100%" }}>
            <div style={{ height: "100%" }} className="corousel-content">
              <h1 style={{ width: "200px" }} className="slide-title">
                {obj.title}
              </h1>
              <hr className="slider-hr" />
              <p style={{ width: "250px" }} className="slide-description">
                {obj.description}
              </p>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}

export default CarouselComp;
