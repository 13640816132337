function AdvancedFiltersIcon() {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_363_137047)">
        <rect
          x={4}
          y={4}
          width={34}
          height={34}
          rx={5}
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect x={12} y={4} width={18} height={7} fill="white" />
        <path
          d="M26.25 14H15.75C15.3358 14 15 14.3358 15 14.75V15.9393C15 16.1383 15.079 16.329 15.2197 16.4697L19.2803 20.5303C19.421 20.671 19.5 20.8617 19.5 21.0607V25.5365C19.5 26.094 20.0867 26.4566 20.5854 26.2073L22.0854 25.4573C22.3395 25.3303 22.5 25.0706 22.5 24.7865V21.0607C22.5 20.8617 22.579 20.671 22.7197 20.5303L26.7803 16.4697C26.921 16.329 27 16.1383 27 15.9393V14.75C27 14.3358 26.6642 14 26.25 14Z"
          stroke="#262043"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_363_137047"
          x={0}
          y={0}
          width={42}
          height={42}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_363_137047"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_363_137047"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AdvancedFiltersIcon;
