import React, { useState } from "react";
import "./Dashboard.scss";
import {
  Routes,
  Route,
  Outlet,
  NavLink,
  useLocation,
  useNavigate
} from "react-router-dom";
import { Layout, Menu, MenuProps, Space, Tooltip, Typography } from "antd";
import { HamburgerIcon, LogoutIcon } from "../../assets/images/svg";
import { DashboardMenu } from "./DashboardMenu";
import DashboardHeader from "../../components/shared/dashboard-header";
import BreadcrumbComp from "../../components/shared/breadcrumb";

const { Link } = Typography;
const { Content } = Layout;

function DashboardLayout() {
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(
    location.pathname.slice(
      location.pathname.lastIndexOf("/"),
      location.pathname.length
    )
  );
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (event) => {
    setCurrent(event.key);
  };

  const onClickIcon = () => {
    setCollapsed(!collapsed);
    setIsOpen(true);
  };

  const onPanelClose = () => {
    setIsOpen(false);
  };

  const onLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const newItems: any = DashboardMenu.map((menuItem) => {
    if (menuItem.children?.length > 0) {
      return {
        key: menuItem.key,
        icon: menuItem.icon
          ? React.createElement(
              menuItem.icon,
              {
                color:
                  collapsed && isOpen
                    ? menuItem.key === current
                      ? "#FFFFFF"
                      : "#4A5C75"
                    : menuItem.key === current
                    ? "#FFFFFF"
                    : "#FFFFFF"
              },
              null,
              menuItem.label
            )
          : undefined,
        label: menuItem.label,
        children: menuItem.children.map((itemValue: any) => {
          return {
            key: itemValue.key,
            icon: itemValue.icon
              ? React.createElement(
                  itemValue.icon,
                  {
                    color:
                      collapsed && isOpen
                        ? itemValue.key === current
                          ? "#FFFFFF"
                          : "#4A5C75"
                        : itemValue.key === current
                        ? "#FFFFFF"
                        : "#FFFFFF"
                  },
                  null,
                  itemValue.label
                )
              : undefined,
            label: (
              <Tooltip
                overlayStyle={{ left: "60px" }}
                placement="right"
                title={collapsed && isOpen ? null : itemValue.label}
              >
                <NavLink to={`/${itemValue.path}`} onClick={onPanelClose}>
                  <span
                    style={{
                      color: itemValue.key === current ? "#FFFFFF" : "#4A5C75"
                    }}
                  >
                    {itemValue.label}
                  </span>
                </NavLink>
              </Tooltip>
            )
          };
        })
      };
    }
    return {
      key: menuItem.key,
      icon: menuItem.icon
        ? React.createElement(
            menuItem.icon,
            {
              color:
                collapsed && isOpen
                  ? menuItem.key === current
                    ? "#FFFFFF"
                    : "#4A5C75"
                  : menuItem.key === current
                  ? "#FFFFFF"
                  : "#FFFFFF"
            },
            null,
            menuItem.label
          )
        : undefined,
      label: (
        <Tooltip
          overlayStyle={{ left: "60px" }}
          placement="right"
          title={collapsed && isOpen ? null : menuItem.label}
        >
          <NavLink to={`/${menuItem.path}`} onClick={onPanelClose}>
            <span
              style={{
                color: menuItem.key === current ? "#FFFFFF" : "#4A5C75"
              }}
            >
              {menuItem.label}
            </span>
          </NavLink>
        </Tooltip>
      )
    };
  });

  return (
    <Space
      className="App"
      direction="vertical"
      style={{ width: "100%" }}
      size={[0, 48]}
    >
      <Layout>
        <div className="landing-sider">
          <div className="hamburger-icon">
            <Link onClick={onClickIcon}>
              <HamburgerIcon />
            </Link>
          </div>
          <div
            className={
              collapsed && isOpen
                ? "side-nav sidebar-move-right"
                : "side-nav sidebar-move-left"
            }
          >
            <Menu
              theme="light"
              selectedKeys={[current]}
              className={
                collapsed && isOpen ? "sidebar-menu" : "sidebar-menu-overlay"
              }
              onClick={onClick}
              mode="inline"
              style={{ overflow: "auto", height: "calc(100% - 61px)" }}
              items={newItems}
            />
          </div>
          <div
            className={
              collapsed && isOpen ? "logout-btn-overlay" : "logout-btn"
            }
          >
            <Tooltip placement="right" title="Logout">
              <Link onClick={onLogout}>
                <LogoutIcon color="#ffff" />
              </Link>
            </Tooltip>
          </div>
        </div>

        <Layout
          style={{
            display: "block",
            background: "#F5F5F5",
            marginLeft: "55px"
          }}
        >
          <DashboardHeader />
          <BreadcrumbComp />
          <Content style={{ background: "#F5F5F5", padding: "15px" }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
}

let dashboardChild: any;
DashboardMenu.map((item) => {
  if (item?.children) {
    dashboardChild = item.children?.map((value: any) => value);
  }
  return dashboardChild;
});

export function Dashboard() {
  return (
    <Routes>
      <Route path="" element={<DashboardLayout />}>
        <Route index element={<>Home</>} />

        {DashboardMenu.concat(dashboardChild).map((item) => {
          return (
            <Route key={item.key} path={item.path} element={item.component} />
          );
        })}
      </Route>
    </Routes>
  );
}
