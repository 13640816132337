import { Modal, Typography } from "antd";
import "./error-modal.scss";
import { ErrorCloseIcon } from "../../../../assets/images/svg";

const { Link } = Typography;

export const ErrorModal = ({
  title,
  modalContent,
  navigationLink
}: {
  title: string;
  modalContent: string[];
  navigationLink?: string;
}) => {
  return Modal.error({
    title,
    className: "modal-style",
    content: modalContent.map((item) => <p>{item}</p>),
    centered: true,
    closable: true,
    closeIcon: <ErrorCloseIcon />,
    icon: null,
    footer: [
      navigationLink ? (
        <Link href={navigationLink} className="reset-password">
          Reset Password
        </Link>
      ) : (
        ""
      )
    ]
  });
};
