import { Input, Typography } from "antd";
import "./GlobalSearchBar.scss";
import { ChangeEventHandler, MouseEventHandler } from "react";
import MicIcon from "../../../assets/images/svg/MicIcon";
import GlobalSearchIcon from "../../../assets/images/svg/GlobalSearchIcon";

const { Link } = Typography;

function GlobalSearchBar({
  onChangeEvent,
  onClickEvent
}: {
  onChangeEvent: ChangeEventHandler<HTMLInputElement>;
  onClickEvent: MouseEventHandler<HTMLElement>;
}) {
  return (
    <Input
      placeholder="Search here"
      prefix={
        <Link>
          <GlobalSearchIcon />
        </Link>
      }
      suffix={
        <Link>
          <MicIcon />
        </Link>
      }
      className="global-search-bar"
      onChange={onChangeEvent}
      onClick={onClickEvent}
    />
  );
}

export default GlobalSearchBar;
