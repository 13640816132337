import { makeAutoObservable } from "mobx";
import { Signup } from "../services/index";
import { SignupRequest, VerifyRegisterTokenRequest } from "../models";

export class SignupStore {
  authenticated = false;

  loading = false;

  accessToken = "";

  refreshToken = "";

  registerToken = "";

  signupService = new Signup();

  constructor() {
    makeAutoObservable(this);
  }

  userSignup = async (request: SignupRequest) => {
    this.loading = true;
    const response = await this.signupService.userSignup(request);
    this.loading = false;
    if (response.remote === "success") {
      this.authenticated = true;
      return response;
    }
    return response;
  };

  verifyRegisterToken = async (request: VerifyRegisterTokenRequest) => {
    this.loading = true;
    const response = await this.signupService.verifyRegisterToken(request);
    this.loading = false;
    if (response.remote === "success") {
      this.authenticated = true;
      return response;
    }
    return response;
  };
}
