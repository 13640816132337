import { Modal, Input, Form, message } from "antd";
import { useState } from "react";
import {
  Button,
  ButtonHTMLType,
  ButtonSize,
  ButtonType
} from "../../../../../components/shared/button";
import { MyFavoriteType } from "../../../../../models/memberPatientList.model";
import { useStore } from "../../../../../stores/helpers/use-store";
import "./RenameMyFavorite.scss";
import { CloseButtonIcon } from "../../../../../assets/images/svg";

type PropsType = {
  RenameModalVisible: boolean;
  setRenameModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  item: MyFavoriteType;
};

function RenameMyFavorite({
  RenameModalVisible,
  setRenameModalVisible,
  item
}: PropsType) {
  const [favorite, setFavorite] = useState("");

  const { memberPatientListStore } = useStore();
  const [form] = Form.useForm();

  const handleChange = (event: any) => {
    setFavorite(event.target.value);
  };

  /**
   * "handleOk" function get called on click of Save button.
   * Once we Entered "new favorite name" in input box and save it will call renameMyFavorite service.
   * renamed "favorite name" will get save and messages of service responses displays accordingly.
   */

  const handleOk = async () => {
    const response = await memberPatientListStore.renameMyFavorite({
      filterPreferenceId: item.filterPreferenceId,
      data: {
        name: favorite
      }
    });

    form.resetFields();
    setFavorite("");
    if (response.remote === "success") {
      message.success(`${item.name} renamed to ${favorite} successfully`);
    } else {
      message.error("Name already exist");
    }
    setRenameModalVisible(false);
  };

  /**
   * "handleCancel" function get called on click of Cancel button.
   * It will close the modal popup.
   */
  const handleCancel = () => {
    setRenameModalVisible(false);
  };

  return (
    <Modal
      title={<span className="title">&nbsp;Rename My Favorite</span>}
      className="rename-modal"
      open={RenameModalVisible}
      centered
      closeIcon={<CloseButtonIcon color="white" />}
      onCancel={handleCancel}
      footer={[
        <Button
          className="cancel-button"
          onClick={handleCancel}
          type={ButtonType.Primary}
          htmlType={ButtonHTMLType.Submit}
          size={ButtonSize.Large}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>,
        <Button
          className="save-button"
          key="back"
          onClick={handleOk}
          htmlType={ButtonHTMLType.Submit}
          size={ButtonSize.Middle}
          style={{ marginRight: 20 }}
        >
          Save
        </Button>
      ]}
    >
      <p>Enter new favorite name</p>
      <Form layout="vertical" form={form}>
        <Form.Item>
          <Input
            name="favorite"
            type="text"
            value={favorite}
            placeholder="Enter my favorite name"
            style={{ height: "44px" }}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default RenameMyFavorite;
