type PropsType = {
  color?: string;
};

function DashboardIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4 12.2H2.6C2.17565 12.2 1.76869 12.0314 1.46863 11.7314C1.16857 11.4313 1 11.0243 1 10.6V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1H15.4C15.8243 1 16.2313 1.16857 16.5314 1.46863C16.8314 1.76869 17 2.17565 17 2.6V10.6C17 11.0243 16.8314 11.4313 16.5314 11.7314C16.2313 12.0314 15.8243 12.2 15.4 12.2H14.6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.5996L13 15.3996H5L9 10.5996Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
