function ManageColumnIcon() {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="manage-column-icon"
    >
      <g filter="url(#filter0_d_363_137050)">
        <rect
          x={4}
          y={4}
          width={34}
          height={34}
          rx={5}
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect x={12} y={4} width={18} height={7} fill="white" />
        <path
          d="M26.0625 26.625V15.375C26.0625 15.0643 25.8107 14.8125 25.5 14.8125H22.6875C22.3768 14.8125 22.125 15.0643 22.125 15.375V26.625C22.125 26.9357 22.3768 27.1875 22.6875 27.1875H25.5C25.8107 27.1875 26.0625 26.9357 26.0625 26.625Z"
          stroke="#262043"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.875 26.625V15.375C19.875 15.0643 19.6232 14.8125 19.3125 14.8125H16.5C16.1893 14.8125 15.9375 15.0643 15.9375 15.375V26.625C15.9375 26.9357 16.1893 27.1875 16.5 27.1875H19.3125C19.6232 27.1875 19.875 26.9357 19.875 26.625Z"
          stroke="#262043"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_363_137050"
          x={0}
          y={0}
          width={42}
          height={42}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_363_137050"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_363_137050"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ManageColumnIcon;
