type PropsType = {
  color?: string;
};
function AdminScreenIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6121 12.6455C15.6138 12.6415 15.6155 12.6375 15.6171 12.6334C15.7691 12.2654 16.1241 12.022 16.5223 12.022H16.8929C17.7056 12.022 18.3645 11.3632 18.3645 10.5504V9.81461C18.3645 9.00187 17.7056 8.343 16.8929 8.343H16.5223C16.1241 8.343 15.7691 8.0997 15.6171 7.73165C15.6155 7.72761 15.6138 7.72356 15.6121 7.71955C15.4593 7.3515 15.5378 6.92819 15.8196 6.64641L16.0816 6.38439C16.6563 5.80969 16.6563 4.87791 16.0816 4.30321L15.5613 3.78292C14.9866 3.20822 14.0548 3.20822 13.4801 3.78292L13.2181 4.04494C12.9363 4.32672 12.513 4.40526 12.145 4.2524C12.1409 4.25071 12.1369 4.24905 12.1329 4.24736C11.7648 4.09541 11.5215 3.74043 11.5215 3.34224V2.97162C11.5215 2.15889 10.8626 1.50001 10.0499 1.50001H9.31411C8.50134 1.50001 7.8425 2.15889 7.8425 2.97162V3.34224C7.8425 3.74043 7.59917 4.09541 7.23112 4.24736C7.22707 4.24905 7.22306 4.25071 7.21902 4.2524C6.85097 4.40526 6.42766 4.32672 6.14588 4.04494L5.88386 3.78292C5.30916 3.20822 4.37737 3.20822 3.80267 3.78292L3.28239 4.30321C2.70768 4.87791 2.70768 5.80969 3.28239 6.38439L3.5444 6.64641C3.82622 6.92819 3.90473 7.3515 3.75187 7.71955C3.75021 7.72356 3.74852 7.72761 3.74686 7.73165C3.59488 8.0997 3.23989 8.343 2.84171 8.343H2.47112C1.65835 8.343 0.999512 9.00187 0.999512 9.81461V10.5504C0.999512 11.3632 1.65835 12.022 2.47112 12.022H2.84171C3.23989 12.022 3.59488 12.2654 3.74686 12.6334C3.74852 12.6375 3.75021 12.6415 3.75187 12.6455"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M15.2814 18.1759C15.442 18.8516 14.9279 19.5 14.2333 19.5H5.20434C4.50977 19.5 3.99563 18.8516 4.15629 18.1759C4.75332 15.665 7.02527 13.7975 9.71883 13.7975C11.2091 13.7975 12.5804 14.3692 13.5964 15.3052"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77563 10.8175C6.77563 9.19202 8.09335 7.8743 9.71886 7.8743C11.3444 7.8743 12.6621 9.19202 12.6621 10.8175C12.6621 12.443 11.3444 13.7607 9.71886 13.7607C8.09335 13.7607 6.77563 12.443 6.77563 10.8175Z"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AdminScreenIcon;
