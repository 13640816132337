/* Base Api */
export const baseAPI = process.env.REACT_APP_API_URL;

/* Login Api */
export const USER_LOGIN = "/auth/login";
export const GOOGLE_LOGIN = "/auth/google-login";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const RESET_PASSWORD = "/auth/reset-password";
export const SEND_OTP = "/auth/send-otp";
export const VERIFY_OTP = "/auth/verify-otp";

// Users
export const GET_CURRENT_USER = "/users/me";

// Risk Adjustment
export const ADVANCE_MEMBER_FILTERS = "/members/filter/list";
export const GET_MEMBER_PATIENT_LIST = "/members/filter";
export const GET_MEMBER_SEARCH_HISTORY = "/search/history";
export const UPDATE_MY_FAVORITE = "/filter-preference/";
export const SAVE_MY_FAVORITE = "/filter-preference/save";
export const GET_FILTER_PREFERENCE = "/filter-preference";
export const GET_SHARED_FILTER_PREFERENCE = "/filter-preference/shared-list";
export const SHARE_SAVED_FILTER = "/filter-preference/share";
export const DELETE_FILTER_PREFERENCE = "/filter-preference";

/* SignUp */
export const SIGN_UP = "auth/register";
export const VERIFY_REGISTER_TOKEN = "auth/verify/register-token";

// Admin-home
export const SIGN_UP_INVITE = "/auth/signup/invite";
export const SIGN_UP_RESEND_INVITE = "/auth/signup/invite/resend";
export const USER_LIST = "/users/registered/users-list";
