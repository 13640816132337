import { Badge, Button, Card, Space } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import { Avatar } from "../../avatar";

export function Confirmation({
  confirmMessage,
  isMsgRead
}: {
  confirmMessage: string;
  isMsgRead: boolean;
}) {
  const now = moment();

  return (
    <Card className="card-style">
      <Meta
        avatar={
          <Badge dot={isMsgRead} color="yellow">
            <Avatar url="https://joesch.moe/api/v1/jon" name="" />
          </Badge>
        }
        description={confirmMessage}
      />
      <Space className="button-group">
        <Button className="accept-button" size="middle">
          Accept
        </Button>
        <Button size="middle">Reject</Button>
      </Space>
      <div className="human-readable-dates">
        {moment.duration(now.diff(now)).humanize(true)}
      </div>
    </Card>
  );
}
