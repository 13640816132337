import {
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Row,
  Select,
  Space
} from "antd";
import "./RiskAdjustment.scss";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, ButtonType } from "../../../components/shared/button";
import {
  MultiLevelSelect,
  ValueType
} from "../../../components/shared/multilevel-select";
import { DropdownWithCheckbox } from "../../../components/shared/dropdown-with-checkbox";
import FloatingLabel from "../../../components/shared/floating-label";
import { TreeData } from "../../../constant/RiskAdjustment";
import { useStore } from "../../../stores/helpers/use-store";
import { PrimaryListType } from "../../../models";
import { globalDateFormat } from "../../../constant";
import { dayjsTypeDate } from "../../../library/util/js-helper";

function PrimaryFilters(props: any) {
  const { primaryFilters, setPrimaryFilters, applyFilters } = props;

  const { memberPatientListStore } = useStore();

  const onAsOfMonthChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPrimaryFilters({ ...primaryFilters, asOfMonth: dateString });
  };

  const [healthPlanState, setHealthPlanState] = useState<
    ValueType | undefined
  >();
  const [riskScoreState, setRiskScoreState] = useState<any>(
    primaryFilters?.riskScore
  );
  const [opportunityScoreState, setOpportunityScoreState] = useState<any>(
    primaryFilters?.opportunityScore
  );

  const [allRisk, setAllRisk] = useState<string>("");
  const [allOpp, setAllOpp] = useState<string>("");

  const [primaryList, setPrimaryList] = useState<PrimaryListType>({
    performanceYear: [],
    measurementYear: []
  });

  const [iconTogglePY, setIconTogglePY] = useState<boolean>(false);
  const [iconToggleMY, setIconToggleMY] = useState<boolean>(false);

  /**
   * handleRiskScoreChange will get called on select value of Risk score dropdown
   * @param newValue
   * @param extra
   */
  const handleRiskScoreChange = (newValue: string[], extra: any) => {
    if (extra.triggerValue === "All") {
      if (newValue.some((item) => item === "All")) {
        setAllRisk("All");
      } else {
        setAllRisk("None");
      }
    } else if (
      newValue.length === TreeData.length - 1 &&
      (allRisk === "None" || allRisk === "Some")
    ) {
      setAllRisk("All");
    } else if (newValue.length === 0) {
      setAllRisk("None");
    } else {
      const tempVal = newValue.filter((item) => item !== "All");
      setRiskScoreState(tempVal);
      setPrimaryFilters({ ...primaryFilters, riskScore: tempVal });
      setAllRisk("Some");
    }
  };

  // This will get called when we select Risk score value
  useEffect(() => {
    const tempArr: string[] = [];
    if (allRisk === "All") {
      TreeData.forEach((item) => {
        tempArr.push(item.value);
      });

      setRiskScoreState(tempArr);
      setPrimaryFilters({ ...primaryFilters, riskScore: tempArr });
    } else if (allRisk === "None") {
      setRiskScoreState(undefined);
      setPrimaryFilters((prevState: any) => {
        const { riskScore, ...rest } = prevState;
        return rest;
      });
    }
  }, [allRisk]);

  // This will get called when we select Opportunity score value
  useEffect(() => {
    const tempArr: string[] = [];
    if (allOpp === "All") {
      TreeData.forEach((item) => {
        tempArr.push(item.value);
      });
      setOpportunityScoreState(tempArr);
      setPrimaryFilters({ ...primaryFilters, opportunityScore: tempArr });
    } else if (allOpp === "None") {
      setOpportunityScoreState(undefined);
      setPrimaryFilters((prevState: any) => {
        const { opportunityScore, ...rest } = prevState;
        return rest;
      });
    }
  }, [allOpp]);

  /**
   * handleOpportunityScore will get called on select value of Opportunity score dropdown
   * @param newValue
   * @param extra
   */
  const handleOpportunityScore = (newValue: string[], extra: any) => {
    if (extra.triggerValue === "All") {
      if (newValue.some((item) => item === "All")) {
        setAllOpp("All");
      } else {
        setAllOpp("None");
      }
    } else if (
      newValue.length === TreeData.length - 1 &&
      (allOpp === "None" || allOpp === "Some")
    ) {
      setAllOpp("All");
    } else if (newValue.length === 0) {
      setAllOpp("None");
    } else {
      const tempVal = newValue.filter((item) => item !== "All");
      setOpportunityScoreState(tempVal);
      setPrimaryFilters({ ...primaryFilters, opportunityScore: tempVal });
      setAllOpp("Some");
    }
  };

  /**
   *  handleHealthPlanChange will get called on select value of Health plan dropdown
   * @param value
   */
  const handleHealthPlanChange = (value: ValueType) => {
    setPrimaryFilters({ ...primaryFilters, ...value });
    setHealthPlanState(value);
  };

  useEffect(() => {
    setRiskScoreState(primaryFilters?.riskScore);
    setOpportunityScoreState(primaryFilters?.opportunityScore);
  }, [primaryFilters]);

  useEffect(() => {
    const perfYear = memberPatientListStore.primaryFilterList?.performanceYear
      .map((item) => {
        return {
          value: item,
          label: item
        };
      })
      .filter(Boolean);

    const measYear = memberPatientListStore.primaryFilterList?.measurementYear
      .map((item) => {
        return {
          value: item,
          label: item
        };
      })
      .filter(Boolean);

    const healthPlan = {
      objectKey: "healthPlan",
      dropdownTitle: "Health Plan",
      showSearch: true,
      value: memberPatientListStore.primaryFilterList
        ? memberPatientListStore.primaryFilterList.healthPlan.map((value) => {
            const healthPlanOption = {
              value: value.name,
              label: value.name
            };

            if (value.lineOfBusiness) {
              return {
                ...healthPlanOption,
                subOption: {
                  objectKey: "lineOfBusiness",
                  dropdownTitle: "Line Of Business",
                  showSearch: true,
                  value: value.lineOfBusiness.map((lineOfBusiness) => {
                    return {
                      value: lineOfBusiness,
                      label: lineOfBusiness
                    };
                  })
                }
              };
            }
            return healthPlanOption;
          })
        : []
    };
    if (perfYear && measYear) {
      setPrimaryList({
        performanceYear: perfYear,
        measurementYear: measYear,
        healthPlan
      });
    }
  }, [memberPatientListStore.primaryFilterList]);

  // resetFilters will get called on click of reset button.
  const resetFilters = async () => {
    setPrimaryFilters({});
    setAllOpp("");
    setAllRisk("");
    setHealthPlanState(undefined);
    applyFilters({});
  };

  useEffect(() => {
    // Health plan value set according to api request
    if (primaryFilters.healthPlan && primaryFilters.lineOfBusiness) {
      setHealthPlanState({
        healthPlan: primaryFilters.healthPlan,
        lineOfBusiness: primaryFilters.lineOfBusiness
      });
    } else {
      setHealthPlanState(undefined);
    }
  }, []);

  return (
    <Form className="primary-filters">
      <Row gutter={[24, 16]} className="filters-container">
        <Col md={12} lg={18} xl={18} xxl={18} xs={24} sm={24}>
          <Row gutter={[4, 4]}>
            <Space wrap>
              <Col flex={2} data-testid="performance-year">
                <FloatingLabel
                  label="P.Y."
                  value={primaryFilters?.performanceYear}
                >
                  <Select
                    className="dropdown-year"
                    onChange={(value) =>
                      setPrimaryFilters((prevState: any) => ({
                        ...prevState,
                        performanceYear: value
                      }))
                    }
                    options={primaryList.performanceYear}
                    value={primaryFilters?.performanceYear}
                    suffixIcon={
                      iconTogglePY ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                    onDropdownVisibleChange={() =>
                      setIconTogglePY(!iconTogglePY)
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col flex={2} data-testid="measurement-year">
                <FloatingLabel
                  label="M.Y."
                  value={primaryFilters?.measurementYear}
                >
                  <Select
                    className="dropdown-year"
                    onChange={(value) =>
                      setPrimaryFilters((prevState: any) => ({
                        ...prevState,
                        measurementYear: value
                      }))
                    }
                    options={primaryList.measurementYear}
                    value={primaryFilters?.measurementYear}
                    suffixIcon={
                      iconToggleMY ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                    onDropdownVisibleChange={() =>
                      setIconToggleMY(!iconToggleMY)
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col flex={2} data-testid="as-of-month">
                <FloatingLabel
                  label="As of Month"
                  value={primaryFilters?.asOfMonth}
                  className="as-of-month"
                >
                  <DatePicker
                    data-testid="date-picker-month"
                    className="datepicker-as-of-month"
                    onChange={onAsOfMonthChange}
                    format={globalDateFormat}
                    value={dayjsTypeDate(primaryFilters?.asOfMonth)}
                  />
                </FloatingLabel>
              </Col>

              <Col flex={2} data-testid="health-plan">
                <FloatingLabel
                  label="Health Plan"
                  value={healthPlanState}
                  className="health-plan-label"
                >
                  <MultiLevelSelect
                    className="health-plan"
                    options={primaryList.healthPlan}
                    onSelect={handleHealthPlanChange}
                    value={healthPlanState}
                  />
                </FloatingLabel>
              </Col>
              <Col flex={2} data-testid="opportunity-score">
                <FloatingLabel
                  label="Opportunity Score"
                  value={primaryFilters?.opportunityScore}
                  className="opportunity-score"
                >
                  <DropdownWithCheckbox
                    className="dropdown-filters"
                    treeData={TreeData}
                    value={opportunityScoreState}
                    handleOnChange={handleOpportunityScore}
                  />
                </FloatingLabel>
              </Col>
              <Col flex={2} data-testid="risk-score">
                <FloatingLabel
                  label="Risk Score"
                  value={primaryFilters?.riskScore}
                  className="risk-score"
                >
                  <DropdownWithCheckbox
                    className="dropdown-filters"
                    treeData={TreeData}
                    value={riskScoreState}
                    handleOnChange={handleRiskScoreChange}
                  />
                </FloatingLabel>
              </Col>
            </Space>
          </Row>
        </Col>

        <Col
          md={24}
          lg={6}
          xl={6}
          xxl={6}
          xs={24}
          sm={24}
          style={{ textAlign: "right" }}
        >
          <Space size={8}>
            <Button onClick={() => applyFilters()} className="apply-button">
              <span data-testid="apply-btn">Apply</span>
            </Button>
            <Button
              className="reset-button"
              type={ButtonType.Primary}
              onClick={() => resetFilters()}
            >
              <span data-testid="reset-btn">Reset</span>
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
export default observer(PrimaryFilters);
