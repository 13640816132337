import {
  Col,
  DatePicker,
  Divider,
  Popover,
  Row,
  Tooltip,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { Button, ButtonType } from "../../../components/shared/button";
import DropdownSelect from "../../../components/shared/dropdown-select";
import {
  FiltersType,
  SelectOptionsType
} from "../../../models/memberPatientList.model";
import {
  AdvanceFilterList,
  filterPlaceHolders
} from "../../../constant/RiskAdjustment";
import { useStore } from "../../../stores/helpers/use-store";
import FloatingLabel from "../../../components/shared/floating-label";
import AdvancedFiltersIcon from "../../../assets/images/svg/AdvancedFiltersIcon";
import { globalDateFormat } from "../../../constant";
import { dayjsTypeDate } from "../../../library/util/js-helper";

const { Link } = Typography;

type AdvanceFiltersProps = {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  advanceFilterList: SelectOptionsType[];
  setAdvanceFilterList: Function;
  applyFilters: Function;
};

function AdvanceFilter(props: AdvanceFiltersProps) {
  const {
    filters,
    setFilters,
    advanceFilterList,
    setAdvanceFilterList,
    applyFilters
  } = props;
  const { memberPatientListStore } = useStore();
  const [search, setSearch] = useState<string>("");
  const [openPopoverFilter, setOpenPopoverFilter] = useState<boolean>(false);

  /**
   * This function call initial render
   * Get the value of backend api and some value take from frontend
   * Converting value according to antd dropdown.
   */
  const getFilterListData = async () => {
    let secondaryList: any = {};
    secondaryList = memberPatientListStore.secondaryFilterList;
    let filterDataList = {
      ...secondaryList,
      ...AdvanceFilterList
    };

    filterDataList = Object.entries(filterDataList)
      ?.map(([key, value]: any[]) => {
        const placeholder = filterPlaceHolders[key];
        const type = typeof value;
        switch (type) {
          case "object": {
            const optionsList = value?.map((item: string, i: number) => {
              return {
                title: item,
                value: item,
                key: i
              };
            });

            return {
              key,
              placeholder,
              type: "dropdown",
              options: optionsList
            };
          }
          case "string": {
            return { key, placeholder, type: "date", value };
          }
          default: {
            return value;
          }
        }
      })
      .filter(Boolean);

    setAdvanceFilterList(filterDataList);
  };

  useEffect(() => {
    getFilterListData();
  }, [memberPatientListStore.secondaryFilterList]);

  // Popover trigger function call
  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopoverFilter(newOpen);
  };

  // Set the value in filters state
  const onChangeSelect = (value: string, key: any) => {
    setFilters((prev: any) => {
      if (prev && prev[key] && prev[key]?.length !== value?.length) {
        setSearch("");
      }
      if (prev[key]?.length && search) {
        return { ...prev, [key]: value };
      }
      return { ...prev, [key]: value };
    });
  };

  // This function will reset filters value when click of reset button
  const onResetSelect = () => {
    setFilters({
      groupName: [],
      groupNpi: [],
      fqhc: [],
      site: [],
      provider: [],
      providerType: [],
      healthSegment: [],
      careManagement: [],
      ageBand: [],
      lastDateOfVisit: "",
      lastDateOfService: "",
      careTeamSize: [],
      careGap: []
    });
  };

  /**
   * This function return dropdown and date field
   * @param item
   * @returns Dropdown and Date
   */
  const filterOptions = (item: SelectOptionsType) => {
    const key: string = item.key || "";
    switch (item.type) {
      case "dropdown":
        return (
          <Col span={8}>
            <FloatingLabel
              label={item.placeholder}
              value={filters[key as keyof FiltersType]}
            >
              <DropdownSelect
                popupClassName="advance-filter"
                key={item.key}
                data={item}
                style={{ width: "100%" }}
                value={filters[key as keyof FiltersType]}
                onChange={(value: string) => onChangeSelect(value, item.key)}
                showSearch={false}
                setSearch={setSearch}
                search={search}
              />
            </FloatingLabel>
          </Col>
        );
      case "date":
        return (
          <Col span={8}>
            <FloatingLabel
              label={item.placeholder}
              value={filters[key as keyof typeof item.key]}
            >
              <DatePicker
                popupClassName="advance-filter"
                key={item.key}
                format={globalDateFormat}
                value={dayjsTypeDate(filters[key as keyof typeof item.key])}
                placeholder={item.placeholder}
                style={{ width: "100%" }}
                onChange={(val, dateValue) =>
                  onChangeSelect(dateValue, item.key)
                }
              />
            </FloatingLabel>
          </Col>
        );
      default:
        return null;
    }
  };

  const content = () => {
    return (
      <>
        <Row justify="space-between" className="filter-header">
          <Col className="filter-header-column">
            <span className="filter-title">Additional Filter</span>
          </Col>
          <Col>
            <Button
              className="reset-filter-btn"
              type={ButtonType.Primary}
              onClick={onResetSelect}
            >
              Reset
            </Button>
            <Button
              className="close-filter-btn"
              onClick={() => setOpenPopoverFilter(false)}
            >
              Close
            </Button>
          </Col>
        </Row>
        <Divider style={{ margin: "10px 0px" }} />
        <Row gutter={[22, 22]} className="gutter-row">
          {advanceFilterList.map((item: SelectOptionsType) => {
            return filterOptions(item);
          })}
        </Row>
        <Row justify="end">
          <Col>
            <Button
              className="apply-filter-btn"
              onClick={() => {
                applyFilters();
                setOpenPopoverFilter(false);
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Popover
      overlayStyle={{ maxWidth: "715px", width: "100%" }}
      overlayClassName="advance-filter"
      placement="bottomRight"
      content={content}
      trigger="click"
      open={openPopoverFilter}
      onOpenChange={handleOpenChange}
    >
      <Tooltip
        overlayClassName="filters-tooltip"
        placement="top"
        title="Additional Filter"
      >
        <Link>
          <AdvancedFiltersIcon />
        </Link>
      </Tooltip>
    </Popover>
  );
}

export default AdvanceFilter;
