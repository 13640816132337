import React from "react";

type PropType = {
  disable?: boolean;
};

function ActionDisableIcon({ disable = false }: PropType) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={disable ? "0.2" : "1"}
        d="M7.00049 9.56055C6.09633 9.56055 5.36133 10.2955 5.36133 11.1939C5.36133 12.098 6.09633 12.833 7.00049 12.833C7.90466 12.833 8.63966 12.098 8.63966 11.1939C8.63966 10.2955 7.90466 9.56055 7.00049 9.56055Z"
        fill="#8F8F8F"
      />
      <path
        opacity={disable ? "0.2" : "1"}
        d="M7.00049 8.63966C7.90578 8.63966 8.63966 7.90578 8.63966 7.00049C8.63966 6.09521 7.90578 5.36133 7.00049 5.36133C6.09521 5.36133 5.36133 6.09521 5.36133 7.00049C5.36133 7.90578 6.09521 8.63966 7.00049 8.63966Z"
        fill="#8F8F8F"
      />
      <path
        opacity={disable ? "0.2" : "1"}
        d="M7.00049 1.16602C6.09633 1.16602 5.36133 1.90101 5.36133 2.80518C5.36133 3.70351 6.09633 4.43851 7.00049 4.43851C7.90466 4.43851 8.63966 3.70351 8.63966 2.80518C8.63966 1.90101 7.90466 1.16602 7.00049 1.16602Z"
        fill="#8F8F8F"
      />
    </svg>
  );
}

export default ActionDisableIcon;
