import React from "react";

function HamburgerIcon() {
  return (
    <svg
      width={36}
      height={33}
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.5" y={12} width={20} height="2.5" fill="white" />
      <rect x="6.5" y="18.5" width={20} height="2.5" fill="white" />
      <rect x="6.5" y={25} width={20} height="2.5" fill="white" />
    </svg>
  );
}

export default HamburgerIcon;
