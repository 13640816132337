import { Navigate, Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore } from "./stores/helpers/use-store";

function PrivateRoute() {
  const { authStore } = useStore();

  if (authStore.isAuthenticated()) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}
export default observer(PrivateRoute);
