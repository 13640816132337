import { useEffect, useState } from "react";
import lodash from "lodash";
import { observer } from "mobx-react";
import { ColumnsType } from "antd/es/table";
import { Col, Divider, Row, Typography, message } from "antd";
import { Modal } from "../../../../../components/shared/modal";
import "./ViewEditMyFavorite.scss";
import PrimaryFilterFields from "./PrimaryFilterFields";
import { Button, ButtonType } from "../../../../../components/shared/button";
import ColumnFilterFields from "./ColumnFilterFields";
import {
  FiltersKeyType,
  FiltersType,
  MyFavoriteType,
  PrimaryFilterType,
  TableRecord
} from "../../../../../models/memberPatientList.model";
import AdvanceFilterFields from "./AdvanceFilterFields";
import { useStore } from "../../../../../stores/helpers/use-store";
import { CloseButtonIcon } from "../../../../../assets/images/svg";

const { Title } = Typography;

type PropsType = {
  viewEditModalVisible: boolean;
  setViewEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  item: MyFavoriteType;
  columnsSource: ColumnsType<TableRecord>;
};

function ViewEditMyFavorite({
  viewEditModalVisible,
  setViewEditModalVisible,
  item,
  columnsSource
}: PropsType) {
  const { memberPatientListStore } = useStore();
  const [isEditable, setIsEditable] = useState(false);
  const [primaryFilters, setPrimaryFilters] = useState<PrimaryFilterType>({});
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filters, setFilters] = useState<FiltersType>({
    groupName: [],
    groupNpi: [],
    fqhc: [],
    site: [],
    provider: [],
    providerType: [],
    healthSegment: [],
    careManagement: [],
    ageBand: [],
    lastDateOfVisit: "",
    lastDateOfService: "",
    careTeamSize: [],
    careGap: []
  });

  // onModeChange get called on click of edit button
  const onModeChange = () => {
    setIsEditable(true);
  };

  /**
   * onSave get called on click of the Save button
   * Convert Standard and Additional filter value according to api request body.
   */
  const onSave = async () => {
    const secondaryFilter: any = {};
    const primaryFilter: any = {};
    Object.keys(filters).map((keys) => {
      if (lodash.isArray(filters[keys as FiltersKeyType])) {
        if (filters[keys as FiltersKeyType].length > 0) {
          secondaryFilter[keys] = filters[keys as FiltersKeyType];
        }
      } else if (lodash.isString(filters[keys as FiltersKeyType])) {
        if (filters[keys as FiltersKeyType]) {
          secondaryFilter[keys] = filters[keys as FiltersKeyType];
        }
      }
      return keys;
    });

    if (filters?.careTeamSize?.length > 0) {
      const careTeamSizeData = filters.careTeamSize.map((cateTeam) => {
        return cateTeam.split("-");
      });
      secondaryFilter.careTeamSize = careTeamSizeData.map((cateTeam) => {
        return {
          bandStartWith: Math.min(...cateTeam.map(Number)),
          bandEndWith: Math.max(...cateTeam.map(Number))
        };
      });
    }

    Object.keys(primaryFilters).map((key) => {
      const selectedPrimaryFilter =
        primaryFilters[key as keyof PrimaryFilterType];

      if (selectedPrimaryFilter) {
        if (lodash.isArray(selectedPrimaryFilter)) {
          if (selectedPrimaryFilter.length > 0) {
            const OppScore = selectedPrimaryFilter.filter(
              (selectedItem: string) => selectedItem !== "All"
            );
            primaryFilter[key] = OppScore;
          }
        } else {
          primaryFilter[key] = selectedPrimaryFilter;
        }
      }
      return key;
    });

    const response = await memberPatientListStore.updateMyFavorite({
      filterPreferenceId: item.filterPreferenceId,
      data: {
        filter: {
          ...(Object.keys(primaryFilter).length > 0 ? { primaryFilter } : {}),
          ...(Object.keys(secondaryFilter).length > 0
            ? { secondaryFilter }
            : {}),
          ...(Object.keys(selectedColumns).length > 0
            ? { manageColumn: selectedColumns }
            : {})
        }
      }
    });

    if (response.remote === "success") {
      message.success(response.data.data.message);
    }

    setIsEditable(false);
    setViewEditModalVisible(false);
  };

  // It will get called on change of Item value
  useEffect(() => {
    if (item.filter.primaryFilter) {
      setPrimaryFilters(item.filter.primaryFilter);
    }
    if (item.filter.secondaryFilter) {
      if (item.filter.secondaryFilter.careTeamSize) {
        const careTeamSizeGroup = item.filter.secondaryFilter.careTeamSize.map(
          (value) =>
            `${value.bandStartWith.toString()}-${value.bandEndWith.toString()}`
        );
        setFilters({
          ...filters,
          ...item.filter.secondaryFilter,
          careTeamSize: careTeamSizeGroup
        });
      } else {
        setFilters({
          ...filters,
          ...item.filter.secondaryFilter,
          careTeamSize: []
        });
      }
    }
    if (item.filter.manageColumn) {
      setSelectedColumns(item.filter.manageColumn);
    }
  }, [item]);

  return (
    <Modal
      visible={viewEditModalVisible}
      centered
      closeIcon={<CloseButtonIcon color="#FFFFFF" />}
      title={isEditable ? `Edit ${item.name}` : item.name}
      className="view-edit-modal"
      maskClosable
      closable
      onOk={() => setViewEditModalVisible(false)}
      onCancel={() => {
        setIsEditable(false);
        setViewEditModalVisible(false);
      }}
      footer={null}
    >
      <Row className="view-edit-modal-content">
        <Col span={24}>
          <Row align="middle">
            <Col span={22}>
              <Title className="title">
                {isEditable
                  ? "Select Filtering Criteria"
                  : "Filtering Criteria"}
              </Title>
            </Col>
            <Col span={2}>
              {isEditable ? (
                <Button type={ButtonType.Primary} onClick={onSave}>
                  Save
                </Button>
              ) : (
                <Button type={ButtonType.Primary} onClick={onModeChange}>
                  Edit
                </Button>
              )}
            </Col>
          </Row>
          <Divider className="title-divider" />

          <PrimaryFilterFields
            primaryFilters={primaryFilters}
            setPrimaryFilters={setPrimaryFilters}
            isEditable={isEditable}
            className="filter-fields"
          />
        </Col>
        <Col span={24}>
          <Title className="title">Additional Filter</Title>
          <Divider className="title-divider" />
          <AdvanceFilterFields
            filters={filters}
            setFilters={setFilters}
            isEditable={isEditable}
            className="filter-fields"
          />
        </Col>
        <Col span={24}>
          <Title className="title">Manage Columns</Title>
          <Divider className="title-divider" />
          <ColumnFilterFields
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            isEditable={isEditable}
            className="filter-fields"
            columnsSource={columnsSource}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default observer(ViewEditMyFavorite);
