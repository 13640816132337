import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Popover, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../stores/helpers/use-store";
import {
  DateIcon,
  EditIcon,
  ThreeDotVerticalIcon
} from "../../../../assets/images/svg";
import { getDateTimeFormat } from "../../../../library/util/js-helper";
import { Spin } from "../../../../components/shared/spin";

const { Link } = Typography;

function SharedByMenuItems({ handleEditIconClick }: any) {
  const SharedMenuItemComponent = useCallback(
    () => (
      <Link
        onClick={() => handleEditIconClick()}
        className="favorite-option-menu"
      >
        <EditIcon />
        <span className="icon-position">View/Edit</span>
      </Link>
    ),
    []
  );

  return <SharedMenuItemComponent />;
}

function TabItemSharedByOthers({
  setViewEditModalVisible,
  setSelectedItem,
  onClose
}: any) {
  const { memberPatientListStore } = useStore();
  const closeRef = useRef<HTMLInputElement>(null);

  const [list, setList] = useState<any>([]);

  const getListData = async () => {
    const response = await memberPatientListStore.getMyFavoriteListSharedBy({
      pageSize: 0,
      pageIndex: 0
    });
    setList(response);
  };

  useEffect(() => {
    getListData();
  }, []);

  return (
    <div className="scrollbar">
      {memberPatientListStore.loading ? (
        <Spin
          spinning={memberPatientListStore.loading}
          className="favorite-loader"
        />
      ) : (
        list?.map((item: any) => {
          return (
            <div style={{ padding: ".35rem", cursor: "pointer" }}>
              <div className="favorite-list-name">
                {item.name}
                <Popover
                  overlayStyle={{
                    maxWidth: "120px"
                  }}
                  arrow={false}
                  overlayClassName="my-favorite-tab-menu"
                  placement="leftTop"
                  content={
                    <SharedByMenuItems
                      item={item}
                      handleEditIconClick={() => {
                        setViewEditModalVisible(true);
                        setSelectedItem(item);
                        onClose();
                        closeRef.current?.click();
                      }}
                    />
                  }
                  trigger="click"
                >
                  <span style={{ float: "right" }} ref={closeRef}>
                    <ThreeDotVerticalIcon />
                  </span>
                </Popover>
              </div>
              <DateIcon />
              <span className="favorite-date">
                {getDateTimeFormat(item.updatedAt)}
              </span>
              <span className="favorite-share-user" title={item.sharedBy}>
                shared by {item.sharedBy}
              </span>
              <Divider
                style={{ margin: "5px 0px", border: "1px dashed #d9d9d9" }}
              />
            </div>
          );
        })
      )}
    </div>
  );
}

export default observer(TabItemSharedByOthers);
