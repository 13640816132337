import { Badge, Col, Form, Popover, Row, Select, Space, Switch } from "antd";
import { useState } from "react";
import { BellIcon } from "../../../../assets/images/svg";
import { Confirmation } from "./Confirmation";
import { Message } from "./Message";
import { MsgWithDis } from "./MsgWithDis";
import "./notification-modal.scss";

export function NotificationModal() {
  const [show, setShow] = useState(false);

  const onChange = (checked: boolean) => {
    setShow(checked);
  };

  const text = (
    <Row gutter={16}>
      <Col span={14}>
        <Form.Item label="Notifications" name="select">
          <Select defaultValue="All">
            <Select.Option value="All">All</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Space align="center">
          Mark all as read
          <Switch size="small" onChange={onChange} checked={show} />
        </Space>
      </Col>
    </Row>
  );

  const content = (
    <div>
      <Confirmation
        isMsgRead={!show}
        confirmMessage="John wan commented on particle health data files report"
      />
      <Message
        isMsgRead={!show}
        message={[
          "Peter Park left 6 comments on particle health data files report"
        ]}
      />
      <MsgWithDis
        message="John wan commented on particle health data files report"
        desc={[
          "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nisl dolor ut lacus ut ultrices. Velit facilisi nisl lectus elementum mi arcu placerat tortor.”"
        ]}
      />
      <Message
        isMsgRead={!show}
        message={[
          "Peter Park left 6 comments on particle health data files report"
        ]}
      />
    </div>
  );
  return (
    <Popover
      placement="bottomRight"
      overlayClassName="popover-style"
      title={text}
      content={content}
      arrow={false}
      trigger="click"
    >
      <Badge count={4} size="default" color="#EB5757">
        <BellIcon />{" "}
      </Badge>
    </Popover>
  );
}
