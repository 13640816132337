import { TreeSelect } from "antd";
import { ReactNode, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

interface IProps {
  className?: string;
  placeholder?: string;
  treeData: { id: string; value: string; title: string }[];
  value: any;
  disabled?: boolean;
  handleOnChange: any;
}

export function DropdownWithCheckbox(props: IProps) {
  const { value, handleOnChange } = props;
  const [iconToggle, setIconToggle] = useState<boolean>(false);
  const { className, placeholder, treeData, disabled } = props;
  return (
    <TreeSelect
      treeDataSimpleMode
      value={value}
      showSearch={false}
      className={className}
      placeholder={placeholder}
      treeData={treeData}
      treeCheckable
      onChange={(newValue: string[], label: ReactNode[], extra: any) =>
        handleOnChange(newValue, extra)
      }
      maxTagCount={1}
      suffixIcon={iconToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}
      disabled={disabled}
      showArrow={!disabled}
      onDropdownVisibleChange={() => setIconToggle(!iconToggle)}
    />
  );
}
