import React, { useEffect, useState } from "react";
import { Card, Form, Input, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonHTMLType,
  ButtonSize
} from "../../components/shared/button";
import { ErrorPopUp } from "../../components/shared/error-messages";
import { useStore } from "../../stores/helpers/use-store";
import { SuccessErrorMessages } from "../../constant/messages/login";
import { RegExp } from "../../constant";

function ResetPassword() {
  const { loginStore } = useStore();
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState({ password: "", cPassword: "" });

  /**
   * Check token validation
   * Invalid token user redirect to login page
   */
  useEffect(() => {
    if (!token) {
      ErrorPopUp({
        type: "error",
        title: "Error",
        buttons: [
          {
            title: "Ok",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "error"
          }
        ],
        description: ["Invalid email token."]
      });
    }
  }, [token]);

  /**
   * onFinish will get called on click of Reset Password button.
   * This function will validate resetToken, password and confirmPassword.
   * It will call resetPassword service to get response.
   */
  const onFinish = async () => {
    const payload = {
      resetToken: token,
      password: formFields.password,
      confirmPassword: formFields.cPassword
    };

    const response = await loginStore.resetPassword(payload);

    if (response.remote === "success") {
      ErrorPopUp({
        type: "success",
        title: "Password Changed",
        description: [
          "Your password has been changed successfully",
          "Please login with the new password"
        ],
        buttons: [
          {
            title: "Login",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "success"
          }
        ]
      });
    }
  };

  return (
    <Card className="card-form">
      <h4 className="page-heading">Reset Your Password</h4>

      <Form layout="vertical" form={form}>
        <Form.Item
          label="Enter new password"
          name="password"
          rules={[
            {
              required: true,
              min: 4
            }
          ]}
          style={{ marginBottom: 20 }}
        >
          <Input.Password
            data-testid="password"
            name="password"
            type="password"
            onChange={(e) =>
              setFormFields({ ...formFields, password: e.target.value })
            }
            value={formFields.password}
            className="form-input"
          />
        </Form.Item>

        <Form.Item
          label="Confirm new password"
          name="cPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              min: 4,
              pattern: RegExp.PasswordExp,
              message: SuccessErrorMessages.PasswordValidation
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords does not match!"));
              }
            })
          ]}
          style={{ marginBottom: 20 }}
        >
          <Input.Password
            data-testid="confirm-password"
            name="cPassword"
            type="password"
            onChange={(e) =>
              setFormFields({ ...formFields, cPassword: e.target.value })
            }
            value={formFields.password}
            className="form-input"
          />
        </Form.Item>

        <Form.Item shouldUpdate style={{ marginBottom: 10 }}>
          <Button
            className="btn-primary"
            htmlType={ButtonHTMLType.Submit}
            size={ButtonSize.Large}
            onClick={onFinish}
          >
            <span data-testid="btn-reset-password">Reset Password</span>
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default ResetPassword;
