import React from "react";

function ThreeDotVerticalIcon() {
  return (
    <svg
      width={20}
      height={4}
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4C11.1046 4 12 3.10457 12 2C12 0.895431 11.1046 0 10 0C8.89543 0 8 0.895431 8 2C8 3.10457 8.89543 4 10 4Z"
        fill="#4A5C75"
      />
      <path
        d="M18 4C19.1046 4 20 3.10457 20 2C20 0.895431 19.1046 0 18 0C16.8954 0 16 0.895431 16 2C16 3.10457 16.8954 4 18 4Z"
        fill="#4A5C75"
      />
      <path
        d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.89543 0 0 0.895431 0 2C0 3.10457 0.89543 4 2 4Z"
        fill="#4A5C75"
      />
    </svg>
  );
}

export default ThreeDotVerticalIcon;
