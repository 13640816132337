import { Api } from "../components/base/api/base.api";
import {
  SIGN_UP_INVITE,
  SIGN_UP_RESEND_INVITE,
  USER_LIST
} from "../components/base/api/endpoints";
import {
  SignUpLinkReq,
  SignUpLinkRes,
  SignUpResendLinkReq,
  SignUpResendLinkRes,
  UserListRequest,
  UserListResponse
} from "../models/admin.model";
import { ErrorResult, SuccessResult } from "../models/base";

export class AdminService {
  /**
   * getSignUpInvite call the endpoint and get response from backend
   * @param request
   * @returns response
   */
  getSignUpInvite = async (
    request: SignUpLinkReq
  ): Promise<SuccessResult<SignUpLinkRes> | ErrorResult> => {
    try {
      const response = await Api.post<SignUpLinkRes>(SIGN_UP_INVITE, request);
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * getSignUpResendInvite call the endpoint and get response from backend
   * @param request
   * @returns response
   */
  getSignUpResendInvite = async (
    request: SignUpResendLinkReq
  ): Promise<SuccessResult<SignUpResendLinkRes> | ErrorResult> => {
    try {
      const response = await Api.post<SignUpLinkRes>(
        SIGN_UP_RESEND_INVITE,
        request
      );
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",

        error: error.response.data
      };
    }
  };

  /**
   * getUserList call the endpoint and fetches the list of users from backend
   * @param request
   * @returns response
   */
  getUserList = async (
    request: UserListRequest
  ): Promise<SuccessResult<UserListResponse> | ErrorResult> => {
    try {
      const response = await Api.get<UserListResponse>(USER_LIST, {
        params: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",

        error: error.response.data
      };
    }
  };
}
