export const SuccessErrorMessages = {
  OtpSendMessage: "OTP sent Successfully",
  LoginFailedMessage: "Your Account is temporarily blocked",
  LoginFailedDescription:
    "Please contact Yuvo Health Administration for more information",
  PasswordValidation:
    "Password must contain minimum length of 8 characters. Include at least one uppercase letter, one lowercase letter, one number and one special character",
  ConfirmPassword: "Please confirm password",
  PasswordNotMatch:
    "Passwords do not match .Please ensure that the passwords you entered match and try again",
  EmailValidation:
    "Email ID should contain a valid  email prefix, email domain and '@' symbol ",
  EnterPassword: "Please enter password",
  EnterEmailId: "Please enter E-mail ID",
  EnterValidEmailId: "Please enter a valid E-mail ID",
  NameValidation: "Please enter a valid name",
  PhoneNumberValidation: "Phone number must contain 10 digits."
};
