import { makeAutoObservable, observable } from "mobx";
import { PatientsListReq } from "../models";
import {
  RecentSearches,
  PrimaryFilterList,
  SecondaryFilterList,
  UpdateMyFavoriteReq,
  MyFavoriteReq,
  GetMyFavoriteReq,
  MyFavoriteType,
  RenameMyFavoriteReq,
  DeleteMyFavoriteReq
} from "../models/memberPatientList.model";
import { MemberPatientList } from "../services";

export class MemberPatientListStore {
  @observable
  memberPatientList = new MemberPatientList();

  memberList: any = [];

  totalPatients: number = 0;

  isFilterApplied: boolean = false;

  recentSearches: RecentSearches[] = [];

  primaryFilterList: PrimaryFilterList = undefined;

  secondaryFilterList: SecondaryFilterList = undefined;

  myFavorite: any;

  myFavoriteList: MyFavoriteType[] = [];

  myFavoriteListSharedBy: MyFavoriteType[] = [];

  loading: boolean = false;

  isPatientListLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getMemberPatientList = async (request: PatientsListReq) => {
    this.isPatientListLoading = true;
    const response = await this.memberPatientList.getMemberPatientList(request);
    this.isPatientListLoading = false;
    if (response.remote === "success") {
      if (response.data.meta.count !== undefined) {
        this.totalPatients = response.data.meta.count;
      }
      if (request.primaryFilter || request.secondaryFilter) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
      this.memberList = response.data.data;
      return this.memberList;
    }
    return response;
  };

  updateMyFavorite = async (request: UpdateMyFavoriteReq) => {
    const response = await this.memberPatientList.updateMyFavorite(request);
    if (response.remote === "success") {
      return response;
    }
    return response;
  };

  dropdownMemberListResponse = async () => {
    const response = await this.memberPatientList.dropMemberListRes();
    if (response.remote === "success") {
      this.recentSearches = response.data.data;
      return response;
    }
    return response;
  };

  getFilterList = async () => {
    const response = await this.memberPatientList.getFilterList();
    if (response.remote === "success") {
      this.primaryFilterList = response.data.data.primaryFilterList;
      this.secondaryFilterList = response.data.data.secondaryFilterList;
      return response;
    }
    return response;
  };

  saveMyFavorite = async (request: MyFavoriteReq) => {
    this.loading = true;
    const response = await this.memberPatientList.saveMyFavorite(request);
    this.loading = false;
    if (response.remote === "success" || response.remote === "error") {
      this.myFavorite = response;
      return this.myFavorite;
    }
    return response;
  };

  getMyFavoriteList = async (request: GetMyFavoriteReq) => {
    this.loading = true;
    const response = await this.memberPatientList.getMyFavoriteList(request);
    this.loading = false;

    if (response.remote === "success") {
      this.myFavoriteList = response.data.data;
      return response.data.data;
    }
    return response;
  };

  getMyFavoriteListSharedBy = async (request: GetMyFavoriteReq) => {
    this.loading = true;
    const response = await this.memberPatientList.getMyFavoriteListSharedBy(
      request
    );
    this.loading = false;
    if (response.remote === "success") {
      this.myFavoriteListSharedBy = response.data.data;
      return response.data.data;
    }
    return response;
  };

  shareSavedFilter = async (id: any) => {
    this.loading = true;
    const response = await this.memberPatientList.shareSavedFilter(id);
    this.loading = false;
    return response;
  };

  renameMyFavorite = async (request: RenameMyFavoriteReq) => {
    const response = await this.memberPatientList.renameMyFavorite(request);
    if (response.remote === "success") {
      return response;
    }
    return response;
  };

  deleteMyFavorite = async (request: DeleteMyFavoriteReq) => {
    this.loading = true;
    const response = await this.memberPatientList.deleteMyFavorite(request);
    this.loading = false;
    if (response.remote === "success") {
      return response;
    }
    return response;
  };
}
