import { ValueType } from "../multilevel-select";
import "./index.scss";

type PropsType = {
  children: JSX.Element;
  label: string;
  disabled?: boolean;
  value?: string | string[] | ValueType;
  className?: string;
};

function FloatingLabel(props: PropsType) {
  const { children, label, disabled, value, className } = props;

  const labelClass =
    value && value.length !== 0
      ? `label label-float ${className}`
      : disabled
      ? `label disabled  ${className}`
      : `label readonly  ${className}`;

  return (
    <div className="label-outer">
      {children}
      <label htmlFor={label} className={labelClass}>
        {label}
      </label>
    </div>
  );
}

export default FloatingLabel;
