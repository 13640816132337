import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Card, Col, Form, Input, Modal, Row, Typography } from "antd";
import {
  Button,
  ButtonHTMLType,
  ButtonSize
} from "../../components/shared/button";
import "./ForgotPassword.scss";
import { useStore } from "../../stores/helpers/use-store";
import { ErrorPopUp } from "../../components/shared/error-messages";
import logo from "../../assets/images/yuvo-logo.svg";

const { Link } = Typography;

function ForgotPassword() {
  const { loginStore } = useStore();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>("");

  /**
   * submitForm will get called on click of Reset Password button.
   * This function will validate email and it will call forgotPassword service to get response
   */

  const submitForm = async () => {
    const response = await loginStore.forgotPassword({ email });
    if (response.remote === "success") {
      ErrorPopUp({
        type: "success",
        title: "Reset Link Sent",
        buttons: [
          {
            title: "Ok ",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "success"
          }
        ],
        description: [
          `Please check your mail inbox <b>${email}</b> and reset your password from the link`
        ]
      });
    }
  };

  return (
    <Card className="card-form" style={{ paddingBottom: 110 }}>
      <div>
        <img
          src={logo}
          className="logo-slider hide-for-large-screen"
          alt="Yuvo Health"
          width="128px"
          height="48px"
        />
      </div>
      <h4 style={{ paddingBottom: 15 }} className="page-heading">
        Forgot Password?
      </h4>

      <Form layout="vertical" form={form} onFinish={submitForm}>
        <Form.Item
          label="Enter Your Email Id"
          name="email"
          className="username"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter your email id"
            }
          ]}
          style={{ marginBottom: 15 }}
        >
          <Input
            data-testid="email"
            name="email"
            type="email"
            value={email}
            placeholder="e.g. uname@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
          />
        </Form.Item>
        <p style={{ fontSize: 15, paddingTop: 15, paddingBottom: 20 }}>
          We will send you an email with a link to reset password of your
          account
        </p>

        <Form.Item shouldUpdate>
          <Button
            className="btn-primary"
            htmlType={ButtonHTMLType.Submit}
            size={ButtonSize.Large}
            disabled={loginStore.loading}
          >
            <span data-testid="btn-reset-password">Reset Password</span>
          </Button>
        </Form.Item>

        <Row justify="end">
          <Col>
            <Link
              style={{ color: "#3A306C" }}
              href="/login"
              disabled={loginStore.loading}
            >
              Back to sign in
            </Link>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default observer(ForgotPassword);
