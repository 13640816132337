import { Api } from "../components/base/api/base.api";
import {
  GOOGLE_LOGIN,
  USER_LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_OTP,
  SEND_OTP
} from "../components/base/api/endpoints";
import {
  ForgotPasswordRequest,
  LoginResponse,
  ResetPasswordRequest,
  ResetPasswordResponse
} from "../models";
import { ErrorResult, SuccessResult } from "../models/base";
import type { GoogleSignInRequest } from "../models/index";
import {
  SendOTPRequest,
  SendOTPResponse,
  UserLoginResponse,
  VerifyOTPRequest,
  VerifyOTPResponse
} from "../models/login.model";

export class Login {
  /**
   * googleSignIn call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  googleSignIn = async (
    request: GoogleSignInRequest
  ): Promise<SuccessResult<LoginResponse> | ErrorResult> => {
    try {
      const response = await Api.post<LoginResponse>(
        GOOGLE_LOGIN,
        {},
        { headers: { ...request } }
      );
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * userLogin call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */

  userLogin = async (
    request: any
  ): Promise<SuccessResult<UserLoginResponse> | ErrorResult> => {
    try {
      const response = await Api.post<UserLoginResponse>(USER_LOGIN, request);

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * forgotPassword call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */

  forgotPassword = async (
    request: any
  ): Promise<SuccessResult<ForgotPasswordRequest> | ErrorResult> => {
    try {
      const response = await Api.post<ForgotPasswordRequest>(
        FORGOT_PASSWORD,
        request
      );
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * resetPassword call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  resetPassword = async (
    request: ResetPasswordRequest
  ): Promise<SuccessResult<ResetPasswordResponse> | ErrorResult> => {
    try {
      const response = await Api.post<ResetPasswordResponse>(
        RESET_PASSWORD,
        request
      );
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * sendOTP call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  sendOTP = async (
    request: SendOTPRequest
  ): Promise<SuccessResult<SendOTPResponse> | ErrorResult> => {
    try {
      const response = await Api.post<SendOTPResponse>(SEND_OTP, request);
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * verifyOTP call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  verifyOTP = async (
    request: VerifyOTPRequest
  ): Promise<SuccessResult<VerifyOTPResponse> | ErrorResult> => {
    try {
      const response = await Api.post<VerifyOTPResponse>(VERIFY_OTP, request);
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };
}
