import React, { useEffect, useState } from "react";
import "./Admin.scss";
import NewUser from "./NewUser";
import UserList from "./UserList";
import { useStore } from "../../stores/helpers/use-store";

import { UserListRequest } from "../../models/admin.model";
import { PageSize, RiskAdjustmentPageIndex } from "../../constant";

function AdminUsers() {
  const { adminStore } = useStore();
  const [getUsersListReq, setGetUsersListReq] = useState<UserListRequest>({
    pageIndex: RiskAdjustmentPageIndex[0],
    pageSize: PageSize[0]
  });

  const [searchText, setSearchText] = useState<string>("");

  // Get user list from api
  useEffect(() => {
    adminStore.getUserList(getUsersListReq);
  }, [getUsersListReq]);

  useEffect(() => {}, [searchText]);

  return (
    <>
      <div className="user-form">
        <NewUser
          setGetUsersListReq={setGetUsersListReq}
          getUsersListReq={getUsersListReq}
        />
      </div>
      <UserList
        setGetUsersListReq={setGetUsersListReq}
        getUsersListReq={getUsersListReq}
        setSearchText={setSearchText}
        searchText={searchText}
      />
    </>
  );
}
export default AdminUsers;
