import React from "react";

function SearchIcon({ onClick }: any) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M17.7938 17.8013C17.6649 17.9284 17.4912 17.9997 17.3101 18C17.1266 17.9992 16.9504 17.9281 16.8178 17.8013L13.0863 14.0614C11.5147 15.3814 9.49417 16.0437 7.44612 15.9102C5.39807 15.7767 3.48062 14.8578 2.09367 13.3451C0.706717 11.8323 -0.042643 9.84257 0.00187585 7.7908C0.0463947 5.73902 0.881355 3.78363 2.33262 2.33247C3.78388 0.881299 5.73939 0.0463917 7.7913 0.00187572C9.84321 -0.0426403 11.8331 0.706672 13.3459 2.09353C14.8588 3.4804 15.7778 5.39773 15.9112 7.44564C16.0447 9.49356 15.3824 11.5139 14.0623 13.0854L17.7938 16.8167C17.8591 16.881 17.911 16.9576 17.9464 17.0422C17.9818 17.1267 18 17.2174 18 17.309C18 17.4007 17.9818 17.4914 17.9464 17.5759C17.911 17.6604 17.8591 17.737 17.7938 17.8013ZM7.98132 14.5451C9.2797 14.5451 10.5489 14.1601 11.6285 13.4388C12.708 12.7175 13.5495 11.6923 14.0463 10.4929C14.5432 9.29339 14.6732 7.97352 14.4199 6.70017C14.1666 5.42682 13.5414 4.25718 12.6233 3.33914C11.7052 2.42111 10.5355 1.79592 9.26203 1.54264C7.9886 1.28935 6.66865 1.41935 5.46911 1.91618C4.26956 2.41302 3.24429 3.25438 2.52295 4.33388C1.80161 5.41337 1.4166 6.68251 1.4166 7.98081C1.41888 9.72106 2.11125 11.3894 3.34188 12.6199C4.57251 13.8505 6.24095 14.5428 7.98132 14.5451Z"
        fill="#5B6E88"
      />
    </svg>
  );
}

export default SearchIcon;
