import React from "react";

function DropdownCloseIcon() {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 7.33301L5.83341 0.666341L8.10623e-05 7.33301L11.6667 7.33301Z"
        fill="#8F8F8F"
      />
    </svg>
  );
}

export default DropdownCloseIcon;
