type PropsType = {
  color?: string;
};
function QualityMgmtIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8115 16.1638C14.0295 16.1638 17.4489 12.7444 17.4489 8.52635C17.4489 4.30831 14.0295 0.888916 9.8115 0.888916C5.59347 0.888916 2.17407 4.30831 2.17407 8.52635C2.17407 12.7444 5.59347 16.1638 9.8115 16.1638Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.59776 13.0972L1 17.5341L4.06537 17.3679L5.45777 20.1007L7.75418 16.2145C6.01888 15.7677 4.62648 14.5104 3.59776 13.0972Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0671 13.0972C15.0487 14.5207 13.646 15.7573 11.9106 16.2145L14.2071 20.1111L15.5995 17.3783L18.6648 17.5445L16.0671 13.0972Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1759 7.56184C14.3734 7.3748 14.2591 7.03188 13.9889 7.00071L11.4847 6.63702C11.3807 6.61624 11.2872 6.5539 11.2353 6.44999L10.113 4.17434C9.98834 3.92496 9.63506 3.92496 9.52076 4.17434L8.39851 6.44999C8.34656 6.54351 8.25305 6.61624 8.14914 6.63702L5.64489 7.00071C5.37472 7.04228 5.27082 7.3748 5.45786 7.56184L7.27629 9.3283C7.35942 9.41143 7.39059 9.51534 7.36981 9.61925L6.94377 12.1131C6.89182 12.3833 7.18276 12.5911 7.42175 12.456L9.66622 11.2818C9.75974 11.2299 9.88445 11.2299 9.97797 11.2818L12.2224 12.456C12.4614 12.5807 12.7524 12.3729 12.7004 12.1131L12.2744 9.61925C12.2536 9.51534 12.2952 9.40103 12.3679 9.3283L14.1759 7.56184Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default QualityMgmtIcon;
