/* eslint react/prop-types: 0 */
/* eslint react/no-danger: 0 */
import { Modal } from "antd";
import { CloseIcon, TickIcon } from "../../../assets/images/svg";
import "./ErrorMessags.scss";

type ButtonType = {
  title: string;
  handleOnClick: any;
  type: "success" | "error" | "default";
};

type ErrorPopUpType = {
  type: "info" | "success" | "error" | "warn" | "warning" | "confirm";
  title: string;
  description?: any | undefined;
  buttons?: ButtonType[] | [];
};

const ErrorPopUp = (props: ErrorPopUpType) => {
  const { type, title, description, buttons } = props;

  const getHeaderIcon = (iconType: string) => {
    switch (iconType) {
      case "success":
        return <TickIcon style={{ margin: "0 auto" }} />;

      case "error":
        return <CloseIcon style={{ margin: "0 auto" }} />;

      default:
        return <TickIcon style={{ margin: "0 auto" }} />;
    }
  };

  function ModalContent() {
    return (
      <div style={{ background: "#fff" }}>
        <div className={`modal-header-icon ${type}`}>{getHeaderIcon(type)}</div>
        <h1>{title}</h1>
        {description?.map((item: any) => (
          <p key={item} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
        <div style={{ marginTop: "25px" }}>
          {buttons &&
            buttons?.length > 0 &&
            buttons?.map((button) => {
              return (
                <button
                  key={`error-btn-${button.title}`}
                  className={`btn btn-${button.type}`}
                  onClick={button.handleOnClick}
                  type="button"
                  data-testid={`error-btn-${button.title}`}
                >
                  {button.title}
                </button>
              );
            })}
        </div>
      </div>
    );
  }

  return Modal.confirm({
    className: "error-modal",
    closable: false,
    okCancel: false,
    content: <ModalContent />,
    footer: null,
    icon: null,
    keyboard: false,
    width: "335px"
  });
};

export default ErrorPopUp;
