import { message, notification } from "antd";
import { Error } from "../../../library/error";
import { AppErrorType, getErrors } from "../../../library/util/error";

const disableInterceptor = (config: any = {}) => {
  return !!config.disableInterceptor;
};

export const requestHandler = (request: any) => {
  if (localStorage.getItem("accessToken") !== "") {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    };
  }
  return request;
};

export const errorHandler = (error: any) => {
  if (!disableInterceptor(error.config)) {
    const errors = getErrors(error);
    errors
      .filter((x) => x.type !== AppErrorType.Validation)
      .forEach((x) => {
        notification.error({
          message: x.errorMessage,
          description: x.description
        });
      });

    const validationErrors = errors.filter(
      (x) => x.type === AppErrorType.Validation
    );
    if (validationErrors.length) {
      message.error({
        content: <Error errors={validationErrors} />
      });
    }
  }
};

export const successHandler = (response: any) => {
  return response;
};
