import React from "react";

function BellIcon() {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5413 14.4139C16.4881 13.1248 15.7444 12.4685 15.7444 8.9145C15.7444 5.65991 14.0825 4.5004 12.7146 3.93726C12.5329 3.86261 12.3619 3.69116 12.3065 3.50454C12.0666 2.68792 11.3939 1.96851 10.4998 1.96851C9.60563 1.96851 8.93257 2.68833 8.69509 3.50536C8.63971 3.69403 8.46868 3.86261 8.28698 3.93726C6.91747 4.50122 5.25716 5.65663 5.25716 8.9145C5.25511 12.4685 4.51149 13.1248 3.45821 14.4139C3.02181 14.9479 3.40407 15.7498 4.16737 15.7498H16.8363C17.5955 15.7498 17.9753 14.9454 17.5413 14.4139Z"
        stroke="#4A5C75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 15.749V16.4053C13.125 17.1015 12.8484 17.7691 12.3562 18.2614C11.8639 18.7537 11.1962 19.0303 10.5 19.0303C9.80381 19.0303 9.13613 18.7537 8.64384 18.2614C8.15156 17.7691 7.875 17.1015 7.875 16.4053V15.749"
        stroke="#4A5C75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BellIcon;
