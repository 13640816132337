import { useEffect, useState } from "react";
import { Col, DatePicker, DatePickerProps, Row, Select } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import FloatingLabel from "../../../../../components/shared/floating-label";
import {
  MultiLevelSelect,
  ValueType
} from "../../../../../components/shared/multilevel-select";
import { DropdownWithCheckbox } from "../../../../../components/shared/dropdown-with-checkbox";
import { TreeData } from "../../../../../constant/RiskAdjustment";
import { useStore } from "../../../../../stores/helpers/use-store";
import { PrimaryFilterType, PrimaryListType } from "../../../../../models";
import { dayjsTypeDate } from "../../../../../library/util/js-helper";
import "./ViewEditMyFavorite.scss";
import { globalDateFormat } from "../../../../../constant";

type PropsType = {
  primaryFilters: PrimaryFilterType;
  setPrimaryFilters: React.Dispatch<React.SetStateAction<PrimaryFilterType>>;
  className: string;
  isEditable: boolean;
};

function PrimaryFilterFields({
  primaryFilters,
  setPrimaryFilters,
  className = "",
  isEditable
}: PropsType) {
  const { memberPatientListStore } = useStore();
  const [iconTogglePY, setIconTogglePY] = useState<boolean>(false);
  const [iconToggleMY, setIconToggleMY] = useState<boolean>(false);
  const [riskScoreState, setRiskScoreState] = useState<any>();
  const [opportunityScoreState, setOpportunityScoreState] = useState<any>();
  const [allRisk, setAllRisk] = useState<string>("");
  const [allOpp, setAllOpp] = useState<string>("");
  const [healthPlanState, setHealthPlanState] = useState<
    ValueType | undefined
  >();
  const [primaryList, setPrimaryList] = useState<PrimaryListType>({
    performanceYear: [],
    measurementYear: []
  });

  /**
   * handleRiskScoreChange will get called on select or change value of Risk score dropdown
   * @param newValue
   * @param extra
   */
  const handleRiskScoreChange = (newValue: string[], extra: any) => {
    if (extra.triggerValue === "All") {
      if (newValue.some((item) => item === "All")) {
        setAllRisk("All");
      } else {
        setAllRisk("None");
      }
    } else if (
      newValue.length === TreeData.length - 1 &&
      (allRisk === "None" || allRisk === "Some")
    ) {
      setAllRisk("All");
    } else if (newValue.length === 0) {
      setAllRisk("None");
    } else {
      const tempVal = newValue.filter((item) => item !== "All");
      setRiskScoreState(tempVal);
      setPrimaryFilters({ ...primaryFilters, riskScore: tempVal });
      setAllRisk("Some");
    }
  };

  // This will get called when we select Risk score value
  useEffect(() => {
    const tempArr: string[] = [];
    if (allRisk === "All") {
      TreeData.forEach((item) => {
        tempArr.push(item.value);
      });

      setRiskScoreState(tempArr);
      setPrimaryFilters({ ...primaryFilters, riskScore: tempArr });
    } else if (allRisk === "None") {
      setRiskScoreState(undefined);
      setPrimaryFilters((prevState: any) => {
        const { riskScore, ...rest } = prevState;
        return rest;
      });
    }
  }, [allRisk]);

  /**
   * handleOpportunityScore will get called on select or change value of Opportunity score dropdown
   * @param newValue
   * @param extra
   */
  const handleOpportunityScore = (newValue: string[], extra: any) => {
    if (extra.triggerValue === "All") {
      if (newValue.some((item) => item === "All")) {
        setAllOpp("All");
      } else {
        setAllOpp("None");
      }
    } else if (
      newValue.length === TreeData.length - 1 &&
      (allOpp === "None" || allOpp === "Some")
    ) {
      setAllOpp("All");
    } else if (newValue.length === 0) {
      setAllOpp("None");
    } else {
      const tempVal = newValue.filter((item) => item !== "All");
      setOpportunityScoreState(tempVal);
      setPrimaryFilters({ ...primaryFilters, opportunityScore: tempVal });
      setAllOpp("Some");
    }
  };

  // This will get called when we select Opportunity score value
  useEffect(() => {
    const tempArr: string[] = [];
    if (allOpp === "All") {
      TreeData.forEach((item) => {
        tempArr.push(item.value);
      });
      setOpportunityScoreState(tempArr);
      setPrimaryFilters({ ...primaryFilters, opportunityScore: tempArr });
    } else if (allOpp === "None") {
      setOpportunityScoreState(undefined);
      setPrimaryFilters((prevState: any) => {
        const { opportunityScore, ...rest } = prevState;
        return rest;
      });
    }
  }, [allOpp]);

  useEffect(() => {
    const perfYear = memberPatientListStore.primaryFilterList?.performanceYear
      .map((item) => {
        return {
          value: item,
          label: item
        };
      })
      .filter(Boolean);

    const measYear = memberPatientListStore.primaryFilterList?.measurementYear
      .map((item) => {
        return {
          value: item,
          label: item
        };
      })
      .filter(Boolean);

    const healthPlan = {
      objectKey: "healthPlan",
      dropdownTitle: "Health Plan",
      showSearch: true,
      value: memberPatientListStore.primaryFilterList
        ? memberPatientListStore.primaryFilterList.healthPlan.map((value) => {
            const healthPlanOption = {
              value: value.name,
              label: value.name
            };

            if (value.lineOfBusiness) {
              return {
                ...healthPlanOption,
                subOption: {
                  objectKey: "lineOfBusiness",
                  dropdownTitle: "Line Of Business",
                  showSearch: true,
                  value: value.lineOfBusiness.map((lineOfBusiness) => {
                    return {
                      value: lineOfBusiness,
                      label: lineOfBusiness
                    };
                  })
                }
              };
            }
            return healthPlanOption;
          })
        : []
    };
    if (perfYear && measYear) {
      setPrimaryList({
        performanceYear: perfYear,
        measurementYear: measYear,
        healthPlan
      });
    }
  }, [memberPatientListStore.primaryFilterList]);

  const handleHealthPlanChange = (value: ValueType) => {
    setPrimaryFilters({ ...primaryFilters, ...value });
  };

  const onAsOfMonthChange: DatePickerProps["onChange"] = (date, dateString) => {
    setPrimaryFilters({ ...primaryFilters, asOfMonth: dateString });
  };

  useEffect(() => {
    setRiskScoreState(primaryFilters.riskScore);
    setOpportunityScoreState(primaryFilters.opportunityScore);
  }, [primaryFilters]);

  useEffect(() => {
    // Health plan value set according to api request
    if (primaryFilters.healthPlan && primaryFilters.lineOfBusiness) {
      setHealthPlanState({
        healthPlan: primaryFilters.healthPlan,
        lineOfBusiness: primaryFilters.lineOfBusiness
      });
    } else if (primaryFilters.healthPlan) {
      setHealthPlanState({ healthPlan: primaryFilters.healthPlan });
    }
  }, [primaryFilters.healthPlan, primaryFilters.lineOfBusiness]);

  return (
    <Row gutter={[24, 24]} className={className}>
      <Col xl={3} lg={3} sm={4} xs={6}>
        <FloatingLabel
          label="P.Y."
          disabled={!isEditable}
          value={primaryFilters.performanceYear}
        >
          <Select
            className={
              !isEditable
                ? primaryFilters.performanceYear
                  ? "readonly dropdown-year"
                  : "disabled dropdown-year"
                : "dropdown-year"
            }
            onChange={(value) =>
              setPrimaryFilters((prevState: PrimaryFilterType) => ({
                ...prevState,
                performanceYear: value
              }))
            }
            options={primaryList.performanceYear}
            value={primaryFilters.performanceYear}
            disabled={!isEditable}
            showArrow={isEditable}
            suffixIcon={
              iconTogglePY ? <CaretUpOutlined /> : <CaretDownOutlined />
            }
            onDropdownVisibleChange={() => setIconTogglePY(!iconTogglePY)}
          />
        </FloatingLabel>
      </Col>
      <Col xl={3} lg={3} sm={4} xs={6}>
        <FloatingLabel
          label="M.Y."
          disabled={!isEditable}
          value={primaryFilters.measurementYear}
        >
          <Select
            className={
              !isEditable
                ? primaryFilters.measurementYear
                  ? "readonly dropdown-year"
                  : "disabled dropdown-year"
                : "dropdown-year"
            }
            onChange={(value) =>
              setPrimaryFilters((prevState: PrimaryFilterType) => ({
                ...prevState,
                measurementYear: value
              }))
            }
            options={primaryList.measurementYear}
            disabled={!isEditable}
            showArrow={isEditable}
            value={primaryFilters.measurementYear}
            suffixIcon={
              iconToggleMY ? <CaretUpOutlined /> : <CaretDownOutlined />
            }
            onDropdownVisibleChange={() => setIconToggleMY(!iconToggleMY)}
          />
        </FloatingLabel>
      </Col>
      <Col xl={6} lg={6} sm={8} xs={12}>
        <FloatingLabel
          label="As of Month"
          disabled={!isEditable}
          value={primaryFilters.asOfMonth}
        >
          <DatePicker
            disabled={!isEditable}
            className={
              !isEditable
                ? primaryFilters.asOfMonth
                  ? "readonly datepicker"
                  : "disabled datepicker"
                : "datepicker"
            }
            onChange={onAsOfMonthChange}
            format={globalDateFormat}
            value={dayjsTypeDate(primaryFilters.asOfMonth)}
          />
        </FloatingLabel>
      </Col>

      <Col xl={6} lg={6} sm={8} xs={12}>
        <FloatingLabel
          label="Health Plan"
          disabled={!isEditable}
          value={healthPlanState}
        >
          <MultiLevelSelect
            options={primaryList.healthPlan}
            onSelect={handleHealthPlanChange}
            readonly={!isEditable}
            disabled={!isEditable && !healthPlanState}
            value={healthPlanState}
          />
        </FloatingLabel>
      </Col>
      <Col xl={6} lg={6} sm={8} xs={12}>
        <FloatingLabel
          label="Opportunity Score"
          disabled={!isEditable}
          value={primaryFilters.opportunityScore}
        >
          <DropdownWithCheckbox
            disabled={!isEditable}
            className={
              !isEditable
                ? primaryFilters.opportunityScore
                  ? "readonly dropdown-filters"
                  : "disabled dropdown-filters"
                : "dropdown-filters"
            }
            treeData={TreeData}
            value={opportunityScoreState}
            handleOnChange={handleOpportunityScore}
          />
        </FloatingLabel>
      </Col>
      <Col xl={6} lg={6} sm={8} xs={12}>
        <FloatingLabel
          label="Risk Score"
          disabled={!isEditable}
          value={primaryFilters.riskScore}
        >
          <DropdownWithCheckbox
            disabled={!isEditable}
            className={
              !isEditable
                ? primaryFilters.riskScore
                  ? "readonly dropdown-filters"
                  : "disabled dropdown-filters"
                : "dropdown-filters"
            }
            treeData={TreeData}
            value={riskScoreState}
            handleOnChange={handleRiskScoreChange}
          />
        </FloatingLabel>
      </Col>
    </Row>
  );
}

export default observer(PrimaryFilterFields);
