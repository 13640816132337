import React from "react";

function CogsIcon() {
  return (
    <svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13.2487C11.7419 13.2487 12.7487 12.2419 12.7487 11C12.7487 9.75802 11.7419 8.75122 10.5 8.75122C9.25803 8.75122 8.25122 9.75802 8.25122 11C8.25122 12.2419 9.25803 13.2487 10.5 13.2487Z"
        stroke="#4A5C75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9548 7.73625L17.0536 6.17875C16.6773 5.53125 15.8549 5.3125 15.2074 5.68875L14.7611 5.9425C13.8598 6.45875 12.7398 5.81125 12.7398 4.77V4.25375C12.7398 3.51 12.1361 2.90625 11.3923 2.90625H9.58984C8.84609 2.90625 8.24236 3.51 8.24236 4.25375V4.77C8.24236 5.81125 7.12234 6.45875 6.22109 5.9425L5.77485 5.68875C5.12735 5.3125 4.30484 5.54 3.92859 6.17875L3.02734 7.73625C2.65109 8.38375 2.87859 9.20625 3.51734 9.5825L3.9636 9.83625C4.86485 10.3525 4.86485 11.6562 3.9636 12.1725L3.51734 12.4262C2.86984 12.8025 2.65109 13.625 3.02734 14.2725L3.92859 15.83C4.30484 16.4775 5.12735 16.6963 5.77485 16.32L6.22109 16.0662C7.12234 15.55 8.24236 16.1975 8.24236 17.2387V17.755C8.24236 18.4988 8.84609 19.1025 9.58984 19.1025H11.3923C12.1361 19.1025 12.7398 18.4988 12.7398 17.755V17.2387C12.7398 16.1975 13.8598 15.55 14.7611 16.0662L15.2074 16.32C15.8549 16.6963 16.6773 16.4687 17.0536 15.83L17.9548 14.2725C18.3311 13.625 18.1036 12.8025 17.4648 12.4262L17.0186 12.1725C16.1174 11.6562 16.1174 10.3525 17.0186 9.83625L17.4648 9.5825C18.1123 9.20625 18.3311 8.375 17.9548 7.73625Z"
        stroke="#4A5C75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CogsIcon;
