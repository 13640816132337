type PropsType = {
  color?: string;
};

function CareMgmtIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.37458 11.0004C3.48703 11.0004 1.16211 8.67552 1.16211 5.78797C1.16211 5.48794 1.42473 5.22559 1.7245 5.22559C2.02453 5.22559 2.28689 5.48821 2.28689 5.78797C2.28689 8.0381 4.12444 9.87538 6.3743 9.87538C8.62442 9.87538 10.4617 8.03783 10.4617 5.78797C10.4617 5.48794 10.7243 5.22559 11.0241 5.22559C11.3241 5.22559 11.5865 5.48821 11.5865 5.78797C11.587 8.63809 9.26213 11.0004 6.37458 11.0004Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M10.4621 18.4998C7.91224 18.4998 5.81226 16.3999 5.81226 13.85C5.81226 13.55 6.07488 13.2876 6.37465 13.2876C6.67468 13.2876 6.93704 13.5502 6.93704 13.85C6.93704 15.8 8.51203 17.375 10.4621 17.375C12.4121 17.375 13.9871 15.8 13.9871 13.85C13.9871 13.55 14.2498 13.2876 14.5495 13.2876C14.8495 13.2876 15.1119 13.5502 15.1119 13.85C15.1122 16.3998 13.0496 18.4998 10.4621 18.4998Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M14.5872 13.9248C13.1247 13.9248 11.9246 12.7247 11.9246 11.2622C11.9246 9.79974 13.1247 8.59961 14.5872 8.59961C16.0497 8.59961 17.2498 9.79974 17.2498 11.2622C17.2495 12.725 16.0497 13.9248 14.5872 13.9248ZM14.5872 9.72494C13.7622 9.72494 13.0496 10.3998 13.0496 11.2625C13.0496 12.1249 13.7245 12.8001 14.5872 12.8001C15.4496 12.8001 16.1247 12.1252 16.1247 11.2625C16.1247 10.4001 15.4122 9.72494 14.5872 9.72494Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M1.7245 6.34989C1.42447 6.34989 1.16211 6.08726 1.16211 5.7875V1.06239C1.16211 0.762358 1.42473 0.5 1.7245 0.5C2.02453 0.5 2.28689 0.762626 2.28689 1.06239V5.78729C2.28715 6.08733 2.02452 6.34989 1.7245 6.34989Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M11.0246 6.34989C10.7245 6.34989 10.4622 6.08726 10.4622 5.7875L10.4619 1.06239C10.4619 0.762358 10.7245 0.5 11.0243 0.5C11.3243 0.5 11.5867 0.762626 11.5867 1.06239V5.78729C11.587 6.08733 11.3246 6.34989 11.0246 6.34989Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M6.37465 15.0507C6.07461 15.0507 5.81226 14.7881 5.81226 14.4883V10.4384C5.81226 10.1383 6.07488 9.87598 6.37465 9.87598C6.67468 9.87598 6.93704 10.1386 6.93704 10.4384V14.4883C6.93704 14.7884 6.67467 15.0507 6.37465 15.0507Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M3.59981 1.62478H1.68739C1.38736 1.62478 1.125 1.36215 1.125 1.06239C1.125 0.762358 1.38763 0.5 1.68739 0.5H3.59981C3.89984 0.5 4.1622 0.762626 4.1622 1.06239C4.16246 1.36242 3.9375 1.62478 3.59981 1.62478Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M11.0249 1.62478H9.11244C8.81241 1.62478 8.55005 1.36215 8.55005 1.06239C8.54979 0.762358 8.81215 0.5 9.14984 0.5H11.0623C11.3623 0.5 11.6247 0.762626 11.6247 1.06239C11.6247 1.36242 11.3623 1.62478 11.0249 1.62478Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default CareMgmtIcon;
