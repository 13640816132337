import React from "react";

type PropType = {
  style: React.CSSProperties;
};

function TickIcon({ style = {} }: PropType) {
  return (
    <svg
      width={46}
      height={36}
      viewBox={`0 0 ${46} ${36}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1535 4.04511L19.9325 35.5802L0.120117 19.0679L3.8583 14.5821L19.0914 27.2744L40.5976 0.400391L45.1535 4.04511Z"
        fill="white"
      />
    </svg>
  );
}

export default TickIcon;
