import classNames from "classnames";
import { Modal } from "antd";
import { emToPxValue } from "../util/css";
import "./modal.scss";

export enum ModelSize {
  Small = "60em",
  Medium = "80em",
  Large = "105em",
  Full = "120em"
}

const modal = ({
  title,
  visible,
  okText,
  cancelText,
  style,
  centered,
  size = ModelSize.Large,
  className,
  onOk,
  onCancel,
  footer,
  okButtonProps,
  cancelButtonProps,
  closable,
  maskClosable,
  closeIcon,
  zIndex,
  bodyStyle,
  confirmLoading,
  children,
  initialWidth
}: any) => {
  let modalInitialWidth = initialWidth;
  let modalInitialStyle = style;
  let modalMaskClosable = maskClosable;

  modalInitialWidth = initialWidth || size;
  modalInitialWidth = emToPxValue(initialWidth);
  modalInitialStyle = style || {};
  modalInitialStyle = {
    top: 60,
    ...style
  };

  modalMaskClosable = maskClosable === undefined ? false : maskClosable;
  return (
    <div>
      {visible && (
        <Modal
          wrapClassName="custom-modal"
          title={title}
          open={visible}
          onOk={onOk}
          onCancel={onCancel}
          centered={centered}
          okText={okText}
          cancelText={cancelText}
          okButtonProps={okButtonProps}
          cancelButtonProps={cancelButtonProps}
          closable={closable}
          closeIcon={closeIcon}
          width={modalInitialWidth}
          zIndex={zIndex}
          bodyStyle={bodyStyle}
          confirmLoading={confirmLoading}
          footer={footer}
          className={classNames(className, size)}
          maskClosable={modalMaskClosable}
          style={modalInitialStyle}
        >
          {children}
        </Modal>
      )}
    </div>
  );
};

export { modal as Modal };

export const { info, error, warn } = Modal;
