import { Input, TreeSelect } from "antd";
import { CSSProperties, useEffect, useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import {
  DropdownCloseIcon,
  DropdownOpenIcon,
  DropdownSearchIcon
} from "../../../assets/images/svg";

type SelectOptionsType = {
  type: string;
  key?: string | undefined;
  placeholder: string;
  options?:
    | {
        key: number;
        title: string;
        value: string;
      }[]
    | undefined;
};

type PropsType = {
  data: SelectOptionsType;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  value?: string | string[] | any;
  key?: string | undefined;
  popupClassName?: string;
  className?: string;
  disabled?: boolean;
  showSearch: boolean;
  setSearch: Function;
  search: string;
};

type TreeDataType = {
  key: number;
  title: string;
  value: string;
};

function DropdownSelect(props: PropsType) {
  const {
    data,
    key,
    style,
    onChange,
    value,
    popupClassName,
    className,
    disabled,
    showSearch,
    setSearch,
    search
  } = props;
  const [iconToggle, setIconToggle] = useState<boolean>(false);
  const [optionsValue] = useState<TreeDataType[] | undefined>(data.options);

  const prevValue: any = usePrevious(value);
  const [filteredOptions, setFilteredOptions] = useState<TreeDataType[]>();

  useEffect(() => {
    if (search) {
      const storeValue = optionsValue ? [...optionsValue] : [];
      const storeFilteredOptions = storeValue.filter((item) => {
        if (item.title.toLowerCase().includes(search.toLowerCase())) {
          return item;
        }
        return null;
      });

      setFilteredOptions(storeFilteredOptions);
    } else {
      setFilteredOptions(optionsValue);
    }
  }, [search]);

  useEffect(() => {
    setFilteredOptions(optionsValue?.filter(Boolean));
  }, [optionsValue]);

  const dropdownRender = (menu: any) => {
    return (
      <>
        {data.key !== "ageBand" && data.key !== "careTeamSize" && (
          <div style={{ margin: "0rem .4rem" }}>
            <Input
              style={{ marginBottom: 5 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              suffix={<DropdownSearchIcon />}
              placeholder="Search"
              size="small"
            />
          </div>
        )}
        {menu}
      </>
    );
  };

  const updatePrevValue = () => {
    const tempValue: any = prevValue ? [...prevValue] : [];
    if (onChange) {
      onChange(tempValue);
    }
  };

  return (
    <TreeSelect
      popupClassName={popupClassName}
      className={className}
      value={value}
      key={key}
      disabled={disabled}
      suffixIcon={iconToggle ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
      showArrow={!disabled}
      showSearch={showSearch}
      treeData={filteredOptions}
      multiple
      treeCheckable
      allowClear
      style={style}
      maxTagCount={1}
      maxTagTextLength={11}
      onChange={onChange}
      onDropdownVisibleChange={() => setIconToggle(!iconToggle)}
      dropdownRender={dropdownRender}
      onKeyUp={(e) => {
        if (e.keyCode === 8) {
          updatePrevValue();
        }
      }}
    />
  );
}

export default DropdownSelect;
