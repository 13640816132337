import React from "react";

function DeleteIcon() {
  return (
    <svg
      width={14}
      height={15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4768 3.90039H3.31934V11.6937C3.31934 12.3529 3.856 12.8896 4.51517 12.8896H9.27516C9.93433 12.8896 10.471 12.3529 10.471 11.6937V3.90039H10.4768Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.21094 3.90039H11.7893"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79646 2.09863H5.20312V3.8953H8.79646V2.09863Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.79834 6.1875V10.4983"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1958 6.1875V10.4983"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
