import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import FloatingLabel from "../../components/shared/floating-label";
import {
  Button,
  ButtonHTMLType,
  ButtonType
} from "../../components/shared/button";
import { UserData, UserListRequest } from "../../models/admin.model";
import { useStore } from "../../stores/helpers/use-store";
import { ToastNotification } from "../../components/shared/notification";
import { SuccessErrorMessages } from "../../constant/messages/login";

type PropType = {
  getUsersListReq: UserListRequest;
  setGetUsersListReq: React.Dispatch<React.SetStateAction<UserListRequest>>;
};

function NewUser(props: PropType) {
  const { getUsersListReq, setGetUsersListReq } = props;
  const [userData, setUserData] = useState<UserData>({
    name: "",
    email: "",
    phoneNumber: ""
  });
  const [form] = Form.useForm();
  const { adminStore } = useStore();

  /**
   * Input field onchange function call
   * @param event
   */
  const onChangeValue = (event: any) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  // Reset form value
  const onFormReset = () => {
    setUserData({
      name: "",
      email: "",
      phoneNumber: ""
    });
    form.resetFields();
  };

  /**
   * Click on send link button
   * Check the value is non empty
   * Success api response to show success toast message
   * Error api response to show Error toast message
   */
  const onFormSubmit = async () => {
    if (
      (userData.name !== "" && userData.email !== "") ||
      userData.phoneNumber !== ""
    ) {
      const signUpData: any = await adminStore.getSignUpInvite(userData);
      ToastNotification(
        signUpData?.data?.data?.success ? "success" : "error",
        signUpData?.data?.data?.success ? "Link sent" : "Link sent failed",
        signUpData?.error?.error?.details?.message ||
          signUpData?.error?.error?.message ||
          signUpData?.data?.data?.message
      );

      onFormReset();
      setGetUsersListReq({ ...getUsersListReq, pageIndex: 0 });
    }
  };

  /**
   * Set value on userData state
   * @param event
   * @param name
   */
  const onClickValue = (event: string, name: string) => {
    setUserData({ ...userData, [name]: event });
  };

  /**
   * Take string parameter
   * Return true when criteria match
   * Return false when criteria not match
   * @param value
   * @returns boolean
   */
  const validateName = (value: string) => {
    const passwordRegEx = /^[A-Za-z ]+$/.test(value);
    if (passwordRegEx) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Row className="user-form-header">
        <Col className="user-form-header-text">
          Enter User Details and Send Sign Up Link
        </Col>
      </Row>
      <Form
        data-testid="btn-send-link"
        onFinish={onFormSubmit}
        autoComplete="off"
        form={form}
      >
        <Row justify="space-between">
          <Col style={{ display: "flex" }}>
            <FloatingLabel
              label="Enter Name"
              value={userData.name}
              className="label-position"
            >
              <Form.Item
                label={<span />}
                className="input-box"
                name="name"
                rules={[
                  { required: true, message: "Please enter your name!" },
                  {
                    validator: (_, value) => {
                      const result = validateName(value);
                      if (!value || result === true) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        SuccessErrorMessages.NameValidation
                      );
                    }
                  }
                ]}
              >
                <Input
                  data-testid="name"
                  className="form-input"
                  name="name"
                  type="text"
                  onChange={onChangeValue}
                  onClick={() => onClickValue("name", "name")}
                />
              </Form.Item>
            </FloatingLabel>
            <FloatingLabel
              label="Enter Work Email ID"
              value={userData.email}
              className="label-position"
            >
              <Form.Item
                label={<span />}
                className="input-box"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!"
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]}
              >
                <Input
                  data-testid="email"
                  className="form-input"
                  name="email"
                  type="email"
                  onChange={onChangeValue}
                  onClick={() => onClickValue("email", "email")}
                />
              </Form.Item>
            </FloatingLabel>
            <FloatingLabel
              label="Enter Phone No."
              value={userData.phoneNumber}
              className="label-position"
            >
              <Form.Item
                rules={[
                  {
                    required: false,
                    validator: (_, value) => {
                      if (
                        (/^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(value) &&
                          value.length === 10) ||
                        value === "" ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        SuccessErrorMessages.PhoneNumberValidation
                      );
                    }
                  }
                ]}
                name="phoneNumber"
                className="input-box-number"
              >
                <Input
                  data-testid="phoneNumber"
                  className="form-input-number"
                  name="phoneNumber"
                  onChange={onChangeValue}
                  onClick={() => onClickValue("phoneNumber", "phoneNumber")}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col style={{ display: "flex" }}>
            <Form.Item>
              <Button className="send-btn" htmlType={ButtonHTMLType.Submit}>
                Send Link
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType={ButtonHTMLType.Reset}
                type={ButtonType.Primary}
                className="reset-btn "
                onClick={onFormReset}
              >
                <span data-testid="btn-reset">Reset</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default observer(NewUser);
