import { Avatar as AntdAvatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import "./avatar.scss";

export function Avatar({
  name,
  url,
  size,
  userRole
}: {
  name: string;
  url?: string;
  size?: AvatarSize;
  userRole?: string;
}) {
  return (
    <div className="avatar">
      <AntdAvatar
        size={size || "default"}
        icon={<UserOutlined />}
        src={url || ""}
        style={{
          backgroundColor: "#5B4E98",
          textTransform: "capitalize"
        }}
        alt={name}
      >
        {name}
      </AntdAvatar>
      <span className="hide-for-small-screen" style={{ marginLeft: "0.5em" }}>
        <span style={{ display: "block", textTransform: "capitalize" }}>
          {name}
        </span>
        {userRole && <span style={{ display: "block" }}>{userRole}</span>}
      </span>
    </div>
  );
}
