import { AdminStore } from "./admin.store";
import { AuthStore } from "./auth.store";
import { LoginStore } from "./login.store";
import { MemberPatientListStore } from "./memberPatientList.store";
import { SignupStore } from "./signup.store";
import { TestStore } from "./test.store";

export type RootStoreType = {
  loginStore: LoginStore;
};

export default class RootStore {
  authStore: AuthStore;

  memberPatientListStore: MemberPatientListStore;

  loginStore: LoginStore;

  signupStore: SignupStore;

  adminStore: AdminStore;

  testStore: TestStore;

  constructor() {
    this.authStore = new AuthStore();
    this.memberPatientListStore = new MemberPatientListStore();
    this.loginStore = new LoginStore();
    this.signupStore = new SignupStore();
    this.adminStore = new AdminStore();
    this.testStore = new TestStore();
  }
}
