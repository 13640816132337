import React from "react";

function DateIcon() {
  return (
    <svg
      width={14}
      height={15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 4.16667V1.5M9.66667 4.16667V1.5M3.66667 6.83333H10.3333M2.33333 13.5H11.6667C12.403 13.5 13 12.903 13 12.1667V4.16667C13 3.43029 12.403 2.83333 11.6667 2.83333H2.33333C1.59695 2.83333 1 3.43029 1 4.16667V12.1667C1 12.903 1.59695 13.5 2.33333 13.5Z"
        stroke="#5C5F62"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DateIcon;
