import { makeAutoObservable, observable } from "mobx";
import {
  SignUpLinkReq,
  SignUpResendLinkReq,
  UserListRequest
} from "../models/admin.model";
import { AdminService } from "../services";

export class AdminStore {
  @observable
  signUpInvite = new AdminService();

  signUpResponse: any = undefined;

  signUpResendResponse: any = undefined;

  userList: any = [];

  totalUsers: number = 0;

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getSignUpInvite = async (request: SignUpLinkReq) => {
    this.loading = true;
    const response = await this.signUpInvite.getSignUpInvite(request);
    this.loading = false;
    if (response.remote === "success") {
      this.signUpResponse = response.data.data;
      return response;
    }
    this.signUpResponse = response.error;
    return response;
  };

  getSignUpResendInvite = async (request: SignUpResendLinkReq) => {
    this.loading = true;
    const response = await this.signUpInvite.getSignUpResendInvite(request);
    this.loading = false;
    if (response.remote === "success") {
      this.signUpResendResponse = response.data.data;
      return response;
    }
    if (response.remote === "error") {
      this.signUpResendResponse = response.error;
      return response;
    }
    return this.signUpResponse;
  };

  getUserList = async (request: UserListRequest) => {
    this.loading = true;
    const response = await this.signUpInvite.getUserList(request);
    this.loading = false;
    if (response.remote === "success") {
      this.userList = response.data.data;
      if (response.data.meta.count) {
        this.totalUsers = response.data.meta.count;
      } else {
        this.totalUsers = 0;
      }
      return response;
    }
    return response;
  };
}
