import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { globalDateFormat } from "../../constant";

dayjs.extend(utc);

export const distinct = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) === index;
};

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const getDateMonth = (date: Date): string => {
  if (!date) {
    return "";
  }
  return monthNames[date.getMonth()];
};

export const dayjsTypeDate = (date: any) => {
  if (date) {
    return dayjs.utc(date.substring(0, 23)).add(1, "day");
  }
  return undefined;
};

export const stringTypedate = (
  date: any,
  dateFormat: string = globalDateFormat
) => {
  return dayjs(date).format(dateFormat);
};

export const getDateTimeFormat = (value: any) => {
  return dayjs(value).format("MM/DD/YYYY  - HH:mm A");
};
