type PropsType = {
  color?: string;
};
function PatientListIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19403 6.52943C2.19403 7.55301 3.02689 8.38587 4.05061 8.38587C5.07419 8.38587 5.90705 7.55301 5.90705 6.52943C5.90705 5.50557 5.07419 4.67285 4.05061 4.67285C3.02675 4.67285 2.19403 5.50557 2.19403 6.52943ZM5.50712 6.52943C5.50712 7.33258 4.85379 7.9859 4.05065 7.9859C3.2475 7.9859 2.59403 7.33258 2.59403 6.52943C2.59403 5.72628 3.2475 5.07282 4.05065 5.07282C4.85379 5.07282 5.50712 5.72628 5.50712 6.52943Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M15.8149 6.52943C15.8149 5.50557 14.982 4.67285 13.9583 4.67285C12.9347 4.67285 12.1018 5.50571 12.1018 6.52943C12.1018 7.55301 12.9347 8.38587 13.9583 8.38587C14.9821 8.38587 15.8149 7.55301 15.8149 6.52943ZM12.5018 6.52943C12.5018 5.72628 13.1551 5.07282 13.9582 5.07282C14.7614 5.07282 15.4149 5.72628 15.4149 6.52943C15.4149 7.33258 14.7614 7.9859 13.9582 7.9859C13.1551 7.9859 12.5018 7.33258 12.5018 6.52943Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M1.0001 12.4692C1.0001 12.996 1.37985 13.4358 1.88014 13.5297V16.4664C1.88014 16.5769 1.96956 16.6665 2.08019 16.6665H6.00378C6.11428 16.6665 6.20384 16.577 6.20384 16.4664L6.2037 13.5297C6.70413 13.4358 7.08374 12.996 7.08374 12.4692V10.5248C7.08374 9.89788 6.57313 9.3877 5.94563 9.3877H2.13811C1.51061 9.3877 1 9.89788 1 10.5248L1.0001 12.4692ZM1.40006 10.5248C1.40006 10.1184 1.73112 9.78766 2.13821 9.78766H5.94573C6.3528 9.78766 6.68387 10.1183 6.68387 10.5248V12.4692C6.68387 12.8436 6.37876 13.1483 6.00376 13.1483C5.89327 13.1483 5.8037 13.2377 5.8037 13.3484V16.2665H2.28012V13.3482C2.28012 13.2377 2.19069 13.1482 2.08006 13.1482C1.70506 13.1482 1.39995 12.8435 1.39995 12.469L1.40006 10.5248Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M12.4941 16.6667H15.4224C15.5329 16.6667 15.6225 16.5772 15.6225 16.4666V15.0008H16.8001C16.8591 15.0008 16.9152 14.9748 16.953 14.9295C16.9911 14.8844 17.0073 14.8249 16.9971 14.7665L16.224 10.3026C16.1497 9.78098 15.6961 9.3877 15.1688 9.3877L12.7476 9.38783C12.2202 9.38783 11.7667 9.78098 11.6933 10.2966L10.9192 14.7666C10.909 14.8248 10.9252 14.8844 10.9632 14.9296C11.0012 14.9749 11.0571 15.0008 11.1161 15.0008H12.2939L12.294 16.4665C12.294 16.5772 12.3835 16.6666 12.4941 16.6666L12.4941 16.6667ZM11.354 14.6008L12.0884 10.359C12.1347 10.0334 12.4181 9.78785 12.7476 9.78785H15.1689C15.4984 9.78785 15.7818 10.0334 15.8291 10.365L16.5625 14.6007H15.4224C15.3119 14.6007 15.2223 14.6902 15.2223 14.8008V16.2666H12.694V14.8008C12.694 14.6903 12.6046 14.6007 12.4939 14.6007L11.354 14.6008Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M7.95376 6.07762H10.0464C10.1569 6.07762 10.2465 5.9882 10.2465 5.87757V4.6182H11.5058C11.6163 4.6182 11.7059 4.52878 11.7059 4.41815L11.7057 2.32535C11.7057 2.21486 11.6163 2.12529 11.5057 2.12529H10.2463V0.866074C10.2463 0.75558 10.1569 0.666016 10.0463 0.666016H7.95376C7.84327 0.666016 7.7537 0.755441 7.7537 0.866074V2.12544H6.49434C6.38385 2.12544 6.29428 2.21486 6.29428 2.32549V4.41829C6.29428 4.52878 6.38371 4.61835 6.49434 4.61835H7.7537V5.87771C7.75384 5.9882 7.84326 6.07762 7.95376 6.07762ZM6.69439 4.21826V2.52547H7.95376C8.06425 2.52547 8.15381 2.43604 8.15381 2.32541V1.06605H9.84636V2.32541C9.84636 2.4359 9.93578 2.52547 10.0464 2.52547H11.3058V4.21826H10.0464C9.93592 4.21826 9.84636 4.30768 9.84636 4.41832V5.67768H8.15381V4.41832C8.15381 4.30782 8.06439 4.21826 7.95376 4.21826H6.69439Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default PatientListIcon;
