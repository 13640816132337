import { Row, Col, Layout, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { CogsIcon, YuvoIconWithLabel } from "../../../assets/images/svg";
import { Avatar } from "../avatar";
import "./DashboardHeader.scss";
import { GlobalSearchBar } from "../searchbar";
import { useStore } from "../../../stores/helpers/use-store";
import { NotificationModal } from "../modal/notification-modal/NotificationModal";

const { Header } = Layout;
const { Link } = Typography;

function DashboardHeader() {
  const { authStore } = useStore();
  const handleSearchChange = () => {};
  const handleClick = () => {};

  return (
    <Header className="page-header">
      <Row>
        <Col flex={1} className="logo-div-width">
          <div className="logo-container">
            <YuvoIconWithLabel height={39} width={100} />
          </div>
        </Col>
        <Col flex={4} className="logo-div-width" style={{ textAlign: "right" }}>
          <ul className="navbar-menu-icons">
            <li>
              <GlobalSearchBar
                onChangeEvent={handleSearchChange}
                onClickEvent={handleClick}
              />
            </li>
            <li>
              <Link className="bell-icon">
                <NotificationModal />
              </Link>
            </li>
            <li className="cogs-icon">
              <CogsIcon />
            </li>
          </ul>

          <div
            style={{
              display: "inline-flex"
            }}
          >
            <Avatar
              size="default"
              name={
                authStore.getCurrentUser ? authStore.getCurrentUser.name : ""
              }
              url=""
            />
          </div>
        </Col>
      </Row>
    </Header>
  );
}

export default observer(DashboardHeader);
