import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import lodash from "lodash";
import { Col, Row, Space, Typography, Tooltip } from "antd";
import { YuvoTable } from "../../../components/shared/table";
import "./RiskAdjustment.scss";
import ColumnFilters from "./ColumnFilters";
import PrimaryFilters from "./PrimaryFilters";
import AdvanceFilter from "./AdvanceFilter";
import {
  Member,
  TableRecord,
  FiltersType,
  SelectOptionsType,
  PrimaryFilterType,
  FiltersKeyType,
  PatientsListReq,
  ColType
} from "../../../models/memberPatientList.model";
import { PageSize, RiskAdjustmentPageIndex } from "../../../constant";
import { SearchBarWithIcon } from "../../../components/shared/searchbar-with-icon";
import { useStore } from "../../../stores/helpers/use-store";
import MyFavorite from "./MyFavorite";
import { ToastNotification } from "../../../components/shared/notification";
import SelectedFilters from "./SelectedFilters";
import PrimaryFiltersIcon from "../../../assets/images/svg/PrimaryFiltersIcon";
import { StatusDotIcon } from "../../../assets/images/svg";
import { StatusType } from "../../../assets/images/svg/StatusDotIcon";
import { stringTypedate } from "../../../library/util/js-helper";

const { Link } = Typography;

function RiskAdjustment() {
  const { memberPatientListStore } = useStore();

  const [filters, setFilters] = useState<FiltersType>({
    groupName: [],
    groupNpi: [],
    fqhc: [],
    site: [],
    provider: [],
    providerType: [],
    healthSegment: [],
    careManagement: [],
    ageBand: [],
    lastDateOfVisit: "",
    lastDateOfService: "",
    careTeamSize: [],
    careGap: []
  });

  const [selectedFilters, setSelectedFilters] = useState<Partial<FiltersType>>(
    {}
  );
  const [filterPayload, setFilterPayload] = useState<any>();

  const [primaryFilters, setPrimaryFilters] = useState<PrimaryFilterType>({});
  const [advanceFilterList, setAdvanceFilterList] = useState<
    SelectOptionsType[]
  >([]);
  const [dataSource, setDataSource] = useState<TableRecord[]>([]);
  const [getPatientListReq, setGetPatientListReq] = useState<PatientsListReq>({
    pageIndex: RiskAdjustmentPageIndex[0],
    pageSize: PageSize[0]
  });

  const [isLock, setIsLock] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<ColType[]>([]);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const [backColor, setBackColor] = useState({
    empi: "#ffffff",
    firstName: "#ffffff",
    lastName: "#ffffff",
    birthDate: "#ffffff",
    gender: "#ffffff",
    email: "#ffffff",
    zipCode: "#ffffff",
    phoneNumber: "#ffffff",
    icdCode: "#ffffff",
    memberCondition: "#ffffff",
    groupName: "#ffffff",
    groupTin: "#ffffff",
    groupNpi: "#ffffff",
    renderingPcp: "#ffffff",
    fqhcName: "#ffffff",
    siteName: "#ffffff",
    pcpName: "#ffffff",
    providerType: "#ffffff",
    providerNpi: "#ffffff",
    healthSegment: "#ffffff",
    ageBand: "#ffffff",
    riskScore: "#ffffff",
    careGap: "#ffffff",
    cmFlag: "#ffffff",
    lastPcpVisit: "#ffffff",
    lastServiceVisit: "#ffffff",
    opportunityScore: "#ffffff",
    caregapOpportunity: "#ffffff",
    careRiskOpportunity: "#ffffff"
  });

  const columnsSource: ColumnsType<TableRecord> = [
    {
      title: "Patient Name",
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
      key: "patientName",
      width: 170,
      dataIndex: "firstName",
      render(value, record) {
        return (
          <>
            <span style={{ display: "block", fontWeight: 500 }}>
              {record.firstName} {record.lastName}
            </span>
            <span>{record.phoneNumber}</span>
          </>
        );
      }
    },
    {
      title: "Yuvo EMPI",
      dataIndex: "empi",
      key: "empi",
      align: "left",
      width: 120,
      render(value) {
        return {
          props: {
            style: { background: backColor.empi }
          },
          children: (
            <NavLink
              to="#"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              {value}
            </NavLink>
          )
        };
      }
    },
    {
      title: "DOB",
      dataIndex: "birthDate",
      key: "birthDate",
      align: "left",
      width: 150,
      sorter: (a: any, b: any) => a.birthDate.localeCompare(b.birthDate),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.birthDate }
          },
          children: <p>{stringTypedate(value)}</p>
        };
      }
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.gender.localeCompare(b.gender),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.gender }
          },
          children: value
        };
      }
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      align: "left",
      width: 200,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.email }
          },
          children: value
        };
      }
    },
    {
      title: "Zip Code",
      dataIndex: "zipCode",
      key: "zipCode",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.zipCode - b.zipCode,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.zipCode }
          },
          children: value
        };
      }
    },
    {
      title: "ICD Codes",
      dataIndex: "icdCode",
      key: "icdCode",
      align: "left",
      width: 120,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.icdCode }
          },
          children: value
        };
      }
    },
    {
      title: "Member Conditions",
      dataIndex: "memberCondition",
      key: "memberCondition",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.memberCondition }
          },
          children: value
        };
      }
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.groupName }
          },
          children: value
        };
      }
    },
    {
      title: "Group TIN",
      dataIndex: "groupTin",
      key: "groupTin",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.groupTin.localeCompare(b.groupTin),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.groupTin }
          },
          children: value
        };
      }
    },
    {
      title: "Group NPI",
      dataIndex: "groupNpi",
      key: "groupNpi",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.groupNpi.localeCompare(b.groupNpi),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.groupNpi }
          },
          children: value
        };
      }
    },
    {
      title: "FQHC",
      dataIndex: "fqhcName",
      key: "fqhcName",
      align: "left",
      width: 200,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.fqhcName }
          },
          children: value
        };
      }
    },
    {
      title: "FQ Site",
      dataIndex: "siteName",
      key: "siteName",
      align: "left",
      width: 120,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.siteName }
          },
          children: value
        };
      }
    },
    {
      title: "PCP Name",
      dataIndex: "pcpName",
      key: "pcpName",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.pcpName }
          },
          children: value
        };
      }
    },
    {
      title: "Provider Type",
      dataIndex: "providerType",
      key: "providerType",
      align: "left",
      width: 130,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.providerType }
          },
          children: value
        };
      }
    },
    {
      title: "PCP NPI",
      dataIndex: "providerNpi",
      key: "providerNpi",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.providerNpi }
          },
          children: value
        };
      }
    },
    {
      title: "Health Segment",
      dataIndex: "healthSegment",
      key: "healthSegment",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.healthSegment }
          },
          children: value
        };
      }
    },
    {
      title: "Age Band",
      dataIndex: "ageBand",
      key: "ageBand",
      align: "left",
      width: 100,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.ageBand }
          },
          children: value
        };
      }
    },
    {
      title: "Risk Score",
      dataIndex: "riskScore",
      key: "riskScore",
      align: "left",
      width: 120,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.riskScore }
          },
          children: (
            <span className={`status-tags status-tags-${value}`}>
              <StatusDotIcon
                color={StatusType[value as keyof typeof StatusType]}
              />
              <span style={{ marginLeft: "10px" }}>{value}</span>
            </span>
          )
        };
      }
    },
    {
      title: "Care Gap",
      dataIndex: "careGap",
      key: "careGap",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.careGap.localeCompare(b.careGap),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.careGap }
          },
          children: value
        };
      }
    },
    {
      title: "CM Flag",
      dataIndex: "cmFlag",
      key: "cmFlag",
      align: "left",
      width: 120,
      sorter: (a: any, b: any) => a.cmFlag.localeCompare(b.cmFlag),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.cmFlag }
          },
          children: value
        };
      }
    },
    {
      title: "Last PCP Visit",
      dataIndex: "lastPcpVisit",
      key: "lastPcpVisit",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.lastPcpVisit }
          },
          children: <p>{stringTypedate(value)}</p>
        };
      }
    },
    {
      title: "Last Service Visit",
      dataIndex: "lastServiceVisit",
      key: "lastServiceVisit",
      align: "left",
      width: 150,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.lastServiceVisit }
          },
          children: <p>{stringTypedate(value)}</p>
        };
      }
    },
    {
      title: "Opportunity Score",
      dataIndex: "opportunityScore",
      key: "opportunityScore",
      align: "left",
      width: 170,
      sorter: (a: any, b: any) =>
        a.opportunityScore.localeCompare(b.opportunityScore),
      render: (value) => {
        return {
          props: {
            style: { background: backColor.opportunityScore }
          },
          children: value
        };
      }
    },
    {
      title: "Care Gap Opportunity",
      dataIndex: "caregapOpportunity",
      key: "caregapOpportunity",
      align: "left",
      width: 170,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.caregapOpportunity }
          },
          children: value
        };
      }
    },
    {
      title: "Care Risk Opportunity",
      dataIndex: "careRiskOpportunity",
      key: "careRiskOpportunity",
      align: "left",
      width: 170,
      render: (value) => {
        return {
          props: {
            style: { background: backColor.careRiskOpportunity }
          },
          children: value
        };
      }
    }
  ];

  const [columns, setColumns] = useState<any>(columnsSource);
  const [searchText, setSearchText] = useState<string>("");
  const [debounceSearch] = useDebounce(searchText, 1000);
  const [togglePrimaryFilters, setTogglePrimaryFilters] = useState(false);

  const [PrimIconFill, setPrimIconFill] = useState("white");
  const [PrimIconStroke, setPrimIconStroke] = useState("#262043");

  // It will check Standard filter toggle and icon color.
  useEffect(() => {
    if (togglePrimaryFilters) {
      setPrimIconFill("#DADF3F");
      setPrimIconStroke("white");
    } else {
      setPrimIconFill("white");
      setPrimIconStroke("#262043");
    }
  }, [togglePrimaryFilters]);

  // It will get called Patient Name column lock or not.
  useEffect(() => {
    if (columnsSource?.length > 0) {
      const temp: any = columnsSource.filter((col: any) => {
        return columns.find((sCol: any) => sCol.key === col.key);
      });
      temp.forEach((col: any, index: number) => {
        if (isLock && col.key === "patientName") {
          temp[index].fixed = "left";
        }
      });
      setColumns([...temp]);
    }
  }, [backColor, isLock]);

  // This will get called for the pagination
  const onChange = (pageSize: number, pageIndex: number) => {
    setGetPatientListReq({ ...getPatientListReq, pageIndex, pageSize });
  };

  const onSelectRowSelection = () => {};

  const onChangeRowSelection = () => {};

  // It will get called when getPatientListReq state value change.
  useEffect(() => {
    memberPatientListStore.getMemberPatientList(getPatientListReq);
  }, [getPatientListReq]);

  useEffect(() => {
    const memberList: TableRecord[] = memberPatientListStore.memberList.map(
      (data: Member) => {
        return {
          ...data,
          key: data.empi
        };
      }
    );
    setDataSource(memberList);
  }, [memberPatientListStore.memberList]);

  const getFilterListData = async () => {
    await memberPatientListStore.getFilterList();
  };

  /**
   * saveFavorite will get called on click of Add to My Favorite button.
   * It will call saveMyFavorite store and get the response.
   */
  const saveFavorite = async () => {
    let primaryFilter;
    let secondaryFilter;
    if (Object.keys(filterPayload.primaryFilter).length !== 0) {
      primaryFilter = filterPayload.primaryFilter;
    }
    if (Object.keys(filterPayload.secondaryFilter).length !== 0) {
      secondaryFilter = filterPayload.secondaryFilter;
    }
    const manageColumn = columns.map((i: any) => i.key);

    const myFavorite = {
      filter: {
        primaryFilter,
        secondaryFilter,
        manageColumn
      }
    };

    let response;
    if (myFavorite.filter.primaryFilter || myFavorite.filter.secondaryFilter) {
      await memberPatientListStore.saveMyFavorite(myFavorite);
      response = await memberPatientListStore.myFavorite;
    } else {
      response = {
        remote: "error",
        error: "Select filtering criteria and columns of your choice"
      };
    }
    // This will show notification according to response
    ToastNotification(
      response.remote,
      response.remote === "success"
        ? "Added to My Favorite"
        : "Failed to Added",
      response.remote === "success"
        ? "Selected filter fields and columns are saved to My Favorite"
        : response.error.error?.details.message || response.error
    );
  };

  useEffect(() => {
    getFilterListData();

    // set table column background colors
    if (localStorage.getItem("risk-adjustment-column-colors")) {
      const backgroundColors: any = JSON.parse(
        localStorage.getItem("risk-adjustment-column-colors") || ""
      );

      setBackColor(backgroundColors);
    }
  }, []);

  // handleSearchChange will get called search something
  const handleSearchChange = (searchValue: any) => {
    setSearchText(searchValue);
    memberPatientListStore.dropdownMemberListResponse();
  };

  /**
   * applyFilters will get called on click of apply button on standard or additional filter.
   * Convert Standard and Additional filter value according to api request body.
   * @param pFilters
   */
  const applyFilters = async (pFilters: PrimaryFilterType = primaryFilters) => {
    const payload: PatientsListReq = {
      pageSize: getPatientListReq.pageSize,
      pageIndex: RiskAdjustmentPageIndex[0]
    };
    if (searchText) {
      payload.search = searchText;
    }

    const secondaryFilter: any = {};
    const primaryFilterFinal: any = {};
    let selectedFilter: Partial<FiltersType> = {};
    Object.keys(filters).forEach((keys) => {
      if (lodash.isArray(filters[keys as FiltersKeyType])) {
        if (filters[keys as FiltersKeyType].length > 0) {
          secondaryFilter[keys] = filters[keys as FiltersKeyType];
          selectedFilter = {
            ...selectedFilter,
            [keys]: filters[keys as FiltersKeyType]
          };
        }
      } else if (lodash.isString(filters[keys as FiltersKeyType])) {
        if (filters[keys as FiltersKeyType]) {
          secondaryFilter[keys] = filters[keys as FiltersKeyType];
          selectedFilter = {
            ...selectedFilter,
            [keys]: filters[keys as FiltersKeyType]
          };
        }
      }
    });

    if (filters?.careTeamSize?.length > 0) {
      const careTeamSizeData = filters.careTeamSize.map((item) => {
        return item.split("-");
      });
      secondaryFilter.careTeamSize = careTeamSizeData.map((item) => {
        return {
          bandStartWith: Math.min(...item.map(Number)),
          bandEndWith: Math.max(...item.map(Number))
        };
      });
    }

    if (filters.lastDateOfService) {
      secondaryFilter.lastDateOfService = new Date(
        filters.lastDateOfService
      ).toISOString();
    }
    if (filters.lastDateOfVisit) {
      secondaryFilter.lastDateOfVisit = new Date(
        filters.lastDateOfVisit
      ).toISOString();
    }

    Object.keys(pFilters).forEach((key) => {
      const selectedPrimaryFilter = pFilters[key as keyof PrimaryFilterType];

      if (selectedPrimaryFilter) {
        if (lodash.isArray(selectedPrimaryFilter)) {
          if (selectedPrimaryFilter.length > 0) {
            const OppScore = selectedPrimaryFilter.filter(
              (item: string) => item !== "All"
            );
            primaryFilterFinal[key] = OppScore;
          }
        } else {
          primaryFilterFinal[key] = selectedPrimaryFilter;
        }
      }
      if (pFilters.asOfMonth) {
        primaryFilterFinal.asOfMonth = new Date(
          pFilters.asOfMonth
        ).toISOString();
      }
    });

    setFilterPayload({
      primaryFilter: primaryFilterFinal,
      secondaryFilter
    });

    setGetPatientListReq({
      ...payload,
      ...(Object.keys(primaryFilterFinal).length > 0
        ? { primaryFilter: primaryFilterFinal }
        : {}),
      ...(Object.keys(secondaryFilter).length > 0 ? { secondaryFilter } : {})
    });

    setSelectedFilters(selectedFilter);
  };

  // It will get called on search
  useEffect(() => {
    applyFilters();
  }, [debounceSearch]);

  /**
   * applyFavoriteFilter will get called on click of favorite list to apply filter.
   * Manage column value set, Standard filter value set and additional filter value set.
   * @param item
   */
  const applyFavoriteFilter = (item: any) => {
    const { manageColumn, secondaryFilter } = item;
    const saveColumn = columnsSource.map((col: any, index: number) => {
      const manageCol = manageColumn.find((sCol: any) => col.key === sCol);
      if (manageCol) {
        return { index, key: col.key, value: true, title: col.title };
      }
      return { index, key: col.key, value: false, title: col.title };
    });

    if (secondaryFilter?.careTeamSize?.length > 0) {
      const newTeam = secondaryFilter?.careTeamSize?.map((itemValue: any) => {
        return `${itemValue.bandStartWith}-${itemValue.bandEndWith}`;
      });
      item.secondaryFilter.careTeamSize = newTeam;
    }

    setPrimaryFilters(item.primaryFilter || {});
    setFilters(item.secondaryFilter || {});
    setSelectedColumns(saveColumn);
    setIsFavorite(true);
  };

  // This will get called when you are applying favorite list to filter
  useEffect(() => {
    if (isFavorite) {
      applyFilters();
    }
    setIsFavorite(false);
  }, [selectedFilters, selectedColumns, filters, primaryFilters]);

  return (
    <>
      {Object.keys(selectedFilters).length > 0 && (
        <div className="selected-filters">
          <div className="selected-filters-title-div">
            <Typography.Text className="selected-filters-title">
              Filter :
            </Typography.Text>
          </div>
          <div className="selected-filters-tags">
            <SelectedFilters
              selectedFilters={selectedFilters}
              filters={filters}
              setFilters={setFilters}
              applyFilters={applyFilters}
              setIsFavorite={setIsFavorite}
            />
          </div>
        </div>
      )}
      <div className="column-filters">
        <Row gutter={[8, 8]} justify="end" align="middle">
          <Col
            span={12}
            style={{ textAlign: "left" }}
            className="col-width-100"
          >
            <Space size="large" align="center">
              <h3 className="dashboard-page-heading">Patient Management</h3>
              {memberPatientListStore.isFilterApplied && (
                <Space size="small">
                  <Typography.Title
                    level={4}
                    className="filter-count"
                    data-testid="record-count"
                  >
                    {memberPatientListStore.totalPatients}
                  </Typography.Title>
                  <Typography.Title level={4} className="filter-count-text">
                    Records Found
                  </Typography.Title>
                </Space>
              )}
            </Space>
          </Col>
          <Col
            className="col-width-100"
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            <SearchBarWithIcon
              placeholder="Search..."
              searchDropdownClassName="wildcard-search-dropdown"
              onChangeEvent={handleSearchChange}
              onClickEvent={() => applyFilters()}
              inputClassName="search-input"
              iconClassName="search-icon"
              options={memberPatientListStore.recentSearches.map(
                (resSearch) => {
                  return {
                    value: resSearch
                  };
                }
              )}
            />
            <Tooltip
              placement="top"
              title="Standard Filter"
              overlayClassName="filters-tooltip"
            >
              <Link
                onClick={() => setTogglePrimaryFilters(!togglePrimaryFilters)}
              >
                <PrimaryFiltersIcon
                  PrimIconFill={PrimIconFill}
                  PrimIconStroke={PrimIconStroke}
                />
              </Link>
            </Tooltip>

            <AdvanceFilter
              filters={filters}
              setFilters={setFilters}
              advanceFilterList={advanceFilterList}
              setAdvanceFilterList={setAdvanceFilterList}
              applyFilters={applyFilters}
            />
            <ColumnFilters
              columnsSource={columnsSource}
              setColumns={setColumns}
              setBackColor={setBackColor}
              backColor={backColor}
              setIsLock={setIsLock}
              isLock={isLock}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
            <MyFavorite
              onSaveFavorite={saveFavorite}
              columnsSource={columnsSource}
              applyFavoriteFilter={applyFavoriteFilter}
            />
          </Col>
        </Row>
      </div>

      {togglePrimaryFilters && (
        <PrimaryFilters
          primaryFilters={primaryFilters}
          setPrimaryFilters={setPrimaryFilters}
          applyFilters={applyFilters}
          showPrimaryFilters
        />
      )}

      <div
        className="risk-adjustment-container"
        data-testid="yuvo-table-patient"
      >
        <YuvoTable
          data={dataSource}
          columns={columns}
          onChange={onChange}
          loading={memberPatientListStore.isPatientListLoading}
          pagination={{
            total: memberPatientListStore.totalPatients,
            pageIndex: getPatientListReq.pageIndex,
            pageSize: getPatientListReq.pageSize
          }}
          selectionType="checkbox"
          onSelectRowSelection={onSelectRowSelection}
          onChangeRowSelection={onChangeRowSelection}
        />
      </div>
    </>
  );
}

export default observer(RiskAdjustment);
