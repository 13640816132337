import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import { Avatar } from "../../avatar";

export function MsgWithDis({
  message,
  desc
}: {
  message: string;
  desc: string[];
}) {
  const now = moment().subtract(2, "days").calendar();

  return (
    <Card className="card-style">
      <Meta
        className="meta-style"
        title={message}
        avatar={<Avatar url="https://joesch.moe/api/v1/jess" name="" />}
        description={<div className="desc-modal">{desc}</div>}
      />
      <div className="human-readable-dates">{now}</div>
    </Card>
  );
}
