import { Menu, Popover, Radio } from "antd";
import { Fragment, useEffect, useState } from "react";
import Dropdown from "./Dropdown";

export type MenuType = {
  label: string;
  value: string;
  isDropdownOpen?: boolean;
  subOption?: Omit<OptionType, "value"> & {
    value: Omit<MenuType, "isDropdownOpen">[];
  };
};

export type OptionType = {
  objectKey: string;
  value: MenuType[];
  dropdownTitle?: string;
  showSearch?: boolean;
};

export type ValueType = {
  [key: string]: string;
};

type PropsType = {
  objectKey: string;
  menuOptions: MenuType[];
  onSelectOption: (value: ValueType, label: string) => void;
  isDropdownOpen: boolean;
  value: ValueType;
};
function MenuItem({
  objectKey,
  menuOptions,
  onSelectOption,
  isDropdownOpen,
  value
}: PropsType) {
  const [selectedValue, setSelectedValue] = useState<ValueType>({});
  const [label, setLabel] = useState<string>();
  const [menuList, setMenuList] = useState<MenuType[]>([]);

  useEffect(() => {
    setSelectedValue(value);
  }, [isDropdownOpen, value]);

  useEffect(() => {
    const list = menuOptions.map((item) => {
      if (item.subOption) {
        return {
          ...item,
          isDropdownOpen:
            isDropdownOpen && item.value === selectedValue[objectKey]
        };
      }
      return item;
    });
    setMenuList(list);
    // eslint-disable-next-line
  }, [menuOptions, selectedValue, isDropdownOpen]);

  const onSelect = (selectValue: ValueType, sublabel: string) => {
    onSelectOption(
      { ...selectedValue, ...selectValue },
      `${label}/${sublabel}`
    );
  };

  return (
    <Menu className="menu" selectedKeys={[selectedValue[objectKey]]}>
      {menuList.map((menuItem) => {
        return (
          <Fragment key={menuItem.value}>
            {menuItem.subOption ? (
              <Menu.Item
                onClick={(e) => {
                  setSelectedValue({ ...selectedValue, [objectKey]: e.key });
                  setLabel(menuItem.label);
                }}
                key={menuItem.value}
              >
                <Popover
                  overlayClassName="sub-dropdown"
                  placement="rightTop"
                  trigger="click"
                  open={menuItem.isDropdownOpen}
                  content={
                    <Dropdown
                      isDropdownOpen={
                        menuItem.isDropdownOpen
                          ? menuItem.isDropdownOpen
                          : false
                      }
                      onSelectOption={onSelect}
                      options={menuItem.subOption}
                      selectedValue={
                        value[objectKey] === menuItem.value ? selectedValue : {}
                      }
                    />
                  }
                  arrow={false}
                >
                  <div style={{ width: "100%" }}>
                    <Radio
                      className="radio-wrapper"
                      checked={menuItem.value === selectedValue[objectKey]}
                    >
                      {menuItem.label}
                    </Radio>
                  </div>
                  {menuItem.label}
                </Popover>
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={(e) => {
                  setSelectedValue({ [objectKey]: e.key });
                  onSelectOption({ [objectKey]: e.key }, menuItem.label);
                }}
                key={menuItem.value}
              >
                <div style={{ width: "100%" }}>
                  <Radio
                    className="radio-wrapper"
                    checked={menuItem.value === selectedValue[objectKey]}
                  >
                    {menuItem.label}
                  </Radio>
                </div>
              </Menu.Item>
            )}
          </Fragment>
        );
      })}
    </Menu>
  );
}

export default MenuItem;
