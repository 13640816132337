import React from "react";

type PropsType = {
  color?: string;
};

export default function CreateUserIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79498 7.02356C9.18193 7.02356 10.3063 5.89922 10.3063 4.51227C10.3063 3.12532 9.18193 2.00098 7.79498 2.00098C6.40804 2.00098 5.28369 3.12532 5.28369 4.51227C5.28369 5.89922 6.40804 7.02356 7.79498 7.02356Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.72119 14.0001V12.5687C2.72119 10.8974 4.07541 9.55176 5.73817 9.55176H10.2551C11.9264 9.55176 13.2721 10.906 13.2721 12.5687V14.0001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
