import {
  AdminScreenIcon,
  CampaignIcon,
  CareMgmtIcon,
  ContractsIcon,
  CreateUserIcon,
  DashboardIcon,
  PatientListIcon,
  PerformanceMgmtIcon,
  ProviderIcon,
  QualityMgmtIcon,
  RiskAdjustmentIcon,
  VisitMgmtIcon,
  YuvoDataHubIcon
} from "../../assets/images/svg";
import AdminUsers from "../admin-users";
import Contracts from "./contracts";
import DataHub from "./data-hub";
import RiskAdjustment from "./risk-adjustment";

type IconProps = { width?: number; height?: number; color?: string };

type DashboardMenuProps = {
  key: React.Key;
  label: string;
  path: string;
  component: any;
  icon?: ({ width, height, color }: IconProps) => JSX.Element;
  children?: any;
};

const DashboardMenuChild: DashboardMenuProps[] = [
  {
    key: "admin/create-user",
    label: "Create New User",
    path: "admin/create-user",
    component: <AdminUsers />,
    icon: CreateUserIcon
  },
  {
    key: "admin/user-list",
    label: "View Users",
    path: "admin/user-list",
    component: <>User List</>,
    icon: CreateUserIcon
  }
];

export const DashboardMenu: DashboardMenuProps[] = [
  {
    key: "/dashboard",
    label: "Dashboard",
    path: "dashboard",
    component: <>Dashboard</>,
    icon: DashboardIcon
  },
  {
    key: "/yuvo-data-hub",
    label: "Yuvo Datahub",
    path: "yuvo-data-hub",
    component: <DataHub />,
    icon: YuvoDataHubIcon
  },
  {
    key: "/contracts",
    label: "Contracts",
    path: "contracts",
    component: <Contracts />,
    icon: ContractsIcon
  },
  {
    key: "/care-management",
    label: "Care Management",
    path: "care-management",
    component: <>care-management</>,
    icon: CareMgmtIcon
  },
  {
    key: "/risk-adjustment",
    label: "Risk Adjustment",
    path: "risk-adjustment",
    component: <>risk-adjustment</>,
    icon: RiskAdjustmentIcon
  },
  {
    key: "/performance-management",
    label: "Performance Management",
    path: "performance-management",
    component: <>Performance Management</>,
    icon: PerformanceMgmtIcon
  },
  {
    key: "/quality-management",
    label: "Quality Management",
    path: "quality-management",
    component: <>Quality Management</>,
    icon: QualityMgmtIcon
  },
  {
    key: "/patient-management",
    label: "Patient Management",
    path: "patient-management",
    component: <RiskAdjustment />,
    icon: PatientListIcon
  },
  {
    key: "/provider",
    label: "Provider",
    path: "provider",
    component: <>provider</>,
    icon: ProviderIcon
  },
  {
    key: "/campaign",
    label: "Campaign",
    path: "campaign",
    component: <>campaign</>,
    icon: CampaignIcon
  },
  {
    key: "/visit-management",
    label: "Visit Management",
    path: "visit-management",
    component: <>visit-management</>,
    icon: VisitMgmtIcon
  },
  {
    key: "/admin-home",
    label: "Admin Home",
    path: "admin-home",
    component: <AdminUsers />,
    icon: AdminScreenIcon,
    children: DashboardMenuChild
  }
];
