import React, { useState } from "react";
import "./ColorSelector.scss";
import { SketchPicker } from "react-color";
import colorwheel from "../../../assets/images/color-wheel.svg";

type PropType = {
  objKey: any;
  setBackColor: any;
  backColor: any;
};

function ColorSelector(props: PropType) {
  const { objKey, setBackColor, backColor } = props;
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState<any>("#ffffff");
  const onClickMethod = () => {
    setDisplay(!display);
  };

  const onCloseMethod = () => {
    setDisplay(false);
  };

  const onChangeMethod = (colorValue: any) => {
    const selectedBackgroundColors = { ...backColor, [objKey]: colorValue.hex };
    localStorage.setItem(
      "risk-adjustment-column-colors",
      JSON.stringify(selectedBackgroundColors)
    );
    setColor(colorValue.hex);
    setBackColor(selectedBackgroundColors);
  };

  const popover: any = {
    position: "absolute",
    zIndex: "3",
    top: "20px",
    right: "0px"
  };

  return (
    <div>
      {color !== "#ffffff" ? (
        <div
          style={{
            backgroundColor: color,
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            position: "absolute",
            top: "0px",
            left: "0px",
            cursor: "pointer"
          }}
          onClick={onClickMethod}
        />
      ) : (
        <img
          style={{ cursor: "pointer" }}
          src={colorwheel}
          alt="colorwheel"
          onClick={onClickMethod}
        />
      )}
      {display && (
        <div style={popover}>
          <div onClick={onCloseMethod} />
          <SketchPicker width="200px" color={color} onChange={onChangeMethod} />
        </div>
      )}
    </div>
  );
}

export default ColorSelector;
