import React from "react";

type PropType = {
  style: React.CSSProperties;
};

function CloseIcon({ style = {} }: PropType) {
  return (
    <svg
      width={36}
      height={36}
      viewBox={`0 0 ${36} ${36}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M32.96 32.96L3.04004 3.04004M32.96 3.04004L3.04004 32.96"
        stroke="white"
        strokeWidth={5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CloseIcon;
