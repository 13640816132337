export const yuvoInfo = [
  {
    name: "Slide-1",
    title: "Welcome to our ePortal",
    description:
      "Our platform helps you to manage patient's health records and appointments.",
    background: "slide-one"
  },
  {
    name: "Slide-2",
    title: "Better contracts",
    description: "Access lucrative contracts with terms that work for you.",
    background: "slide-two"
  },
  {
    name: "Slide-3",
    title: "Learn  more about us",
    description: "",
    background: "slide-three"
  },
  {
    name: "Slide-4",
    title: "Optimize performance",
    description: "Digital tools designed for your unique needs.",
    background: "slide-four"
  }
];
