type PropsType = {
  color?: string;
};
function RiskAdjustmentIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0233 1.02417H3.90773C2.30184 1.02417 1 2.32601 1 3.9319V16.0475C1 17.6533 2.30184 18.9552 3.90773 18.9552H16.0233C17.6292 18.9552 18.931 17.6533 18.931 16.0475V3.9319C18.931 2.32601 17.6292 1.02417 16.0233 1.02417Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.89673 9.98976H6.45721L8.28908 12.8975L11.6427 7.08203L13.4745 9.98976H15.035"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RiskAdjustmentIcon;
