import { VerifyRequestTokenResponse } from "../models/signup.model";
import { Api } from "../components/base/api/base.api";
import {
  SIGN_UP,
  VERIFY_REGISTER_TOKEN
} from "../components/base/api/endpoints";
import { SignupResponse } from "../models";
import { ErrorResult, SuccessResult } from "../models/base";

export class Signup {
  /**
   * userSignup call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  userSignup = async (
    request: any
  ): Promise<SuccessResult<SignupResponse> | ErrorResult> => {
    try {
      const response = await Api<SignupResponse>({
        method: "POST",
        url: SIGN_UP,
        data: request
      });
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * verifyRegisterToken call the endpoint and fetch response from backend
   * @param request
   * @returns response
   */
  verifyRegisterToken = async (
    request: any
  ): Promise<SuccessResult<VerifyRequestTokenResponse> | ErrorResult> => {
    try {
      const response = await Api<VerifyRequestTokenResponse>({
        method: "POST",
        url: VERIFY_REGISTER_TOKEN,
        data: request
      });
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };
}
