import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Space,
  Modal,
  Typography,
  Tooltip
} from "antd";
import "./register.scss";
import { useNavigate, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  ButtonHTMLType,
  ButtonSize
} from "../../components/shared/button";
import { useStore } from "../../stores/helpers/use-store";
import { VerifyRegisterTokenRequest, SignupRequest } from "../../models";
import { ErrorPopUp } from "../../components/shared/error-messages";
import { SuccessErrorMessages } from "../../constant/messages/login";
import logo from "../../assets/images/yuvo-logo.svg";

const { Text } = Typography;

function Register() {
  const { signupStore } = useStore();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [token, setToken] = useState<VerifyRegisterTokenRequest>({
    registerToken: ""
  });

  /**
   * it will call verifyRegisterToken service to get response
   * @param registerToken
   */
  const verify = async (registerToken: VerifyRegisterTokenRequest) => {
    const response = await signupStore.verifyRegisterToken(registerToken);
    if (response.remote === "success") {
      form.setFieldsValue({ username: response.data.data.email });
    } else {
      ErrorPopUp({
        type: "error",
        title: "Error",
        buttons: [
          {
            title: "Cancel ",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "error"
          }
        ],
        description: ["Invalid Link"]
      });
      navigate("/login");
    }
  };

  /**
   * register will get called from submitForm function.
   * This function will validate payload and it will call userSignup service to get response
   * @param signUpData
   */
  const register = async (signUpData: SignupRequest) => {
    const response = await signupStore.userSignup(signUpData);
    if (response.remote === "success") {
      form.resetFields();
      ErrorPopUp({
        type: "success",
        title: "Success",
        buttons: [
          {
            title: "Login ",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "success"
          }
        ],
        description: ["Congratulations Account successfully created"]
      });
    } else {
      ErrorPopUp({
        type: "error",
        title: "Error",
        buttons: [
          {
            title: "Cancel ",
            handleOnClick: () => {
              Modal.destroyAll();
              navigate("/login");
            },
            type: "error"
          }
        ],
        description: ["Email already registered"]
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenIsPresent = params.has("token");
    if (tokenIsPresent) {
      setToken({
        registerToken: params.get("token")
      });
    }
  }, []);

  useEffect(() => {
    if (token.registerToken !== "") {
      verify(token);
    }
  }, [token]);

  /**
   * submitForm will get called on click of Sign up button.
   * This function will validate payload and it will call register function.
   */
  const submitForm = () => {
    const payload = {
      registerToken: token.registerToken,
      email: form.getFieldValue("username"),
      password: form.getFieldValue("password"),
      confirmPassword: form.getFieldValue("confirmPassword")
    };
    register(payload);
  };

  /**
   * validatePassword call for password validation
   * @param str
   * @returns boolean
   */
  const validatePassword = (str: string) => {
    const passwordRegEx =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(
        str
      );
    if (passwordRegEx) {
      return true;
    }
    return false;
  };

  return (
    <Card className="card-form">
      <div>
        <img
          src={logo}
          className="logo-slider hide-for-large-screen"
          alt="Yuvo Health"
          width="128px"
          height="48px"
        />
      </div>
      <h4 className="page-title">Welcome to Yuvo Health</h4>
      <Form
        layout="vertical"
        form={form}
        onFinish={submitForm}
        validateTrigger="onChange"
        className="registerForm"
      >
        <Form.Item
          label={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <span style={{ marginRight: "4px" }}>Email Id</span>
              <Tooltip
                title={SuccessErrorMessages.EmailValidation}
                overlayClassName="filters-tooltip"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          }
          name="username"
          rules={[
            {
              required: true,
              message: SuccessErrorMessages.EnterEmailId
            },
            {
              type: "email",
              message: SuccessErrorMessages.EnterValidEmailId
            }
          ]}
          style={{ marginBottom: 15 }}
          className="username"
        >
          <Input
            name="username"
            type="text"
            placeholder="Enter Your Username"
            className="username"
            disabled
          />
        </Form.Item>

        <Form.Item
          label={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <span style={{ marginRight: "4px" }}>Password</span>
              <Tooltip
                title={SuccessErrorMessages.PasswordValidation}
                overlayClassName="filters-tooltip"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          }
          name="password"
          rules={[
            {
              required: true,
              message: SuccessErrorMessages.EnterPassword
            },
            {
              min: 8,
              validator: (_, value) => {
                const result = validatePassword(value);
                if (!value || result === true) {
                  return Promise.resolve();
                }
                return Promise.reject(SuccessErrorMessages.PasswordValidation);
              }
            }
          ]}
          style={{ marginBottom: 15 }}
          className="password"
        >
          <Input.Password
            name="password"
            placeholder="Enter Your Password"
            className="password"
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: SuccessErrorMessages.ConfirmPassword
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(SuccessErrorMessages.PasswordNotMatch);
              }
            })
          ]}
          style={{ marginBottom: 15 }}
          className="password"
        >
          <Input.Password
            name="confirmPassword"
            placeholder="Enter Your Password"
            className="password"
          />
        </Form.Item>
        <Row gutter={[24, 24]} style={{ marginTop: 15 }} justify="center">
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="typo-right">
            <Form.Item shouldUpdate>
              <Button
                className="btn-primary"
                htmlType={ButtonHTMLType.Submit}
                size={ButtonSize.Large}
              >
                Sign up
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Space>
          <Text
            style={{
              color: "#4A5C75",
              fontSize: "14px",
              textAlign: "center"
            }}
          >
            <p>
              By signing in, you agree to our{" "}
              <Link to="#" className="signUpLink">
                Terms of Service{" "}
              </Link>
              and <br />
              <Link to="#" className="signUpLink">
                Privacy Policy{" "}
              </Link>
            </p>
          </Text>
        </Space>
      </Row>
    </Card>
  );
}

export default observer(Register);
