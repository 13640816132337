type PropsType = {
  color?: string;
};

function ContractsIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5823 7.68388C12.5823 7.29808 12.2675 6.98535 11.8792 6.98535H4.63696C4.24863 6.98535 3.93384 7.29808 3.93384 7.68388C3.93384 8.06968 4.24863 8.38241 4.63696 8.38241H11.8792C12.2675 8.38241 12.5823 8.06968 12.5823 7.68388Z"
        fill={color}
      />
      <path
        d="M4.63696 9.7793C4.24863 9.7793 3.93384 10.092 3.93384 10.4778C3.93384 10.8636 4.24863 11.1764 4.63696 11.1764H9.0354C9.42373 11.1764 9.73852 10.8636 9.73852 10.4778C9.73852 10.092 9.42373 9.7793 9.0354 9.7793H4.63696Z"
        fill={color}
      />
      <path
        d="M6.28907 16.4853H3.9375C3.16209 16.4853 2.53125 15.8586 2.53125 15.0882V2.79412C2.53125 2.02378 3.16209 1.39706 3.9375 1.39706H12.582C13.3574 1.39706 13.9883 2.02378 13.9883 2.79412V7.09007C13.9883 7.47587 14.3031 7.7886 14.6914 7.7886C15.0797 7.7886 15.3945 7.47587 15.3945 7.09007V2.79412C15.3945 1.25344 14.1328 0 12.582 0H3.9375C2.38669 0 1.125 1.25344 1.125 2.79412V15.0882C1.125 16.6289 2.38669 17.8824 3.9375 17.8824H6.28907C6.6774 17.8824 6.99219 17.5696 6.99219 17.1838C6.99219 16.798 6.6774 16.4853 6.28907 16.4853Z"
        fill={color}
      />
      <path
        d="M16.6398 10.1139C15.8173 9.29679 14.4791 9.29676 13.6572 10.1133L9.79695 13.9398C9.71497 14.0211 9.6545 14.1212 9.62082 14.2313L8.78013 16.9809C8.7056 17.2247 8.77053 17.4894 8.94962 17.6717C9.08377 17.8083 9.26602 17.8825 9.45295 17.8825C9.51549 17.8825 9.57863 17.8742 9.64061 17.8571L12.4786 17.0761C12.5954 17.044 12.7018 16.9824 12.7876 16.8973L16.6398 13.0775C17.4622 12.2604 17.4622 10.931 16.6398 10.1139ZM11.925 15.7788L10.4973 16.1717L10.9151 14.8051L13.5198 12.2231L14.5144 13.2112L11.925 15.7788ZM15.6459 12.0892L15.5097 12.2243L14.5153 11.2364L14.6511 11.1018C14.9252 10.8294 15.3713 10.8294 15.6454 11.1018C15.9196 11.3741 15.9196 11.8173 15.6459 12.0892Z"
        fill={color}
      />
      <path
        d="M11.8792 4.19116H4.63696C4.24863 4.19116 3.93384 4.50389 3.93384 4.88969C3.93384 5.27549 4.24863 5.58822 4.63696 5.58822H11.8792C12.2675 5.58822 12.5823 5.27549 12.5823 4.88969C12.5823 4.50389 12.2675 4.19116 11.8792 4.19116Z"
        fill={color}
      />
    </svg>
  );
}

export default ContractsIcon;
