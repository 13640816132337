import { notification } from "antd";
import "./notification.scss";
import React from "react";
import { ToastErrorIcon, ToastSuccessIcon } from "../../../assets/images/svg";

const errorDescription = (message: string) => {
  return <p className="toast-des">{message}</p>;
};

const errorMessage = (type: string, header: string) => {
  switch (type) {
    case "success": {
      return <p className="success-msg">{header}</p>;
    }

    case "error": {
      return <p className="error-msg">{header}</p>;
    }

    default: {
      return false;
    }
  }
};

const errorIcon = (type: string) => {
  switch (type) {
    case "success": {
      return <ToastSuccessIcon />;
    }

    case "error": {
      return <ToastErrorIcon />;
    }

    default: {
      return false;
    }
  }
};

export const ToastNotification = (
  type: string,
  header: string,
  message: string
) => {
  notification.open({
    className: `toast-notification ${
      type === "error" ? "error-msg-bg" : "success-msg-bg"
    }`,

    icon: errorIcon(type),
    message: errorMessage(type, header),
    description: errorDescription(message),
    placement: "top",

    duration: 2
  });
};
