function ErrorCloseIcon() {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.910156L1 13.0901"
        stroke="#FB4B4B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 0.910156L13 13.0901"
        stroke="#FB4B4B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ErrorCloseIcon;
