import React from "react";

export enum StatusType {
  High = "#E31D1C",
  Low = "#11A75C",
  Medium = "#FFAD0F"
}

interface StatusProps {
  width?: number;
  height?: number;
  color?: StatusType;
}
function StatusDotIcon({
  width = 8,
  height = 8,
  color = StatusType.High
}: StatusProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={width / 2} cy={width / 2} r={width / 2} fill={color} />
    </svg>
  );
}

export default StatusDotIcon;
