import React from "react";

function LockIcon({ width = 12, height = 14, setIsLock, isLock }: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      onClick={() => {
        setIsLock(!isLock);
      }}
    >
      <path
        d="M5.99951 9.70996V11.21M5.99951 9.70996C6.68987 9.70996 7.24951 9.15032 7.24951 8.45996C7.24951 7.76961 6.68987 7.20996 5.99951 7.20996C5.30916 7.20996 4.74951 7.76961 4.74951 8.45996C4.74951 9.15032 5.30916 9.70996 5.99951 9.70996ZM3.74951 5.20996V2.95996C3.74951 2.36322 3.98656 1.79093 4.40852 1.36897C4.83048 0.947014 5.40277 0.709961 5.99951 0.709961C6.59625 0.709961 7.16855 0.947014 7.5905 1.36897C8.01246 1.79093 8.24951 2.36322 8.24951 2.95996V5.20996M0.999512 5.20996H10.9995C11.2757 5.20996 11.4995 5.43382 11.4995 5.70996V12.71C11.4995 12.9861 11.2757 13.21 10.9995 13.21H0.999512C0.723369 13.21 0.499512 12.9861 0.499512 12.71V5.70996C0.499512 5.43382 0.723369 5.20996 0.999512 5.20996Z"
        stroke="#7256FC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LockIcon;
