import React from "react";

function ResendIcon() {
  return (
    <svg
      width={12}
      height={9}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14453 6.04099L3.68453 4.50098"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.14449 6.04099L0.604492 4.50098"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85641 3.20508L8.31641 4.74509"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85645 3.20508L11.3964 4.74509"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85673 3.49707V6.69956C9.85673 7.60956 9.1159 8.35041 8.2059 8.35041H3.7959"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.14453 5.84785V2.29536C2.14453 1.38536 2.88536 0.644531 3.79536 0.644531H8.19953"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ResendIcon;
