import React from "react";

function DropdownOpenIcon() {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.666992L5.83333 7.33366L11.6667 0.666992H0Z"
        fill="#8F8F8F"
      />
    </svg>
  );
}

export default DropdownOpenIcon;
