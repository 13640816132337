import { Modal, message } from "antd";
import {
  Button,
  ButtonHTMLType,
  ButtonSize,
  ButtonType
} from "../../../../../components/shared/button";
import { WarningIcon } from "../../../../../assets/images/svg";
import { MyFavoriteType } from "../../../../../models/memberPatientList.model";
import { useStore } from "../../../../../stores/helpers/use-store";
import "./DeleteMyFavorite.scss";

type PropsType = {
  deleteModalVisible: boolean;
  setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  item: MyFavoriteType;
};

function DeleteMyFavorite({
  deleteModalVisible,
  setDeleteModalVisible,
  item
}: PropsType) {
  const { memberPatientListStore } = useStore();

  /**
   * "handleOk" function get called on click of Delete button.
   * it will call deleteMyFavorite service.
   * that "favorite" will get deleted from list and messages of service responses displays accordingly.
   */
  const handleOk = async () => {
    const response = await memberPatientListStore.deleteMyFavorite({
      filterPreferenceId: item.filterPreferenceId
    });
    if (response.remote === "success") {
      message.success(`${item.name} deleted successfully`);
    }
    setDeleteModalVisible(false);
  };

  /**
   * "handleCancel" function get called on click of Cancel button.
   * It will close the modal popup.
   */

  const handleCancel = () => {
    setDeleteModalVisible(false);
  };

  return (
    <Modal
      className="delete-modal"
      title={
        <>
          <WarningIcon />
          <span className="title">Delete {item.name}</span>
        </>
      }
      open={deleteModalVisible}
      centered
      closable={false}
      footer={[
        <Button
          onClick={handleCancel}
          type={ButtonType.Tertiary}
          htmlType={ButtonHTMLType.Submit}
          size={ButtonSize.Middle}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>,
        <Button
          key="back"
          onClick={handleOk}
          htmlType={ButtonHTMLType.Submit}
          size={ButtonSize.Middle}
        >
          Delete
        </Button>
      ]}
    >
      <>
        <b>Are you sure you want to delete '{item.name}'?</b>
        <br />'{item.name}' will be deleted immediately.
        <br /> You can't undo this action.
      </>
    </Modal>
  );
}

export default DeleteMyFavorite;
