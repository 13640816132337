import { Space, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import lodash from "lodash";
import { FiltersType } from "../../../models";
import {
  FiltersKeyType,
  SelectedTagType
} from "../../../models/memberPatientList.model";
import { Button } from "../../../components/shared/button";

type PropsType = {
  selectedFilters: Partial<FiltersType>;
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  applyFilters: () => Promise<void>;
  setIsFavorite: Function;
};

function SelectedFilters({
  selectedFilters,
  filters,
  setFilters,
  applyFilters,
  setIsFavorite
}: PropsType) {
  const [tags, setTags] = useState<SelectedTagType[]>([]);
  const [isTagRemoved, setIsTagRemoved] = useState<boolean>(false);

  /**
   * handleClose will get called on click of remove tag
   * This will remove filter value
   * @param removedTag
   */
  const handleClose = (removedTag: SelectedTagType) => {
    if (removedTag) {
      const removedValue =
        selectedFilters[removedTag.objectKey as FiltersKeyType];
      if (lodash.isArray(removedValue)) {
        const filteredValue = removedValue.filter((value: any) => {
          if (value.bandStartWith && value.bandEndWith) {
            setIsFavorite(true);
            value = `${value.bandStartWith}-${value.bandEndWith}`;
            return value !== removedTag.value;
          }
          return value !== removedTag.value;
        });
        if (filteredValue.length > 0) {
          setFilters({ ...filters, [removedTag.objectKey]: filteredValue });
        } else {
          setFilters({ ...filters, [removedTag.objectKey]: [] });
        }
      } else {
        setFilters({ ...filters, [removedTag.objectKey]: "" });
      }

      setIsTagRemoved(true);
    }
  };

  /**
   * clearAll will get called on click of clear all button.
   * This function will clear Additional filters.
   */
  const clearAll = () => {
    let removeFilters: Partial<FiltersType> = {};
    Object.keys(selectedFilters).forEach((key) => {
      if (lodash.isArray(selectedFilters[key as FiltersKeyType])) {
        removeFilters = { ...removeFilters, [key]: [] };
      } else {
        removeFilters = { ...removeFilters, [key]: "" };
      }
    });
    setFilters({ ...filters, ...removeFilters });
    setIsTagRemoved(true);
  };

  // Value removed from filters it will called.
  useEffect(() => {
    if (isTagRemoved) {
      applyFilters();
      setIsTagRemoved(false);
    }
  }, [isTagRemoved]);

  // This function show selected filter value
  useEffect(() => {
    const selectedFilterTags: any = [];

    Object.keys(selectedFilters).forEach((key) => {
      const selectedFilter = selectedFilters[key as FiltersKeyType];
      if (selectedFilter) {
        if (lodash.isArray(selectedFilter)) {
          if (selectedFilter.length > 0) {
            selectedFilter.forEach((value: string | any) => {
              if (key === "careTeamSize" && value === "object") {
                selectedFilterTags.push({
                  objectKey: key,
                  value: `${value?.bandStartWith}-${value?.bandEndWith}`
                });
              }

              selectedFilterTags.push({ objectKey: key, value });
            });
          }
        } else {
          selectedFilterTags.push({ objectKey: key, value: selectedFilter });
        }
      }
    });

    setTags(selectedFilterTags.filter((value: any) => value !== undefined));
  }, [selectedFilters]);

  return (
    <Space data-testid="selected-filter-empty">
      {tags.map((tag) => {
        return (
          <Tooltip
            title={tag?.objectKey
              .replace(/([A-Z])/g, " $1")
              .trim()
              .toLowerCase()}
            overlayClassName="filters-tooltip"
          >
            <Tag
              data-testid="selected-filter"
              className="selected-filter-tag"
              key={tag?.value}
              closable
              onClose={() => handleClose(tag)}
            >
              {tag?.value}
            </Tag>
          </Tooltip>
        );
      })}
      <Button className="clear-all-tags" onClick={clearAll}>
        Clear All
      </Button>
    </Space>
  );
}

export default SelectedFilters;
