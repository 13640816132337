import { Api } from "../components/base/api/base.api";
import {
  GET_MEMBER_SEARCH_HISTORY,
  ADVANCE_MEMBER_FILTERS,
  UPDATE_MY_FAVORITE,
  SAVE_MY_FAVORITE,
  GET_FILTER_PREFERENCE,
  GET_SHARED_FILTER_PREFERENCE,
  SHARE_SAVED_FILTER,
  DELETE_FILTER_PREFERENCE,
  GET_MEMBER_PATIENT_LIST
} from "../components/base/api/endpoints";

import { ErrorResult, SuccessResult } from "../models/base";
import type { PatientsListRes, PatientsListReq } from "../models/index";
import {
  SearchHistoryResponse,
  FilterListRes,
  UpdateMyFavoriteRes,
  UpdateMyFavoriteReq,
  MyFavoriteReq,
  MyFavoriteRes,
  GetMyFavoriteReq,
  RenameMyFavoriteRes,
  RenameMyFavoriteReq,
  DeleteMyFavoriteReq,
  DeleteMyFavoriteRes
} from "../models/memberPatientList.model";

export class MemberPatientList {
  /**
   * getMemberPatientList(to get list of Member Patient) call the endpoint and fetch response.
   * @param request
   * @returns response
   */
  getMemberPatientList = async (
    request: PatientsListReq
  ): Promise<SuccessResult<PatientsListRes> | ErrorResult> => {
    try {
      const response = await Api<PatientsListRes>({
        method: "POST",
        url: GET_MEMBER_PATIENT_LIST,
        data: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",

        error: error.response.data
      };
    }
  };

  /**
   * updateMyFavorite(to update My Favorite) call the endpoint with filterPreferenceId and fetch response
   * @param request
   * @returns response
   */
  updateMyFavorite = async (
    request: UpdateMyFavoriteReq
  ): Promise<SuccessResult<UpdateMyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<UpdateMyFavoriteRes>({
        method: "PATCH",
        url: UPDATE_MY_FAVORITE + request.filterPreferenceId,
        data: request.data
      });
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * dropMemberListRes(to get Search History with latest 5 wild card searches)
   * call the endpoint and fetch response
   * @param request
   * @returns response
   */
  dropMemberListRes = async (): Promise<
    SuccessResult<SearchHistoryResponse> | ErrorResult
  > => {
    try {
      const response = await Api.get<SearchHistoryResponse>(
        GET_MEMBER_SEARCH_HISTORY
      );

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * getFilterList(to get Filter List) call the endpoint and fetch response
   * @param request
   * @returns response
   */
  getFilterList = async (): Promise<
    SuccessResult<FilterListRes> | ErrorResult
  > => {
    try {
      const response = await Api<FilterListRes>({
        method: "GET",
        url: ADVANCE_MEMBER_FILTERS
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",

        error: error.response.data
      };
    }
  };

  /**
   * saveMyFavorite(will save My Favorites) call the endpoint and fetch response
   * @param request
   * @returns response
   */
  saveMyFavorite = async (
    request: MyFavoriteReq
  ): Promise<SuccessResult<MyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<MyFavoriteRes>({
        method: "POST",
        url: SAVE_MY_FAVORITE,
        data: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * getMyFavoriteList(to get My Favorite List) call the endpoint and fetch response
   * @param request
   * @returns response
   */
  getMyFavoriteList = async (
    request: GetMyFavoriteReq
  ): Promise<SuccessResult<MyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<MyFavoriteRes>({
        method: "GET",
        url: GET_FILTER_PREFERENCE,
        params: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * getMyFavoriteListSharedBy(to get MyFavorite List Shared By others) call the endpoint and fetch response
   * @param request
   * @returns response
   */
  getMyFavoriteListSharedBy = async (
    request: GetMyFavoriteReq
  ): Promise<SuccessResult<MyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<MyFavoriteRes>({
        method: "GET",
        url: GET_SHARED_FILTER_PREFERENCE,
        params: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * shareSavedFilter(to share Saved Filters to others) call the endpoint and fetch response
   * @param request
   * @returns response
   */
  shareSavedFilter = async (
    id: any
  ): Promise<SuccessResult<any> | ErrorResult> => {
    try {
      const response = await Api<MyFavoriteRes>({
        method: "PUT",
        url: `${SHARE_SAVED_FILTER}/${id}`
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * renameMyFavorite(to rename My favorites) call the endpoint with filterPreferenceId and fetch response from backend
   * @param request
   * @returns response
   */
  renameMyFavorite = async (
    request: RenameMyFavoriteReq
  ): Promise<SuccessResult<RenameMyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<RenameMyFavoriteRes>({
        method: "PATCH",
        url: UPDATE_MY_FAVORITE + request.filterPreferenceId,
        data: request.data
      });
      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };

  /**
   * deleteMyFavorite(to delete My Favorites) call the endpoint with filterPreferenceId and fetch response from backend
   * @param request
   * @returns response
   */

  deleteMyFavorite = async (
    request: DeleteMyFavoriteReq
  ): Promise<SuccessResult<DeleteMyFavoriteRes> | ErrorResult> => {
    try {
      const response = await Api<DeleteMyFavoriteRes>({
        method: "DELETE",
        url: `${DELETE_FILTER_PREFERENCE}/${request.filterPreferenceId}`,
        data: request
      });

      return {
        remote: "success",
        data: response.data
      };
    } catch (error: any) {
      return {
        remote: "error",
        error: error.response.data
      };
    }
  };
}
