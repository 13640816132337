import { ColumnsType } from "antd/es/table";
import { Col, Popover, Row, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { YuvoTable } from "../../components/shared/table";
import "./Admin.scss";
import { TableRecord, User, UserListRequest } from "../../models/admin.model";
import { EditIconTwo, ResendIcon, ActionIcon } from "../../assets/images/svg";
import { SearchBarWithIcon } from "../../components/shared/searchbar-with-icon";
import { emailStatus } from "../../constant/admin";
import { useStore } from "../../stores/helpers/use-store";
import { ToastNotification } from "../../components/shared/notification";
import { columnsSource } from "./columnData";

type PropType = {
  getUsersListReq: UserListRequest;
  setGetUsersListReq: React.Dispatch<React.SetStateAction<UserListRequest>>;
  setSearchText: Function;
  searchText: string;
};

function UserList(props: PropType) {
  const { getUsersListReq, setGetUsersListReq, setSearchText, searchText } =
    props;

  const { adminStore } = useStore();
  const [debounceSearchAdmin] = useDebounce(searchText, 1000);

  // Action column value show delivered or failed
  const columnsSourceWithRender: ColumnsType<TableRecord> = columnsSource.map(
    (obj: any) => {
      switch (obj.title) {
        case "Status":
          return {
            ...obj,
            render: (value: any) => {
              return value === emailStatus.success ? (
                <span className="delivered">
                  <span className="bullet" />
                  {value}
                </span>
              ) : (
                <span className="failed">
                  <span className="bullet" />
                  {value}
                </span>
              );
            }
          };
        default:
          return obj;
      }
    }
  );

  /**
   * Resend link
   * @param email
   */
  const resendLink = async (email: string) => {
    await adminStore.getSignUpResendInvite({ email });
    const signUpResendData = await adminStore.signUpResendResponse;
    ToastNotification(
      signUpResendData?.success ? "success" : "error",
      signUpResendData?.success ? "Link sent" : "Link sent failed",
      signUpResendData?.error?.message || signUpResendData?.message
    );
    setGetUsersListReq({ ...getUsersListReq, pageIndex: 0 });
  };

  /**
   * In action column add three dot icon
   * click to three dot icon open popover
   * In popover two menu options edit and resend
   */
  const data: TableRecord[] = adminStore.userList.map(
    (value: User, index: any) => {
      return {
        ...value,
        key: index,
        action:
          value.status === emailStatus.fail ? (
            <Popover
              placement="left"
              content={
                <ul className="actions">
                  <li>
                    <button className="action-btn" type="submit">
                      <EditIconTwo />
                      <span style={{ paddingLeft: "7px" }}>Edit</span>
                    </button>
                  </li>
                  <li>
                    <button
                      data-testid="resend-btn"
                      className="action-btn"
                      onClick={() => resendLink(value.email)}
                      type="submit"
                    >
                      <ResendIcon />
                      <span style={{ paddingLeft: "7px" }}>Resend</span>
                    </button>
                  </li>
                </ul>
              }
              trigger="click"
              arrow={false}
            >
              <Space data-testid="action-btn">
                <ActionIcon />
              </Space>
            </Popover>
          ) : (
            <ActionIcon disable />
          )
      };
    }
  );

  /**
   * onchange event to call api to get search list
   * @param pageSize
   * @param pageIndex
   */
  const onChange = (pageSize: number, pageIndex: number) => {
    setGetUsersListReq({ ...getUsersListReq, pageIndex, pageSize });
  };

  const onSelectRowSelection = () => {};

  const onChangeRowSelection = () => {};

  /**
   * Search value
   * @param searchValue
   */
  const handleSearchChange = (searchValue: any) => {
    setSearchText(searchValue);
  };

  useEffect(() => {
    if (searchText) {
      setGetUsersListReq({
        ...getUsersListReq,
        pageIndex: 0,
        search: searchText
      });
    } else {
      setGetUsersListReq({
        pageIndex: 0,
        pageSize: getUsersListReq.pageSize
      });
    }
  }, [debounceSearchAdmin]);

  // Search function
  const handleSearchClick = () => {
    if (searchText) {
      setGetUsersListReq({
        ...getUsersListReq,
        pageIndex: 0,
        search: searchText
      });
    } else {
      setGetUsersListReq({ pageIndex: 0, pageSize: getUsersListReq.pageSize });
    }
  };

  return (
    <div className="risk-adjustment-container" data-testid="admin-table">
      <div className="title-bar">
        <Row justify="space-between">
          <Col className="table-title">
            <span>User List</span>
          </Col>
          <Col>
            <SearchBarWithIcon
              placeholder="Name or Email ID"
              searchDropdownClassName="external-user-search"
              onChangeEvent={handleSearchChange}
              onClickEvent={handleSearchClick}
              inputClassName="search-input"
              iconClassName="search-icon"
            />
          </Col>
        </Row>
      </div>
      <YuvoTable
        data={data}
        columns={columnsSourceWithRender}
        onChange={onChange}
        loading={adminStore.loading}
        pagination={{
          total: adminStore.totalUsers,
          pageIndex: getUsersListReq.pageIndex,
          pageSize: getUsersListReq.pageSize
        }}
        selectionType="checkbox"
        onSelectRowSelection={onSelectRowSelection}
        onChangeRowSelection={onChangeRowSelection}
      />
    </div>
  );
}

export default observer(UserList);
