import React from "react";

function MyFavoritePlusIcon() {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 6.875H8.125V3.125C8.125 2.95924 8.05915 2.80027 7.94194 2.68306C7.82473 2.56585 7.66576 2.5 7.5 2.5C7.33424 2.5 7.17527 2.56585 7.05806 2.68306C6.94085 2.80027 6.875 2.95924 6.875 3.125V6.875H3.125C2.95924 6.875 2.80027 6.94085 2.68306 7.05806C2.56585 7.17527 2.5 7.33424 2.5 7.5C2.5 7.66576 2.56585 7.82473 2.68306 7.94194C2.80027 8.05915 2.95924 8.125 3.125 8.125H6.875V11.875C6.875 12.0408 6.94085 12.1997 7.05806 12.3169C7.17527 12.4342 7.33424 12.5 7.5 12.5C7.66576 12.5 7.82473 12.4342 7.94194 12.3169C8.05915 12.1997 8.125 12.0408 8.125 11.875V8.125H11.875C12.0408 8.125 12.1997 8.05915 12.3169 7.94194C12.4342 7.82473 12.5 7.66576 12.5 7.5C12.5 7.33424 12.4342 7.17527 12.3169 7.05806C12.1997 6.94085 12.0408 6.875 11.875 6.875Z"
        fill="#4A5C75"
      />
    </svg>
  );
}

export default MyFavoritePlusIcon;
