import {
  Popover,
  Button,
  Row,
  Col,
  Divider,
  Form,
  FormInstance,
  Typography
} from "antd";
import { ReactNode, useRef, useState } from "react";

import "./filter-table.scss";

const { Title } = Typography;

export function FilterTable({ children }: { children: ReactNode[] }) {
  return (
    <div className="filter-table">
      <div className="filter-table-content">{children}</div>
    </div>
  );
}

export function FilterTableHeader({ children }: { children: ReactNode[] }) {
  return <div className="filter-table-header">{children}</div>;
}

export function FilterTableBody({ children }: { children: ReactNode[] }) {
  return <div className="filter-table-body">{children}</div>;
}

// Childer - JSX element/ReactNode - Required
// onclick - functionality after clicking Apply button - required
// onCancel -  functionality after clicking Cancel button - not required
// While using filter wrap the childer jsx in form.item

type FilterProps = {
  children: ReactNode;
  onclick: (e: any) => void;
};

export function Filter(props: FilterProps) {
  const { children, onclick } = props;
  const [open, setOpen] = useState<boolean>(false);

  const formRef = useRef<FormInstance>(null);
  const onCancelClick = () => {
    setOpen(false);
    formRef.current?.resetFields();
  };

  const onResetClick = () => {
    formRef.current?.resetFields();
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const test = () => {
    return (
      <div>
        <Row align="bottom">
          <Col span={16}>
            <Title level={5}>Advance Filter</Title>
          </Col>
          <Col span={8}>
            <Button onClick={onResetClick}>Reset</Button>
          </Col>
        </Row>
        <Divider />
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          ref={formRef}
          onFinish={onclick}
          style={{ maxHeight: 300, overflow: "scroll" }}
        >
          <Row>{children}</Row>
          <Row>
            <Col span={12}>
              <Button htmlType="submit">Apply</Button>
            </Col>
            <Col>
              <Button onClick={onCancelClick}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };
  return (
    <div>
      <Popover
        placement="bottomRight"
        content={test}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button>Filter</Button>
      </Popover>
    </div>
  );
}
