function GlobalSearchIcon() {
  return (
    <svg
      width={24}
      height={26}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.277 16.7774L18.1247 19.6251M16.7188 13.2969C16.7188 16.0152 14.5152 18.2188 11.7969 18.2188C9.0786 18.2188 6.875 16.0152 6.875 13.2969C6.875 10.5786 9.0786 8.375 11.7969 8.375C14.5152 8.375 16.7188 10.5786 16.7188 13.2969Z"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GlobalSearchIcon;
