import { useEffect, useState } from "react";
import { Col, DatePicker, Row } from "antd";
import { FiltersType, SelectOptionsType } from "../../../../../models";
import { useStore } from "../../../../../stores/helpers/use-store";
import { dayjsTypeDate } from "../../../../../library/util/js-helper";
import {
  AdvanceFilterList,
  filterPlaceHolders
} from "../../../../../constant/RiskAdjustment";
import FloatingLabel from "../../../../../components/shared/floating-label";
import DropdownSelect from "../../../../../components/shared/dropdown-select";
import "./ViewEditMyFavorite.scss";
import { globalDateFormat } from "../../../../../constant";

type PropsType = {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  className: string;
  isEditable: boolean;
};

function AdvanceFilterFields({
  filters,
  setFilters,
  className = "",
  isEditable
}: PropsType) {
  const [advanceFilterList, setAdvanceFilterList] = useState<
    SelectOptionsType[]
  >([]);
  const [search, setSearch] = useState<string>("");
  const { memberPatientListStore } = useStore();

  /**
   * This function call initial render
   * Get the value of backend api and some value take from frontend
   * Converting value according to antd dropdown.
   */
  const getFilterListData = async () => {
    let secondaryList: any = {};
    secondaryList = memberPatientListStore.secondaryFilterList;
    let filterDataList = {
      ...secondaryList,
      ...AdvanceFilterList
    };

    filterDataList = Object.entries(filterDataList)
      ?.map(([key, value]: any[]) => {
        const placeholder = filterPlaceHolders[key];
        if (Array.isArray(value)) {
          const optionsList = value?.map((item: string, i: number) => {
            return {
              title: item,
              value: item,
              key: i
            };
          });

          return {
            key,
            placeholder,
            type: "dropdown",
            options: optionsList
          };
        }
        if (typeof value === "string") {
          return { key, placeholder, type: "date", value };
        }
        return value;
      })
      .filter(Boolean);

    setAdvanceFilterList(filterDataList);
  };

  useEffect(() => {
    getFilterListData();
  }, [memberPatientListStore.secondaryFilterList]);

  // Set the value in filters state
  const onChangeSelect = (value: string, key: any) => {
    setFilters((prev: any) => {
      if (prev && prev[key] && prev[key]?.length !== value?.length) {
        setSearch("");
      }
      if (prev[key]?.length && search) {
        return { ...prev };
      }
      return { ...prev, [key]: value };
    });
  };

  /**
   * This function return dropdown and date field
   * @param item
   * @returns Dropdown and Date
   */
  const filterOptions = (item: SelectOptionsType) => {
    const key: string = item.key || "";
    switch (item.type) {
      case "dropdown":
        return (
          <Col xl={6} lg={6} sm={8} xs={12} key={item.key}>
            <FloatingLabel
              disabled={!isEditable}
              label={item.placeholder}
              value={filters[key as keyof FiltersType]}
            >
              <DropdownSelect
                className={
                  !isEditable
                    ? filters[key as keyof FiltersType].length > 0
                      ? "readonly dropdown-filters"
                      : "disabled dropdown-filters"
                    : "dropdown-filters"
                }
                key={item.key}
                data={item}
                style={{ width: "100%" }}
                value={filters[key as keyof FiltersType]}
                disabled={!isEditable}
                onChange={(value: string) => onChangeSelect(value, item.key)}
                showSearch={false}
                setSearch={setSearch}
                search={search}
              />
            </FloatingLabel>
          </Col>
        );
      case "date":
        return (
          <Col xl={6} lg={6} sm={8} xs={12} key={item.key}>
            <FloatingLabel
              disabled={!isEditable}
              label={item.placeholder}
              value={filters[key as keyof FiltersType]}
            >
              <DatePicker
                className={
                  !isEditable
                    ? filters[key as keyof FiltersType].length > 0
                      ? "readonly datepicker"
                      : "disabled datepicker"
                    : "datepicker"
                }
                disabled={!isEditable}
                key={item.key}
                format={globalDateFormat}
                value={dayjsTypeDate(filters[key as keyof FiltersType])}
                style={{ width: "100%" }}
                onChange={(val, dateValue) =>
                  onChangeSelect(dateValue, item.key)
                }
              />
            </FloatingLabel>
          </Col>
        );
      default:
        return null;
    }
  };

  return (
    <Row gutter={[24, 24]} className={className}>
      {advanceFilterList.map((item: SelectOptionsType) => {
        return filterOptions(item);
      })}
    </Row>
  );
}

export default AdvanceFilterFields;
