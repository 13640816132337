type PropsType = {
  color?: string;
};
function ProviderIcon({ color = "#4A5C75" }: PropsType) {
  return (
    <svg
      width={21}
      height={17}
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25781 7.14918C8.9717 7.14918 10.3611 5.7598 10.3611 4.0459C10.3611 2.33201 8.9717 0.942627 7.25781 0.942627C5.54391 0.942627 4.15454 2.33201 4.15454 4.0459C4.15454 5.7598 5.54391 7.14918 7.25781 7.14918Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15.7392V13.9718C1 11.9166 2.66467 10.252 4.71982 10.252H10.2893C12.3444 10.252 14.0091 11.9166 14.0091 13.9718V15.7392"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0061 8.03273C16.2774 8.03273 17.3079 7.0022 17.3079 5.73097C17.3079 4.45973 16.2774 3.4292 15.0061 3.4292C13.7349 3.4292 12.7043 4.45973 12.7043 5.73097C12.7043 7.0022 13.7349 8.03273 15.0061 8.03273Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6636 10.3342H17.246C18.7668 10.3342 19.9999 11.5673 19.9999 13.0881V14.4034"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProviderIcon;
