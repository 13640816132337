import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { observer } from "mobx-react-lite";
import PrivateRoute from "./PrivateRoute";
import { Dashboard } from "./pages/dashboard";
import "./App.scss";
import { Login, Register } from "./pages/index";

import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import VerifyLogin from "./pages/login/verify-login";
import AuthLayout from "./pages/login/AuthLayout";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Manrope",
          colorPrimary: "#5B4E98"
        }
      }}
    >
      <Router>
        <Routes>
          {/* public routes  */}
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify-login" element={<VerifyLogin />} />
          </Route>

          {/* protected routes */}
          <Route path="" element={<PrivateRoute />}>
            <Route path="/*" element={<Dashboard />} />
          </Route>

          <Route path="*" element={<>404 page not found</>} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default observer(App);
