import { Badge, Card } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import { Avatar } from "../../avatar";

export function Message({
  message,
  isMsgRead
}: {
  message: string[];
  isMsgRead: boolean;
}) {
  const now = moment().subtract(1, "days").calendar();

  return (
    <Card className="card-style">
      <Meta
        avatar={
          <Badge dot={isMsgRead} color="yellow">
            <Avatar name="" />
          </Badge>
        }
        description={message.map((item) => item)}
      />
      <div className="human-readable-dates">{now}</div>
    </Card>
  );
}
