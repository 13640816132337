function PrimaryFiltersIcon({ PrimIconFill, PrimIconStroke }: any) {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="primary-filters-icon"
    >
      <g filter="url(#filter0_d_363_137044)">
        <rect
          x={4}
          y={4}
          width={34}
          height={34}
          rx={5}
          fill={PrimIconFill}
          shapeRendering="crispEdges"
        />
        <rect x={12} y={4} width={18} height={7} fill={PrimIconFill} />
        <path
          d="M26.0627 25.0625L26.0628 26.1875M27.7502 22.8125H24.3752M15.9378 22.8125L15.9377 26.1875M14.2502 20.5625H17.6252M21.0002 19.4375L21.0003 26.1875M22.6877 17.1875H19.3127M26.0628 13.8125L26.0627 22.8125M21.0003 13.8125L21.0002 17.1875M15.9377 13.8125L15.9378 20.5625"
          stroke={PrimIconStroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_363_137044"
          x={0}
          y={0}
          width={42}
          height={42}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_363_137044"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_363_137044"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default PrimaryFiltersIcon;
