import { MouseEventHandler, CSSProperties } from "react";
import classNames from "classnames";
import { Button as AntdButton } from "antd";
import "./button.scss";

export enum ButtonType {
  Default = "default",
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quaternary = "quaternary",
  Ghost = "ghost",
  Dashed = "dashed",
  Link = "link",
  Text = "text"
}

export enum SubmitAction {
  Default = "default",
  Close = "close",
  New = "new",
  Add = "add",
  Back = "back"
}

export enum ButtonHTMLType {
  button = "button",
  Submit = "submit",
  Reset = "reset"
}

export enum ButtonSize {
  Small = "small",
  Middle = "middle",
  Large = "large"
}

interface IProps {
  type?: ButtonType;
  disabled?: boolean;
  startIcon?: any;
  endIcon?: any;
  children?: any;
  onClick?: MouseEventHandler<HTMLElement>;
  htmlType?: ButtonHTMLType;
  danger?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  href?: string;
  style?: CSSProperties;
  className?: string;
  tabIndex?: number;
  icon?: any;
}

export function Button(props: IProps) {
  const {
    tabIndex,
    htmlType,
    type,
    className,
    size,
    loading,
    danger,
    disabled,
    href,
    onClick,
    style,
    startIcon,
    icon,
    children,
    endIcon
  } = props;
  return (
    <span className="btn-container">
      <AntdButton
        tabIndex={tabIndex}
        htmlType={htmlType || ButtonHTMLType.button}
        className={classNames("btn", type, className)}
        size={size}
        loading={loading}
        danger={danger}
        disabled={disabled}
        href={href}
        onClick={onClick}
        style={style}
        type={type as any}
      >
        {startIcon ? <span className="btn-icon start">{startIcon}</span> : null}
        {icon ? (
          <span className="btn-icon" style={{ marginRight: "10px" }}>
            {icon}
          </span>
        ) : null}
        {children}
        {endIcon ? <span className="btn-icon end">{endIcon}</span> : null}
      </AntdButton>
    </span>
  );
}
