import React from "react";

function RenameIcon() {
  return (
    <svg
      width={14}
      height={13}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 7.16686C14.4826 7.16686 14.3333 7.31611 14.3333 7.50019V12.1333C14.333 12.5841 14.1537 13.0162 13.835 13.335C13.5162 13.6537 13.0841 13.833 12.6333 13.8333H3.36667C2.91592 13.833 2.48377 13.6537 2.16503 13.335C1.84628 13.0162 1.66697 12.5841 1.66667 12.1333V2.86667C1.66696 2.41592 1.84628 1.98377 2.16503 1.66503C2.48378 1.34628 2.91592 1.16697 3.36667 1.16667H7.99981C8.18389 1.16667 8.33314 1.01741 8.33314 0.833333C8.33314 0.649257 8.18389 0.5 7.99981 0.5H3.36667C2.73912 0.500744 2.13764 0.750297 1.6939 1.1939C1.25029 1.63764 1.00076 2.23916 1 2.86667V12.1333C1.00074 12.7609 1.2503 13.3624 1.6939 13.8061C2.13764 14.2497 2.73916 14.4992 3.36667 14.5H12.6333C13.2609 14.4993 13.8624 14.2497 14.3061 13.8061C14.7497 13.3624 14.9992 12.7608 15 12.1333V7.50019C15 7.4118 14.9649 7.32698 14.9024 7.26448C14.8399 7.20198 14.7551 7.16686 14.6667 7.16686Z"
        fill="#4A5C75"
        stroke="#4A5C75"
        strokeWidth="0.2"
      />
      <path
        d="M7.09707 8.40311C7.22728 8.53317 7.43829 8.53317 7.56835 8.40311L14.9017 1.06978C15.028 0.938974 15.0262 0.731088 14.8977 0.602502C14.7691 0.473915 14.5612 0.472144 14.4304 0.598484L7.09706 7.93182C6.967 8.06188 6.96701 8.27291 7.09707 8.40311Z"
        fill="#4A5C75"
        stroke="#4A5C75"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default RenameIcon;
