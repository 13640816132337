import { ColumnsType } from "antd/es/table";
import { TableRecord } from "../../models/admin.model";

// Column header list
export const columnsSource: ColumnsType<TableRecord> = [
  {
    title: "User ID",
    dataIndex: "id",
    key: "id",
    align: "center",
    width: 80
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "left",
    width: 200
  },
  {
    title: "Email Id",
    dataIndex: "email",
    key: "email",
    align: "left",
    width: 200
  },
  {
    title: "Phone No.",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    align: "left",
    width: 200
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "left",
    width: 80
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    align: "center",
    width: 80
  }
];
