import React from "react";

function ShareIcon() {
  return (
    <svg
      width={16}
      height={15}
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.07759 7.67796C2.74769 7.67796 3.29092 7.1504 3.29092 6.49962C3.29092 5.84885 2.74769 5.32129 2.07759 5.32129C1.40748 5.32129 0.864258 5.84885 0.864258 6.49962C0.864258 7.1504 1.40748 7.67796 2.07759 7.67796Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95763 3.58323C8.6084 3.58323 9.13596 3.05566 9.13596 2.40489C9.13596 1.75411 8.6084 1.22656 7.95763 1.22656C7.30685 1.22656 6.7793 1.75411 6.7793 2.40489C6.7793 3.05566 7.30685 3.58323 7.95763 3.58323Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.95763 11.7737C8.6084 11.7737 9.13596 11.2461 9.13596 10.5953C9.13596 9.94454 8.6084 9.41699 7.95763 9.41699C7.30685 9.41699 6.7793 9.94454 6.7793 10.5953C6.7793 11.2461 7.30685 11.7737 7.95763 11.7737Z"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.38428 5.59043L6.65094 3.31543"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65094 9.68515L3.38428 7.41016"
        stroke="#4A5C75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShareIcon;
