import { makeAutoObservable } from "mobx";
import {
  ForgotPasswordRequest,
  GoogleSignInRequest,
  ResetPasswordRequest,
  SendOTPRequest,
  VerifyOTPRequest
} from "../models/login.model";
import { Login } from "../services/index";
import { LoginRequest } from "../models";

export class LoginStore {
  authenticated = false;

  loading = false;

  accessToken = "";

  refreshToken = "";

  success = false;

  message = "";

  loginService = new Login();

  constructor() {
    makeAutoObservable(this);
  }

  googleSignIn = async (request: GoogleSignInRequest) => {
    this.loading = true;
    const response = await this.loginService.googleSignIn(request);
    this.loading = false;
    if (response.remote === "success") {
      this.accessToken = response.data.data.accessToken;
      this.refreshToken = response.data.data.refreshToken;
      this.authenticated = true;
      localStorage.setItem("accessToken", response.data.data.accessToken);
      return response;
    }
    return response;
  };

  userLogin = async (request: LoginRequest) => {
    this.loading = true;
    const response = await this.loginService.userLogin(request);

    this.loading = false;
    if (response.remote === "success") {
      this.success = response.data.data.success;
      this.message = response.data.data.message;
      return response;
    }
    return response;
  };

  forgotPassword = async (request: ForgotPasswordRequest) => {
    this.loading = true;
    const response = await this.loginService.forgotPassword(request);
    this.loading = false;
    if (response.remote === "success") {
      return response;
    }
    return response;
  };

  resetPassword = async (request: ResetPasswordRequest) => {
    this.loading = true;
    const response = await this.loginService.resetPassword(request);
    this.loading = false;
    if (response.remote === "success") {
      return response;
    }
    return response;
  };

  sendOTP = async (request: SendOTPRequest) => {
    this.loading = true;
    const response = await this.loginService.sendOTP(request);
    this.loading = false;
    if (response.remote === "success") {
      return response;
    }
    return response;
  };

  verifyOTP = async (request: VerifyOTPRequest) => {
    this.loading = true;
    const response = await this.loginService.verifyOTP(request);
    this.loading = false;
    if (response.remote === "success") {
      this.accessToken = response.data.data.accessToken;
      this.refreshToken = response.data.data.refreshToken;
      this.authenticated = true;
      localStorage.setItem("accessToken", response.data.data.accessToken);
      return response;
    }
    return response;
  };
}
