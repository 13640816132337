import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/images/yuvo-logo.svg";
import CarouselComp from "../../components/shared/carousel";
import { yuvoInfo } from "../../data/YuvoInfo";

function AuthLayout() {
  const [, setHeight] = useState(0);
  const ref = useRef<any>(null);

  useEffect(() => {
    setHeight(ref.current?.clientHeight);
  }, []);

  return (
    <section className="app-section">
      <div className="center-vh-card">
        <Row style={{ display: "flex" }} justify="center" align="stretch">
          <Col className="slidshow-left hide-for-small-screen">
            <div>
              <img
                src={logo}
                className="logo-slider"
                alt="Yuvo Health"
                width="128px"
                height="48px"
              />
            </div>
            <CarouselComp info={yuvoInfo} />
          </Col>
          <Col className="login-form-right" ref={ref}>
            <Outlet />
          </Col>
        </Row>
      </div>
    </section>
  );
}
export default AuthLayout;
