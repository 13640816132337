import React from "react";

function MyFavoriteIcon() {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_363_137057)">
        <rect
          x={4}
          y={4}
          width={34}
          height={34}
          rx={5}
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect x={12} y={4} width={18} height={7} fill="white" />
        <path
          d="M21.3102 25.4109L24.8564 27.6576C25.3097 27.9448 25.8725 27.5177 25.738 26.9886L24.7134 22.9581C24.6845 22.8458 24.688 22.7277 24.7233 22.6173C24.7586 22.5069 24.8243 22.4087 24.9129 22.334L28.093 19.6872C28.5108 19.3394 28.2951 18.6459 27.7583 18.6111L23.6054 18.3416C23.4935 18.3336 23.3862 18.294 23.296 18.2274C23.2058 18.1608 23.1363 18.0699 23.0957 17.9654L21.5469 14.0649C21.5048 13.954 21.4299 13.8584 21.3322 13.791C21.2346 13.7236 21.1187 13.6875 21 13.6875C20.8813 13.6875 20.7654 13.7236 20.6678 13.791C20.5701 13.8584 20.4952 13.954 20.4531 14.0649L18.9043 17.9654C18.8637 18.0699 18.7942 18.1608 18.704 18.2274C18.6138 18.294 18.5065 18.3336 18.3946 18.3416L14.2417 18.6111C13.7049 18.6459 13.4892 19.3394 13.907 19.6872L17.0871 22.334C17.1757 22.4087 17.2414 22.5069 17.2767 22.6173C17.312 22.7277 17.3155 22.8458 17.2866 22.9581L16.3364 26.6959C16.175 27.3309 16.8504 27.8434 17.3943 27.4988L20.6898 25.4109C20.7825 25.3519 20.8901 25.3205 21 25.3205C21.1099 25.3205 21.2175 25.3519 21.3102 25.4109V25.4109Z"
          stroke="#262043"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_363_137057"
          x={0}
          y={0}
          width={42}
          height={42}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0 0.704167 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_363_137057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_363_137057"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default MyFavoriteIcon;
